import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded'
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined'
import './style.css'
const ReactWaves = React.lazy(() => import('@dschoon/react-waves'))

export default class TimeWaveForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      wavesurfer: null,
      playing: false,
      pos: 0,
      currentTime: 0,
      audioKey: 0,
    }
  }

  componentDidMount() {
    this.updateInterval = setInterval(() => {
      if (this.state.playing && this.state.wavesurfer) {
        this.setState({
          currentTime: this.state.wavesurfer.getCurrentTime(),
        })
      }
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.updateInterval)
  }

  onPosChange = (pos, wavesurfer) => {
    if (pos >= 0 && pos <= 1 && pos !== this.state.pos) {
      this.setState({
        pos,
        wavesurfer,
        currentTime: pos * wavesurfer.getDuration(),
      })
    }

    if (pos >= wavesurfer.getDuration()) {
      setTimeout(() => {
        this.setState({
          playing: false,
          pos: 0,
        })
      }, 1200)
    }
  }

  componentDidUpdate(prevProps) {
    // Check if the audio source has changed
    if (prevProps.src !== this.props.src) {
      // Reset the position and increment the audioKey
      this.setState({
        pos: 0,
        playing: false,
        audioKey: this.state.audioKey + 1,
      })
    }
  }

  onReady = ({ wavesurfer }) => {
    this.setState({ wavesurfer, duration: wavesurfer.getDuration() })
  }

  formatTime(seconds) {
    seconds = Math.round(seconds)
    var minutes = Math.floor(seconds / 60)
    var remainingSeconds = seconds % 60

    if (remainingSeconds < 10) {
      remainingSeconds = '0' + remainingSeconds
    }

    return minutes + ':' + remainingSeconds
  }

  render() {
    const {
      builder: isBuilder,
      containerStyles = {},
      buttonStyles = {},
      timeStyles = {},
      timelineStyles = {},
    } = this.props
    const formattedCurrentTime = this.formatTime(this.state?.currentTime || 0)
    const formattedDuration = this.formatTime(this.state?.duration || 0)

    return (
      <Box
        alignItems="center"
        className="audio_player_timeline_waveform"
        display="flex"
        flex="1 0 0"
        gap="10px"
        height="40px"
        justifyContent="center"
        padding="9.271px 9.271px 9.271px 12px"
        sx={{
          width: isBuilder ? '100%' : undefined,
          backgroundColor: isBuilder ? 'rgba(174, 198, 221, 0.1)' : undefined,
          borderRadius: isBuilder ? '100px' : undefined,
          ...containerStyles,
        }}>
        <IconButton
          size="small"
          sx={{
            height: isBuilder ? '45px' : 'auto',
            width: isBuilder ? '45px' : 'auto',
            border: '1px solid #EE46BC',
            background: ({ palette }) => palette.primary.main,
            '&:hover': {
              background: ({ palette }) => palette.primary.main,
            },
            ...buttonStyles,
          }}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            this.setState({ playing: !this.state.playing })
          }}>
          {!this.state.playing ? (
            <PlayCircleOutlineRoundedIcon fontSize="medium" sx={{ color: '#fff' }} />
          ) : (
            <PauseCircleOutlinedIcon fontSize="medium" sx={{ color: '#fff' }} />
          )}
        </IconButton>
        <Box
          sx={{
            width: '100%',
            minWidth: '100px',
            height: isBuilder ? '20px' : '40px',
            cursor: 'pointer',
            ...timelineStyles,
          }}>
          <ReactWaves
            key={this.state.audioKey}
            audioFile={this.props.src || ''}
            className="react-waves-timeline"
            options={{
              barGap: 3,
              barWidth: 3,
              barHeight: 2,
              barRadius: 3,
              cursorWidth: 0,
              height: isBuilder ? 20 : 40,
              width: '100%',
              hideScrollbar: true,
              progressColor: '#DE5790',
              responsive: true,
              waveColor: '#00153D',
            }}
            playing={this.state.playing}
            pos={this.state.pos}
            volume={1}
            zoom={0}
            onPosChange={this.onPosChange}
            onReady={this.onReady}
          />
        </Box>
        <Typography sx={{ minWidth: '30px', whiteSpace: 'nowrap', ...timeStyles }}>
          {this.state.playing ? formattedCurrentTime : formattedDuration}
        </Typography>
      </Box>
    )
  }
}
