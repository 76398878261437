import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

export function logOnboardingStep(action, stepName, onboardingState) {
  logEvent(analytics, `onboarding_step_${action}`, {
    step_name: stepName,
    onboarding_state: onboardingState,
  });
  _cio.track(`onboarding_step_${action}`, {
    step_name: stepName,
    onboarding_state: onboardingState,
  });
}

export function logOnboardingTransition(fromState, toState, completedSteps) {
  logEvent(analytics, "onboarding_transition", {
    from_state: fromState,
    to_state: toState,
    completed_steps: completedSteps,
  });
  _cio.track("onboarding_transition", {
    from_state: fromState,
    to_state: toState,
    completed_steps: completedSteps,
  });
}

export function logOnboardingCompletion(completedSteps) {
  logEvent(analytics, "onboarding_completed", {
    completed_steps: completedSteps,
  });
  _cio.track("onboarding_completed", {
    completed_steps: completedSteps,
  });
}
