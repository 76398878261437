import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import usePollyTextToSpeech from '../../utils/amazonPollyTextToSpeech'
import { usePollyVoices } from '../../utils/amazonPollyTextToSpeech'
import textToSpeech from '../../utils/textToSpeech'

import styles from './styles.module.css'
import WordAssistantPopover from './SyllablesPopover'

// fix english language
const styllableBreakingLangs = ['es', 'fr', 'de', 'it', 'pt', 'ru']
const reversedLangs = ['ar', 'he', 'fa']

function HighlightCardText({ children, language, currentWord, isMicOn, textStyles = {} }) {
  const { langVoices } = usePollyVoices(language)
  let words = children.split(' ')

  if (reversedLangs.includes(language)) {
    words = words.reverse()
  }

  return (
    <span className={styles.textArea}>
      {words.map((word, index) => (
        <Word
          key={index}
          highlightWord={currentWord}
          index={index}
          isMicOn={isMicOn}
          langVoices={langVoices}
          language={language}
          textStyles={textStyles}
          word={word}
        />
      ))}
    </span>
  )
}

HighlightCardText.propTypes = {
  children: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
}

export default React.memo(HighlightCardText)

function Word({ word, language, langVoices, highlightWord, index, isMicOn, textStyles = {} }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { pollyTextToSpeech } = usePollyTextToSpeech(word)
  let wordWithoutSpecialChars = word.replace(/[`~!@#$%^&*()_|+\-=?;:",.<>\{\}\[\]\\\/]/gi, '')
  const hasSlash = word.includes('/')
  const highlight =
    !isMicOn &&
    highlightWord &&
    wordWithoutSpecialChars.includes(highlightWord?.value) &&
    (index === highlightWord?.index || index === highlightWord?.index + 1 || index === highlightWord?.index + 2) &&
    !word.includes('/')

  const handleSpeak = e => {
    e.preventDefault()
    e.stopPropagation()
    if (hasSlash) return
    if (!isMicOn) {
      if (!langVoices || langVoices?.length === 0) {
        textToSpeech({
          text: word,
          language,
          speed: 0.7,
        })
      } else {
        awsTextToSpeech({
          langVoices,
          pollyTextToSpeech,
          word,
        })
      }
      setAnchorEl(e.currentTarget)
    }
  }

  const handleClose = e => {
    e?.preventDefault?.()
    setAnchorEl(null)
  }

  return (
    <>
      {Boolean(anchorEl) && styllableBreakingLangs.includes(language) && (
        <WordAssistantPopover
          anchorEl={anchorEl}
          handleClose={handleClose}
          lang={language}
          word={wordWithoutSpecialChars}
        />
      )}
      <span
        className={cx(styles.word, {
          [styles.highlight]: highlight,
          [styles.hover]: !isMicOn && !hasSlash,
          //   [styles.dashed]: !hasSlash,
        })}
        style={{
          color: '#344054',
          ...textStyles,
        }}
        onClick={handleSpeak}
      >
        {word}
      </span>
    </>
  )
}

export const awsTextToSpeech = ({ langVoices, pollyTextToSpeech, word }) => {
  const voiceId = localStorage.getItem('voiceId')
  const languageCode = localStorage.getItem('languageCode')

  if (!voiceId && !languageCode && langVoices?.length) {
    const vId = langVoices[0]?.Id
    const langId = langVoices[0]?.LanguageCode

    localStorage.setItem('voiceId', langVoices[0]?.Id)
    localStorage.setItem('languageCode', langVoices[0]?.LanguageCode)
    localStorage.setItem('languageName', langVoices[0]?.LanguageName)
    pollyTextToSpeech({
      speechText: word,
      lang: langId,
      voice: vId,
    })
  } else {
    pollyTextToSpeech({
      speechText: null,
      lang: null,
      voice: null,
    })
  }
}
