const API_KEY = import.meta.env.VITE_YOUTUBE_API_KEY

const searchYouTubeVideos = async query => {
  const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&type=video&q=${encodeURIComponent(
    query,
  )}&maxResults=10&key=${API_KEY}`

  try {
    const response = await fetch(url)
    const data = await response.json()

    const videos = data.items.map(item => ({
      id: item.id.videoId,
      title: item.snippet.title,
      thumbnailUrl: item.snippet.thumbnails.default.url,
      description: item.snippet.description,
      url: `https://www.youtube.com/watch?v=${item.id.videoId}`,
    }))

    console.log('YouTube Video Search Results:', videos)

    return videos
  } catch (error) {
    console.error('Error fetching video search results:', error)
  }
}

export { searchYouTubeVideos }
