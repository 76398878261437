import { defaultBlockSchema } from '@blocknote/core'

export const CUSTOM_BLOCKS_TYPES_MEDIA_AREA = {
  MEDIA_AREA_ITEM_IMAGE: 'media-area-item-image',
  MEDIA_AREA_ITEM_VIDEO: 'media-area-item-video',
  MEDIA_AREA_ITEM_AUDIO: 'media-area-item-audio',
  MEDIA_AREA_ITEM_DOCUMENT: 'media-area-item-document',
  MEDIA_AREA_ITEM_PODCAST: 'media-area-item-podcast',
  // MEDIA_AREA_ITEM_WEB: 'media-area-item-youtube',
  MEDIA_AREA_ITEM_TEXT: defaultBlockSchema.paragraph.type,
  MEDIA_AREA_ITEM_TEXT_TO_SPEECH: 'media-area-item-text-to-speech',
}

export const CUSTOM_BLOCKS_TYPES_MEDIA_AREA_ITEM = Object.values(CUSTOM_BLOCKS_TYPES_MEDIA_AREA)

export type CustomBlocksTypesMediaArea =
  (typeof CUSTOM_BLOCKS_TYPES_MEDIA_AREA)[keyof typeof CUSTOM_BLOCKS_TYPES_MEDIA_AREA]

export const CUSTOM_BLOCKS_GROUP_MEDIA_AREA = {
  MEDIA: 'Media',
  ENTERTAINMENT: 'Entertainment',
}
