import { useState } from 'react'
import { Box } from '@mui/material'

import { useMediaAreaContext } from '../MediaArea'

import { AlignContentCustomBlock } from './ActionsCustomBlock/AlignContentCustomBlock'

import { ResizableContent } from '@/components/ResizableContent'

export function ContainerCustomBlock({
  children,
  justifyContent,
  handleJustifyContent,
  handleResize,
  type,
  initialSize,
  isMaxWidth = false,
  handleDoubleClick,
}: {
  children: React.ReactNode
  justifyContent: 'flex-start' | 'flex-end' | 'center'
  handleJustifyContent: (justifyContent: 'flex-start' | 'flex-end' | 'center') => void
  type: 'image' | 'video' | 'audio' | 'document' | 'pdf' | 'web' | 'podcast'
  handleResize: (width: number, height: number) => void
  initialSize?: {
    width: number | string
    height: number
  }
  isMaxWidth?: boolean
  handleDoubleClick: () => void
}) {
  const { maxDimensions, isEditable } = useMediaAreaContext()
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <div contentEditable={false} onDoubleClick={handleDoubleClick}>
      <div style={{ display: 'flex', justifyContent: justifyContent }}>
        <ResizableContent
          enabled={isEditable}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          handleResizeStop={handleResize}
          initialSize={initialSize}
          isHovered={isHovered}
          isMaxWidth={isMaxWidth}
          maxDimensions={maxDimensions}
          type={type}
        >
          {children}
          {isEditable ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                display: 'flex',
                borderRadius: '4px',
                color: '#fff',
                fill: '#fff',
                fontSize: '11.5px',
                background: 'rgba(0, 0, 0, 0.6)',
                whiteSpace: 'nowrap',
                height: '26px',
                maxWidth: 'calc(100% - 16px)',
                transition: 'opacity 300ms ease-in 0s',
                zIndex: 10,
                opacity: isHovered ? 1 : 0,
              }}
            >
              <AlignContentCustomBlock handleJustifyContent={handleJustifyContent} />
            </Box>
          ) : null}
        </ResizableContent>
        <div />
      </div>
    </div>
  )
}
