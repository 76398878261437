/* eslint-disable @typescript-eslint/no-empty-function */
import { useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { MediaArea } from '@/components/MediaArea'

export function MediaAreaPage() {
  const mediaAreaId = useParams().id
  const [searchParams] = useSearchParams()

  const width = searchParams.get('width')
  const height = searchParams.get('height')
  const voiceId = searchParams.get('voiceId')
  const languageName = searchParams.get('languageName')
  const languageCode = searchParams.get('languageCode')

  useEffect(() => {
    if (voiceId) {
      localStorage.setItem('voiceId', voiceId)
    }
    if (languageName) {
      localStorage.setItem('languageName', languageName)
    }
    if (languageCode) {
      localStorage.setItem('languageCode', languageCode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  return (
    <MediaArea
      isMobileApp
      isEditable={false}
      mediaAreaId={mediaAreaId!}
      scale={1}
      stylesContainer={{
        width: width ? +width : '100%',
        height: height ? +height : '100%',
      }}
      onChange={() => {}}
    />
  )
}
