export function getOrgById(orgs, id) {
  return orgs?.find((org) => org.id === id);
}

export function formatId(id) {
  return id?.replace(/\./g, "_");
}

export function retrieveId(id) {
  return id?.replace(/_/g, ".");
}

export function findActiveOrg({ adminOrgs, managerOrgs, orgId }) {
  // First, search in adminOrgs
  let activeOrg = adminOrgs.find((org) => org.id === orgId);

  // If not found in adminOrgs, search in managerOrgs
  if (!activeOrg) {
    activeOrg = managerOrgs.find((org) => org.id === orgId);
  }

  // Return the found organization or null if not found
  return activeOrg || null;
}
