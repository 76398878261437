import React, { useState } from 'react'
import { Snackbar, useMediaQuery } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

import notificationTypes from '../utils/notificationTypes.js'

import useNotifications from './useNotifications.js'

const UtilitiesContext = React.createContext({
  isMobile: false,
  isTablet: false,
  showSnackbar: () => {},
  hideSnackbar: () => {},
  setSnackbarMessage: () => {},
  snackbarAnchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  snackbarType: 'success',
  snackbarOpen: false,
  snackbarMessage: '',
  notifications: [],
  notificationTypes: {},
  unreadNotifications: [],
  createNotification: () => {},
  updateNotifications: () => {},
})

const useUtils = () => React.useContext(UtilitiesContext)

export default useUtils

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert ref={ref} elevation={6} variant="standard" {...props} />
})

const useUtilitiesMain = () => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme => theme.breakpoints.down('md'))
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarType, setSnackbarType] = useState('success')
  const [snackbarAnchorOrigin, setSnackbarAnchorOrigin] = useState({
    vertical: 'top',
    horizontal: 'center',
  })
  const showSnackbar = (message, type, anchorOrigin) => {
    setSnackbarMessage(message)
    if (type) setSnackbarType(type)
    setSnackbarOpen(true)
    if (anchorOrigin) setSnackbarAnchorOrigin(anchorOrigin)
  }

  const hideSnackbar = () => {
    setSnackbarOpen(false)
  }

  const { notifications, updateNotifications, createNotification, totalUnread } = useNotifications()

  return {
    isMobile,
    isTablet,
    showSnackbar,
    hideSnackbar,
    setSnackbarMessage,
    snackbarAnchorOrigin,
    snackbarType,
    snackbarOpen,
    snackbarMessage,
    notifications,
    notificationTypes,
    createNotification,
    updateNotifications,
    totalUnreadNotifications: totalUnread,
  }
}

function UtilitiesProvider({ children }) {
  const utils = useUtilitiesMain() || {}

  return (
    <UtilitiesContext.Provider value={{ ...utils }}>
      {children}
      <Snackbar
        autoHideDuration={6000}
        open={utils.snackbarOpen}
        anchorOrigin={utils.snackbarAnchorOrigin}
        //   message={snackbarMessage}
        onClose={utils.hideSnackbar}
      >
        <Alert
          severity={
            ['error', 'info', 'success', 'warming'].includes(utils.snackbarType) ? utils.snackbarType : 'success'
          }
          sx={{ width: '100%' }}
          onClose={utils.hideSnackbar}
        >
          {utils.snackbarMessage}
        </Alert>
      </Snackbar>
    </UtilitiesContext.Provider>
  )
}

export { UtilitiesProvider }
