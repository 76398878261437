/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
import { insertOrUpdateBlock } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import { useState } from 'react'
import { Box, Modal } from '@mui/material'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA, CUSTOM_BLOCKS_GROUP_MEDIA_AREA } from '../custom-blocks-types-media-area'
import { type Editor } from '../media-area-schema'
import { useMediaAreaContext } from '../MediaArea'

import { CardPreviewBlock } from './CardPreviewBlock'
import { ContainerCustomBlock } from './ContainerCustomBlock'
import { CustomBlockIcons } from './IconsCustomBlocks'

import { ImageDialogSelector } from '@/components/ImageSelector'
import { useDialog } from '@/hooks/ui/useDialog'

export const ImageCustomBlock = createReactBlockSpec(
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_IMAGE,
    content: 'none',
    propSchema: {
      imageUrl: {
        default: '',
      },
      width: {
        default: '100%',
      },
      height: {
        default: 0,
      },
      justifyContent: {
        default: 'center',
        values: ['flex-start', 'center', 'flex-end'],
      },
      isMaxWidth: {
        default: false,
      },
    },
  },
  {
    render: props => {
      const { justifyContent, imageUrl, width, height, isMaxWidth } = props.block.props
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { maxDimensions } = useMediaAreaContext()

      const initialSize = {
        width,
        height,
      }

      const handleUpdateBlock = (imageUrl: string) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            imageUrl,
          },
        })
      }

      const removeBlock = () => {
        props.editor.removeBlocks([props.block.id])
      }

      const handleResize = (width: number | string, height: number) => {
        // let isMax = false

        // if (width === '100%') {
        //   isMax = true
        // }

        // if (maxDimensions?.maxWidth && (width as number) + 10 >= maxDimensions.maxWidth) {
        //   isMax = true
        // }

        props.editor.updateBlock(props.block.id, {
          props: {
            // @ts-ignore
            width,
            height,
            isMaxWidth: false,
          },
        })
      }

      const handleJustifyContent = (place: 'flex-start' | 'center' | 'flex-end') => {
        props.editor.updateBlock(props.block.id, {
          props: {
            justifyContent: place,
          },
        })
      }

      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <ImageUI
            handleJustifyContent={handleJustifyContent}
            handleResize={handleResize}
            handleUpdateBlock={handleUpdateBlock}
            imageUrl={imageUrl && imageUrl.length > 0 ? imageUrl : null}
            initialSize={initialSize.width ? initialSize : null}
            isEditable={props.editor.isEditable}
            isMaxWidth={isMaxWidth}
            justifyContent={justifyContent}
            removeBlock={removeBlock}
          />
        </div>
      )
    },
  },
)

function ImageUI({
  imageUrl = null,
  justifyContent,
  handleJustifyContent,
  handleUpdateBlock,
  removeBlock,
  handleResize,
  initialSize,
  isEditable,
  isMaxWidth,
}: {
  imageUrl: string | null
  justifyContent: 'flex-start' | 'center' | 'flex-end'
  handleJustifyContent: (place: 'flex-start' | 'center' | 'flex-end') => void
  handleUpdateBlock: (imageUrl: string) => void
  removeBlock: () => void
  handleResize: (width: number, height: number) => void
  initialSize: {
    width: number | string
    height: number
  } | null
  isEditable: boolean
  isMaxWidth: boolean
}) {
  const [showFullImage, setShowFullImage] = useState(false)

  const { open, handleOpen, handleClose } = useDialog({
    showOnMount: isEditable,
  })

  const handleSelectImage = (images: { url: string; path: null | string }[]) => {
    const url = images[0].url

    handleUpdateBlock(url)
  }

  const handleDoubleClick = () => {
    setShowFullImage(true)
  }

  const handleCloseFullImage = () => {
    setShowFullImage(false)
  }

  return (
    <>
      {imageUrl == null ? (
        <>
          <CardPreviewBlock
            actions={{
              onDelete: removeBlock,
            }}
            icon={CustomBlockIcons.ImageBlockIcon}
            showActions={isEditable}
            onClick={handleOpen}
          >
            Add an image
          </CardPreviewBlock>
          <ImageDialogSelector
            enabled={isEditable}
            handleClose={handleClose}
            open={open}
            onClickImage={handleSelectImage}
          />
        </>
      ) : (
        <ContainerCustomBlock
          handleDoubleClick={handleDoubleClick}
          handleJustifyContent={handleJustifyContent}
          handleResize={handleResize}
          initialSize={initialSize ?? undefined}
          isMaxWidth={isMaxWidth}
          justifyContent={justifyContent}
          type="image"
        >
          <img alt="" src={imageUrl} style={{ width: '100%', height: '100%', borderRadius: '8px' }} />
        </ContainerCustomBlock>
      )}
      <Modal open={showFullImage} onClose={handleCloseFullImage}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <img
            alt=""
            src={imageUrl!}
            style={{ maxWidth: '80vw', borderRadius: '8px', objectFit: 'contain', maxHeight: '80vh', width: 'auto' }}
          />
        </Box>
      </Modal>
    </>
  )
}

export const insertImageCustomBlock = (editor: Editor) => ({
  title: 'Image',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      // @ts-ignore
      type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_IMAGE,
    })
  },
  aliases: ['image', 'google image', 'unsplash', 'photo'],
  group: CUSTOM_BLOCKS_GROUP_MEDIA_AREA.MEDIA,
  icon: <CustomBlockIcons.ImageBlockIcon height={25} width={25} />,
  subtext: 'Search Google or Unsplash',
})
