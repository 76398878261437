import { useEffect, useState } from 'react'

export function useDialog({ showOnMount = false }: { showOnMount?: boolean } = {}) {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (showOnMount) {
      setOpen(true)
    }
  }, [showOnMount])

  return {
    open,
    handleOpen,
    handleClose,
  }
}
