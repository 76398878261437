import { PollyClient, SynthesizeSpeechCommand } from "@aws-sdk/client-polly";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { isSafari } from "./browser-type";

const accessKeyId = import.meta.env.VITE_AMAZON_POLLY_ACCESS_KEY_ID;
const secretAccessKey = import.meta.env.VITE_AMAZON_POLLY_SECRET_ACCESS_KEY;

const awsConfiguration = {
	region: "us-east-1",
	credentials: fromCognitoIdentityPool({
		client: new CognitoIdentityClient({ region: "us-east-1" }),
		accessKeyId,
		secretAccessKey,
		identityPoolId: "us-east-1:55f4d596-26ef-4c3b-ab64-b7ab8769ceab",
	}),
};

const client = new PollyClient(awsConfiguration);

/**
 * Function to get Speech Marks from AWS Polly service for a given text.
 * @param {string} text - The text for which the speech marks are to be obtained
 * @returns {Array} The speech marks.
 */
async function getTextSpeechMarks(text) {
	const voiceId = localStorage.getItem("voiceId");

	if (!voiceId) {
		return [];
	}
	const voiceSpeed = isSafari ? 1 : localStorage.getItem("voiceSpeed");
	const playbackRate = voiceSpeed ?? 0.7;

	const params = {
		OutputFormat: "json",
		Text: text,
		VoiceId: voiceId,
		SpeechMarkTypes: ["word"],
	};

	try {
		const data = await client.send(new SynthesizeSpeechCommand(params));
		const speechMarkStream = await data.AudioStream.transformToString();
		const speechMarksArray = speechMarkStream.split("\n").filter(Boolean);
		const speechMarks = speechMarksArray.filter(isValidJson).map(JSON.parse);

		if (playbackRate !== 1 && speechMarks?.length) {
			speechMarks?.forEach((speechMark) => {
				speechMark.time = speechMark.time / playbackRate;
			});
		}
		return speechMarks;
	} catch (err) {
		console.log("getTextSpeechMarks", err);
		throw err;
	}
}

/**
 * Function to check if a string is valid JSON.
 * @param {string} jsonString - The string to check
 * @returns {boolean} whether the string is a valid JSON.
 */
function isValidJson(jsonString) {
	try {
		JSON.parse(jsonString);
		return true;
	} catch (error) {
		return false;
	}
}

export default getTextSpeechMarks;
