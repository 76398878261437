import algoliasearch from "algoliasearch";

// export const client = algoliasearch(
//   process.env.REACT_APP_ALGOLIA_APP_ID,
//   process.env.REACT_APP_ALGOLIA_KEY
// );

// // export const flashcardsIndex = client.initIndex("speakable-flashcards");
// export default client.initIndex("speakable-sets-live");

export const algolia_client = algoliasearch(
	"RB0ARP7IJZ",
	import.meta.env.VITE_ALGOLIA_KEY
);
