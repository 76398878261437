import { FirebaseError } from 'firebase/app'
import { StorageError } from 'firebase/storage'

import {
  HttpsFirebaseError,
  InternalServerError,
  ItemExistsError,
  ItemNotFoundError,
  MissingDataError,
} from './custom-errors'

export function handleError(err: unknown, customMessage?: string): never {
  if (err instanceof MissingDataError) {
    throw err
  }

  if (err instanceof ItemExistsError) {
    throw err
  }

  if (err instanceof ItemNotFoundError) {
    throw err
  }

  if (err instanceof HttpsFirebaseError) {
    throw err
  }

  if (err instanceof StorageError) {
    throw new Error('Image upload failed. Please try again.')
  }

  if (err instanceof FirebaseError) {
    console.error(err)
    throw new InternalServerError()
  }

  throw new Error(customMessage ?? 'Something went wrong', {
    cause: {
      message: 'unknown',
    },
  })
}
