import { Box, Input } from '@mui/material'

export function InputEmbedIframe({ onEmbedIframeUploaded }) {
  const handleEmbedIframe = async iframe => {
    // const isValid = isValidIframe(iframe)

    onEmbedIframeUploaded(iframe)
    // if (isValid) {
    // } else {
    //   alert('Invalid iframe')
    // }
  }

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Input
        placeholder="Paste an iframe"
        onChange={e => {
          handleEmbedIframe(e.target.value)
        }}
      />
    </Box>
  )
}

// const isValidIframe = iframe => {
//   const parser = new DOMParser()

//   try {
//     const doc = parser.parseFromString(iframe, 'text/html')
//     const iframeElement = doc.querySelector('iframe')

//     return iframeElement !== null
//   } catch (e) {
//     return false
//   }
// }
