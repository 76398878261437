import Compressor from 'compressorjs'

export default async function compressImage(file) {
  if (!isTypeImage(file)) return file

  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.75,
      success: resolve,
      error: reject,
      maxWidth: 1000,
      maxHeight: 1000,
      convertSize: 1000000,
    })
  })
}

const isTypeImage = file => file.type.split('/')[0] === 'image'
