import 'regenerator-runtime'
import './LogrocketInit'
import '../index.css'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'

import App from './App'
import { AuthProvider } from './hooks/useAuth'
import store from './Redux/store'
import audioClips from './assets/audio'
import Splash from './components/Splash/Splash'
import mui_theme from './assets/mui_theme/theme'
import { UtilitiesProvider } from './hooks/useUtilities'
import { LocationHistoryProvider } from './context/LocationHistoryProvider'

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString()

import 'typeface-poppins'
import 'typeface-pacifico'
import '@splidejs/react-splide/css'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
// @ts-ignore
window.audio = {
  ...audioClips,
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 3000000,
    },
  },
})

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <LocationHistoryProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ThemeProvider theme={mui_theme}>
              <UtilitiesProvider>
                <Suspense fallback={<Splash />}>
                  <BrowserRouter>
                    <CssBaseline />
                    <App />
                  </BrowserRouter>
                </Suspense>
              </UtilitiesProvider>
            </ThemeProvider>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </LocationHistoryProvider>
  </React.StrictMode>,
)
