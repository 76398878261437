import { Dialog, DialogTitle, Button, Typography, Stack, Grid } from '@mui/material'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'

import { Ellipse, EllipseGroup, FeaturesList } from './components'
import ContactSalesForm from './ContactSalesForm'

function CalendlyDialog({ open, onClose }) {
  return (
    <Dialog
      fullScreen
      maxWidth="md"
      open={open}
      sx={{
        '& .MuiPaper-root': {
          gap: '16px',
          // padding: '24px 32px 74px 32px',
          flexDirection: 'column',
          alignItems: 'center',
          bgcolor: '#F7FAFE',
        },
      }}>
      <DialogTitle component="div" sx={{ alignItems: 'flex-start', width: '100%', px: 3 }}>
        <Button startIcon={<ArrowBackRoundedIcon />} sx={{ color: '#101828', textTransform: 'none' }} onClick={onClose}>
          Go back
        </Button>
      </DialogTitle>
      <Stack
        sx={{
          display: 'flex',
          maxWidth: '1053px',
          padding: '32px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
        }}>
        <Stack position="relative" width="100%">
          <EllipseGroup />
          <Ellipse style={{ position: 'absolute', top: '20px', left: '50px' }} />
          <Typography
            sx={{
              position: 'absolute',
              left: '20px',
              color: '#101828',
              fontSize: 40,
              fontWeight: 600,
              width: 350,
              lineHeight: '46px',
            }}>
            Get your free Speakable demo
          </Typography>
        </Stack>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12}>
            <Stack direction="column" gap="20px">
              <Typography
                sx={{
                  fontSize: 16,
                  lineHeight: '24px',
                }}>
                Speakable’s AI powered platform provides educators with advanced tools for teaching speaking, writing,
                listening, and reading. Maximize student progress with AI-driven feedback and auto-grading, creating a
                seamless, effective learning experience.
              </Typography>
              <Typography
                sx={{
                  fontSize: 20,
                  fontWeight: 600,
                  lineHeight: '30px',
                }}>
                Top Features:
              </Typography>
              {/* Features List */}
              <Stack direction="row" flexWrap="wrap" rowGap="20px">
                {featuresData.topFeatures.map(section => (
                  <FeaturesList key={section.category} category={section.category} features={section?.features} />
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item md={6} sm={12}>
            <ContactSalesForm handleClose={onClose} />
          </Grid>
        </Grid>
      </Stack>
    </Dialog>
  )
}

export default CalendlyDialog

const featuresData = {
  topFeatures: [
    {
      category: 'Language practice',
      features: [
        'Speaking and pronunciation activities',
        'Writing exercises with instant AI feedback',
        'Reading and listening comprehension tasks',
      ],
    },
    {
      category: 'Assessment & Feedback',
      features: [
        'AI auto-grading for assignments',
        'Proficiency level estimation ACTFL/WIDA',
        'Analytics for student performance',
      ],
    },
    {
      category: 'Teacher Tools',
      features: [
        'Customizable rubrics for grading',
        'Ready-to-use activities from the Global Library',
        'Integration with Google Classroom, Clever, and Canvas',
      ],
    },
    {
      category: 'AI Powered Features',
      features: [
        'AI-generated content for activities',
        'Grammar insights and automated corrections',
        'Real-time feedback for improvement',
      ],
    },
  ],
}
