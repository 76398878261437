import { useEffect, useState, useRef } from 'react'

export function usePopover({ showOnMount = false }: { showOnMount?: boolean }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [preferredPlacement, setPreferredPlacement] = useState<'top' | 'bottom' | 'left' | 'right'>('top')

  const refPopoverTrigger = useRef<HTMLElement | null>(null)

  const handleOpenPopover = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget)
    setPreferredPlacement(determineBestPlacement(e.currentTarget))
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const determineBestPlacement = (element: HTMLElement) => {
    // Example logic to determine best placement based on viewport size
    const { innerHeight } = window
    const rect = element.getBoundingClientRect()

    // Determine placement based on available space
    if (rect.bottom + 400 > innerHeight) {
      return 'top'
    } else {
      return 'bottom'
    }
  }

  useEffect(() => {
    if (showOnMount && refPopoverTrigger.current != null) {
      setAnchorEl(refPopoverTrigger.current)
    }
  }, [showOnMount])

  return {
    anchorEl,
    handleOpenPopover,
    handleClosePopover,
    refPopoverTrigger,
    preferredPlacement,
    open: Boolean(anchorEl),
  }
}
