import { Popover } from '@mui/material'

import { AudioSelector } from '.'

import { POPOVER_POSITIONS } from '@/constants/popover.constants'

export function AudioSelectorPopover({
  anchorEl,
  handleClosePopover,
  handleAudioUrl,
  position = 'top',
  enabled = true,
}) {
  const open = Boolean(anchorEl)

  const isOpen = open && enabled

  return (
    <Popover
      anchorEl={anchorEl}
      sx={{
        '& .MuiPaper-root': {
          background: '#FFF',
          boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.10) !important',
          border: '1px solid rgba(0,0,0,0.08)',
          borderRadius: '8px',
        },
      }}
      {...POPOVER_POSITIONS[position]}
      open={isOpen}
      onClose={handleClosePopover}
    >
      <AudioSelector handleAudioUrl={handleAudioUrl} />
    </Popover>
  )
}
