/* eslint-disable jsx-a11y/no-autofocus */
import { useState } from 'react'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import { Box, FormControl, InputLabel, ListItemButton, OutlinedInput } from '@mui/material'
import { Search } from '@mui/icons-material'

import useKeyPress from '@/utils/useKeyPress'
import { getYouTubeVideo } from '@/utils/youtube-getVideo'
import { searchYouTubeVideos } from '@/utils/youtube-search-videos'

export function YoutubeSearcher({ onSelectVideo }) {
  const [searchResults, setSearchResults] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [search, setSearch] = useState('')

  const handlePreviewClick = async video => {
    setSelectedVideo(video)

    await getYouTubeVideo(video.id)
  }

  const handleDialogClose = () => {
    setSelectedVideo(null)
  }

  const handleSearch = async event => {
    event.preventDefault()
    event.stopPropagation()

    if (search === '') {
      if (searchResults.length === 0) {
        return
      }

      setSearchResults([])

      return
    }

    const results = await searchYouTubeVideos(search)

    setSearchResults(results)
  }

  useKeyPress(['Enter'], handleSearch)

  return (
    <>
      <div>
        <Box sx={{ p: 1, display: 'flex', mt: 2 }}>
          <FormControl fullWidth variant="outlined">
            <InputLabel
              htmlFor="google-search-input"
              sx={{
                fontSize: '12px',
                padding: '0px',
              }}>
              Search YouTube
            </InputLabel>
            <OutlinedInput
              autoFocus
              endAdornment={
                <IconButton color="secondary" onClick={handleSearch}>
                  <Search sx={{ fontSize: '20px', color: '#344054' }} />
                </IconButton>
              }
              label="Search YouTube"
              sx={{
                fontSize: '12px',
                paddingRight: '5px',
              }}
              value={search}
              variant="outlined"
              onChange={e => {
                setSearch(e.target.value)
              }}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            maxHeight: '500px',
            overflow: 'auto',
            width: '550px',
          }}>
          <List>
            {searchResults.map(video => (
              <ListItemButton key={video.id} onClick={() => onSelectVideo(video)}>
                <ListItemAvatar>
                  <Avatar
                    src={video.thumbnailUrl}
                    sx={{
                      width: 150,
                      height: 85,
                      marginRight: 2,
                      borderRadius: 1,
                    }}
                    variant="square"
                  />
                </ListItemAvatar>
                <ListItemText primary={video.title} secondary={video.description} />
                <IconButton
                  color="secondary"
                  onClick={e => {
                    e.stopPropagation()

                    handlePreviewClick(video)
                  }}>
                  <PlayArrowIcon />
                </IconButton>
              </ListItemButton>
            ))}
          </List>
        </Box>
      </div>
      {selectedVideo ? (
        <Dialog open onClose={handleDialogClose}>
          <iframe
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            height="315"
            src={`https://www.youtube.com/embed/${selectedVideo.id}`}
            title={selectedVideo.title}
            width="560"
          />
        </Dialog>
      ) : null}
    </>
  )
}
