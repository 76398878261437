/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { useQuery } from '@tanstack/react-query'
import { type DependencyList, useEffect, useMemo, useRef, useState } from 'react'
import { BlockNoteEditor } from '@blocknote/core'

import { mediaAreaQueryKeys } from './media-area-query-keys'

import { getMediaArea } from '@/services/media-area/get-media-area.service'
import { type MediaAreaWithId } from '@/models/media-area/media-area.model'
import { type BlocksMediaArea, SCHEMA_MEDIA_AREA } from '@/components/MediaArea/media-area-schema'
import { mediaAreaAdapter } from '@/utils/media-area.utils'

export function useMediaArea({ mediaAreaId, enabled = true }: { mediaAreaId: string; enabled?: boolean }) {
  const query = useQuery({
    queryFn: () => getMediaArea({ mediaAreaId: mediaAreaId }),
    queryKey: mediaAreaQueryKeys.one(mediaAreaId),
    enabled: enabled && mediaAreaId != null && mediaAreaId !== '',
  })

  return {
    mediaAreaQuery: query,
  }
}

export function useMediaAreaEditor({
  blocksData,
  dependencies = [],
}: {
  blocksData: MediaAreaWithId['blocks'] | undefined
  dependencies?: DependencyList
}) {
  const [initialContent, setInitialContent] = useState<BlocksMediaArea | undefined | 'loading'>(undefined)

  const editor = useMemo(() => {
    if (initialContent === 'loading') {
      return undefined
    }

    return BlockNoteEditor.create({ initialContent, schema: SCHEMA_MEDIA_AREA })
  }, [initialContent])

  useEffect(() => {
    const blocksInitialContent = mediaAreaAdapter.fromDB(blocksData ?? undefined)

    setInitialContent(blocksInitialContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies])

  return editor
}

export function useMediaAreaDimensions({ scale = 1, isEditable }: { scale?: number; isEditable?: boolean }) {
  const [maxDimensions, setMaxDimensions] = useState<{
    maxWidth: number
    maxHeight: number
  }>()

  const widthAndHeight = scale ? (100 / scale).toString() + '%' : '100%'
  const refMediaArea = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const mediaArea = refMediaArea.current

    if (!mediaArea) return

    const updateDimensions = () => {
      setMaxDimensions({
        maxWidth: (mediaArea.scrollWidth - 148) / scale,
        maxHeight: mediaArea.scrollHeight / scale,
      })
    }

    const resizeObserver = new ResizeObserver(() => {
      updateDimensions()
    })

    resizeObserver.observe(mediaArea)

    const handleWindowResize = () => {
      updateDimensions()
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      resizeObserver.unobserve(mediaArea)
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [scale, isEditable])

  return { maxDimensions, widthAndHeight, refMediaArea }
}
