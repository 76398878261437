import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export function InputFileHidden({ onChange, disabled }) {
  return (
    <Button
      component="label"
      disabled={disabled}
      role={undefined}
      size="small"
      startIcon={<CloudUploadIcon />}
      tabIndex={-1}
      variant="contained">
      Upload file
      <VisuallyHiddenInput type="file" onChange={onChange} />
    </Button>
  )
}
