import { useState, useEffect } from 'react'
import { PollyClient, SynthesizeSpeechCommand, DescribeVoicesCommand } from '@aws-sdk/client-polly'
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { Howl } from 'howler'

import { isSafari } from './browser-type'
import getTextSpeechMarks from './aws-polly-speech-marks'
import googleTranslate from './googleTranslate'

const accessKeyId = import.meta.env.VITE_AMAZON_POLLY_ACCESS_KEY_ID
const secretAccessKey = import.meta.env.VITE_AMAZON_POLLY_SECRET_ACCESS_KEY
const awsConfiguration = {
  region: 'us-east-1',
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: 'us-east-1' }),
    accessKeyId,
    secretAccessKey,
    identityPoolId: 'us-east-1:55f4d596-26ef-4c3b-ab64-b7ab8769ceab',
  }),
}

async function streamToArrayBuffer(stream) {
  let result = new Uint8Array(0)
  const reader = stream.getReader()

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const { done, value } = await reader.read()

    if (done) {
      break
    }
    const newResult = new Uint8Array(result.length + value.length)

    newResult.set(result)
    newResult.set(value, result.length)
    result = newResult
  }

  return result
}

/**
 * Custom hook to list Amazon Polly Voices and filter voices for given language.
 *
 * @param language Language to get voices for.
 *
 * @returns Object - An object with all the voices and specific language voices if provided the language.
 */

export const usePollyVoices = lang => {
  const [voices, setVoices] = useState(null)
  const [langVoices, setLangVoices] = useState(null)
  const [status, setStatus] = useState(null)
  const [loading, setLoading] = useState(false)

  const input = {
    Engine: 'standard',
    IncludeAdditionalLanguageCodes: true,
  }

  const fetchVoices = async () => {
    setLoading(true)
    try {
      const client = new PollyClient(awsConfiguration)
      const command = new DescribeVoicesCommand(input)
      const response = await client.send(command)
      const { Voices } = response

      setVoices(Voices)
      setLoading(false)
      setStatus('success')
    } catch (e) {
      console.log('fetchVoices', e)
      setStatus('failed')
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchVoices()
  }, [])

  useEffect(() => {
    if (voices && lang) {
      if (!langVoices) {
        const filteredLangVoices = voices.filter(voice => voice.LanguageCode.includes(lang == 'zh' ? 'CN' : lang))

        setLangVoices(filteredLangVoices)
      }
      if (langVoices && !langVoices.some(voice => voice.LanguageCode.includes(lang))) {
        const filteredLangVoices = voices.filter(voice => voice.LanguageCode.includes(lang == 'zh' ? 'CN' : lang))

        setLangVoices(filteredLangVoices)
      }
    }
  }, [voices, lang])

  return { voices, langVoices, status, loading }
}

const usePollyTextToSpeech = targetText => {
  const voiceId = localStorage.getItem('voiceId')
  const voiceSpeed = isSafari ? 1 : localStorage.getItem('voiceSpeed')
  const playbackRate = voiceSpeed ?? 1

  const [playing, setPlaying] = useState(false)
  const [paused, setPaused] = useState(false)
  const [text, setText] = useState(null)
  const [loading, setLoading] = useState(false)
  const [speechVoiceId, setSpeechVoiceId] = useState(voiceId)
  const [textToSpeechUrl, setTextToSpeechUrl] = useState(null)
  const [currentWord, setCurrentWord] = useState(null)
  const [speechMarks, setSpeechMarks] = useState([])
  const [howl, setHowl] = useState(null)
  const [timeouts, setTimeouts] = useState([])

  async function fetchSpeechMarks() {
    try {
      const marks = await getTextSpeechMarks(targetText)

      setSpeechMarks(marks)
    } catch (e) {
      console.log('fetchSpeechMarks', e)
    }
  }

  useEffect(() => {
    if (textToSpeechUrl) {
      playUrlAudio(textToSpeechUrl)
    }
  }, [textToSpeechUrl])

  useEffect(() => {
    if (targetText) fetchSpeechMarks()

    // howl unload
    return () => {
      if (howl) {
        howl.unload()
      }
    }
  }, [targetText, playbackRate])

  const getCurrentSpokenWord = () => {
    const newTimeouts = []
    const timeAdjustment = (howl.seek() * 1000) / playbackRate

    speechMarks.forEach((speechMark, index) => {
      const timeoutId = setTimeout(
        () => {
          setCurrentWord({ ...speechMark, index })
          if (index === speechMarks.length - 1) {
            setTimeout(() => {
              setCurrentWord(null)
            }, 600)
          }
        },
        speechMark.time - timeAdjustment > 0 ? speechMark.time - timeAdjustment : 0,
      )

      newTimeouts.push(timeoutId)
    })

    setTimeouts(newTimeouts)
  }

  useEffect(() => {
    if (playing) {
      getCurrentSpokenWord()
    }
  }, [playing])

  const pollyTextToSpeech = ({ speechText = null, lang = null, voice = null }) => {
    const langVoice = localStorage.getItem('voiceId')

    if (speechText && lang && voice) {
      if (speechText !== text) {
        setText(speechText)
        setSpeechVoiceId(voice)
        getTextToSpeech({ speechText, lang, voice })
      } else {
        playUrlAudio(textToSpeechUrl)
      }
    } else if (langVoice !== speechVoiceId) {
      setSpeechVoiceId(langVoice)
      setText(targetText)
      getTextToSpeech({
        speechText: null,
        lang: null,
        voice: null,
      })
    } else if (targetText && targetText !== text && !loading) {
      setText(targetText)
      getTextToSpeech({
        speechText: null,
        lang: null,
        voice: null,
      })
    } else {
      if (!playing && !loading) {
        playUrlAudio(textToSpeechUrl)
      }
    }
  }

  const getTextToSpeech = async ({ speechText, lang, voice }) => {
    const voiceId = localStorage.getItem('voiceId')
    const languageCode = localStorage.getItem('languageCode')
    const input = {
      OutputFormat: 'mp3',
      Text: speechText ? speechText : targetText,
      TextType: 'text',
      VoiceId: voice ? voice : voiceId,
      LanguageCode: lang ? lang : languageCode,
    }

    try {
      setLoading(true)
      const client = new PollyClient(awsConfiguration)
      const command = new SynthesizeSpeechCommand(input)
      const stream = await client.send(command).then(response => response.AudioStream)
      let arrayBuffer = await streamToArrayBuffer(stream)
      let buffer = arrayBuffer.buffer
      let blob = new Blob([buffer])
      let url = URL.createObjectURL(blob)

      setTextToSpeechUrl(url)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log('pollyTextToSpeech', e)
    }
  }

  const playUrlAudio = (url, speed = null) => {
    if (!url) return
    var sound = new Howl({
      src: [url],
      format: ['mp3'],
      rate: speed ?? playbackRate,
      volume: 1,
      html5: !isSafari,
      onplay: function () {
        setPlaying(true)
      },
      onend: function () {
        setPlaying(false)
        setPaused(false)
      },
    })

    sound?.play?.()
    setHowl(sound)
  }

  // { LanguageCode: langCode, Id: voiceId }
  const playTestVoice = async (defaultVoice, defaultLangCode) => {
    const voiceId = defaultVoice ?? localStorage.getItem('voiceId')
    const langCode = defaultLangCode ?? localStorage.getItem('languageCode')

    let sampleText = 'Hi! My name is placeholder. I will be the narrator of this assignment.'

    sampleText = sampleText.replace('placeholder', voiceId)
    if (!playing && !loading) {
      let translatedText

      if (langCode.split('-')[0] === 'en') {
        translatedText = sampleText
      } else {
        translatedText = await translateText({
          text: sampleText,
          lang: langCode.split('-')[0],
        })
      }
      pollyTextToSpeech({
        speechText: translatedText,
        lang: langCode,
        voice: voiceId,
      })
    }
  }

  const pause = () => {
    if (howl) {
      howl.pause()
      setPaused(true)
      // Clear timeouts when audio is paused
      timeouts.forEach(timeoutId => {
        clearTimeout(timeoutId)
      })
    }
  }

  const resume = () => {
    if (howl) {
      howl.play()
      setPaused(false)
      // Recreate timeouts when audio is resumed
      getCurrentSpokenWord()
    }
  }

  const reset = () => {
    if (howl) {
      howl.stop()
      // Clear timeouts when audio is reset
      timeouts.forEach(timeoutId => {
        clearTimeout(timeoutId)
      })
      setCurrentWord(null)
      setPlaying(false)
      setHowl(null)
      setPaused(false)
    }
  }

  return {
    pollyTextToSpeech,
    playTestVoice,
    playUrlAudio,
    loading,
    playing,
    currentWord,
    pause,
    resume,
    reset,
    paused,
  }
}

export default usePollyTextToSpeech

export const getPhoneticPronunciation = async (ipa, voice, lang) => {
  let url = null
  const voiceId = localStorage.getItem('voiceId')
  const languageCode = localStorage.getItem('languageCode')
  const input = {
    OutputFormat: 'mp3',
    Text: `<phoneme alphabet="ipa" ph="${ipa}"></phoneme>`,
    TextType: 'ssml',
    VoiceId: voice ? voice : voiceId,
    LanguageCode: lang ? lang : languageCode,
  }

  try {
    const client = new PollyClient(awsConfiguration)
    const command = new SynthesizeSpeechCommand(input)
    const stream = await client.send(command).then(response => response.AudioStream)
    let arrayBuffer = await streamToArrayBuffer(stream)
    let buffer = arrayBuffer.buffer
    let blob = new Blob([buffer])

    url = URL.createObjectURL(blob)
    playTextToSpeech(url)
  } catch (e) {
    console.log('pollyTextToSpeech', e)
  }

  return url
}

const playTextToSpeech = url => {
  const voiceSpeed = isSafari ? 1 : localStorage.getItem('voiceSpeed')
  const playbackRate = voiceSpeed ?? 0.7
  // html5 is set to false for safari as it will only play if the audio is played directly from a user interaction
  // Currently, changing the playback rate will not work on safari as it will change the pitch instead of the speed
  var sound = new Howl({
    src: [url],
    format: ['mp3'],
    rate: playbackRate,
    volume: 1,
    html5: !isSafari,
  })

  sound.play()
}

async function translateText({ text, lang }) {
  const translatedText = await new Promise(function (resolve, reject) {
    googleTranslate.translate(text, 'en', lang, (err, translation) => {
      const translated = translation && translation.translatedText

      if (translated || translated == '') {
        resolve(translated)
      } else if (err) {
        reject(err)
      }
    })
  })

  return translatedText
}
