/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  type BlockTypeSelectItem,
  blockTypeSelectItems,
  useDictionary,
  useEditorContentOrSelectionChange,
  useSelectedBlocks,
} from '@blocknote/react'
import { type CSSProperties, useMemo, useState } from 'react'
import { Box, Popover, Stack, Typography } from '@mui/material'

import { type Editor } from './media-area-schema'

import { usePopover } from '@/hooks/ui/usePopover'
import { POPOVER_POSITIONS } from '@/constants/popover.constants'
import { ChevronDownIcon } from '@/assets/icons/chevron-icons'

export function CustomBlockTypeSelect({ editor }: { editor: Editor }) {
  const { anchorEl, handleClosePopover, handleOpenPopover, preferredPlacement } = usePopover({ showOnMount: false })

  const selectedBlocks = useSelectedBlocks(editor)
  const dict = useDictionary()

  const [block, setBlock] = useState(editor.getTextCursorPosition().block)

  const items = blockTypeSelectItems(dict).map(item => {
    let name = item.name
    let style: CSSProperties = {}
    const level = item.props?.level ?? 1

    if (item.type === 'heading') {
      if (level === 1) {
        name = 'Title'
        style = {
          fontSize: '12px',
          fontWeight: 500,
        }
      }
      if (level === 2) {
        name = 'Headline'
        style = {
          fontSize: '11px',
          fontWeight: 500,
        }
      }
      if (level === 3) {
        name = 'Sub headline'
        style = {
          fontSize: '10px',
          fontWeight: 500,
        }
      }
    }

    if (item.type === 'bulletListItem') name = '• Bullet list'
    if (item.type === 'numberedListItem') name = '1. Numbered list'

    return {
      ...item,
      name,
      style,
    }
  })

  const filteredItems: (BlockTypeSelectItem & { style?: CSSProperties })[] = useMemo(() => {
    return items.filter(item => item.type in editor.schema.blockSchema)
  }, [editor, items])

  const shouldShow: boolean = useMemo(
    () => filteredItems.find(item => item.type === block.type) !== undefined,
    [block.type, filteredItems],
  )

  const fullItems = useMemo(() => {
    const onClick = (item: BlockTypeSelectItem) => {
      editor.focus()

      for (const blockSelected of selectedBlocks) {
        editor.updateBlock(blockSelected, {
          type: item.type as any,
          props: item.props as any,
        })
      }
    }

    return filteredItems.map(item => {
      const Icon = item.icon

      return {
        text: item.name,
        icon: <Icon size={16} />,
        onClick: () => {
          onClick(item)
        },
        isSelected: item.isSelected(block),
        style: item.style,
      }
    })
  }, [block, filteredItems, editor, selectedBlocks])

  const blockSelected = useMemo(() => fullItems.find(item => item.isSelected), [fullItems])

  const isOpen = Boolean(anchorEl) && shouldShow

  const fullItemsOrdered = useMemo(() => {
    const paragraph = fullItems[0]

    return [...fullItems.slice(1), paragraph]
  }, [fullItems])

  useEditorContentOrSelectionChange(() => {
    setBlock(editor.getTextCursorPosition().block)
  }, editor)

  return (
    <>
      <Stack
        alignItems="center"
        direction="row"
        sx={{
          cursor: 'pointer',
          paddingBlock: '2px',
          paddingInline: '10px',
          borderRadius: '4px',
          transition: 'background-color 0.2s',
          gap: '8px',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
        onClick={handleOpenPopover}
      >
        <Typography fontSize="13px" fontWeight={500}>
          {blockSelected?.text ?? 'Select a block'}
        </Typography>
        <ChevronDownIcon width={16} />
      </Stack>
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClosePopover}
        {...POPOVER_POSITIONS[preferredPlacement]}
        sx={{
          '& .MuiPaper-root': {
            paddingBlock: '2px',
            paddingInline: '2px',
            borderRadius: '8px',
            background: '#fff',
            boxShadow: '0 4px 12px #cfcfcf !important',
            mt: preferredPlacement === 'top' ? '-10px' : '10px',
            minWidth: '120px',
          },
        }}
      >
        <Stack>
          {fullItemsOrdered.map(item => (
            <Stack
              key={item.text}
              direction="row"
              gap="8px"
              sx={{
                cursor: 'pointer',
                paddingInline: '8px',
                paddingBlock: '4px',
                borderRadius: '6px',
                transition: 'background-color 0.2s ease-in',
                '&:hover': {
                  backgroundColor: '#f5f5f5',
                },
              }}
              onClick={item.onClick}
            >
              <Box>
                <Typography
                  color="#101828"
                  sx={{
                    fontSize: '10px',
                    ...item.style,
                  }}
                >
                  {item.text}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  )
}
