import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
	name: "search",
	initialState: {
		searchState: "discover", // or navbar  (the search bar is in the navbar or discover page)
		algoliaFilters: [],
		filtersString: "",
		query: "",
		searchInitialized: false,
		searchLanguage: null,
		selectedCardTypes: ["all"],
		searchFocussed: false,
	},
	reducers: {
		setSearchState: (state, action) => {
			state.searchState = action.payload;
		},
		setAlgoliaFilters: (state, action) => {
			state.algoliaFilters = action.payload;
		},
		setFilterString: (state, action) => {
			state.filtersString = action.payload;
		},
		setSearchQuery: (state, action) => {
			state.query = action.payload;
		},
		setSearchLanguage: (state, action) => {
			state.searchLanguage = action.payload;
		},
		initializeSearchState: (state, action) => {
			state.searchInitialized = true;
			state.filtersString = action.payload.filtersString;
			state.searchLanguage = action.payload.searchLanguage;
			state.query = action.payload.query;
		},
		setCardTypes: (state, action) => {
			state.selectedCardTypes = action.payload;
		},
		resetSearchState: (state) => {
			state.searchState = "discover";
			state.algoliaFilters = [];
			state.selectedCardTypes = ["all"];
			state.query = "";
			state.filtersString = `public:true AND plays > 1`;
			// state.searchInitialized = false;

			// state.filtersString = "";
			// state.searchLanguage = null;
		},
		setSearchFocus: (state, action) => {
			state.searchFocussed = action.payload;
		},
	},
});

export const {
	setSearchState,
	setAlgoliaFilters,
	setFilterString,
	setSearchQuery,
	setSearchLanguage,
	initializeSearchState,
	setCardTypes,
	resetSearchState,
	setSearchFocus,
} = searchSlice.actions;

export default searchSlice.reducer;
