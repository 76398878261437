const notificationTypes = {
	NEW_ASSIGNMENT: "NEW_ASSIGNMENT",
	FEEDBACK_FROM_TEACHER: "FEEDBACK_FROM_TEACHER",
	MESSAGE_FROM_STUDENT: "MESSAGE_FROM_STUDENT",
	PHRASE_MARKED_CORRECT: "PHRASE_MARKED_CORRECT",
	STUDENT_PROGRESS: "STUDENT_PROGRESS",
	PLAYLIST_FOLLOWERS: "PLAYLIST_FOLLOWERS",
	PLAYLIST_PLAYS: "PLAYLIST_PLAYS",

	// New notifications
	ASSESSMENT_SUBMITTED: "ASSESSMENT_SUBMITTED", // Notification FOR TEACHER when student submits assessment
	ASSESSMENT_SCORED: "ASSESSMENT_SCORED", // Notification FOR STUDENT when teacher scores assessment
	// Comment
	NEW_COMMENT: "NEW_COMMENT",
};

export default notificationTypes;
