/* eslint-disable react/no-unstable-nested-components */
import {
  FormattingToolbarController,
  FormattingToolbar,
  FileCaptionButton,
  FileReplaceButton,
  BasicTextStyleButton,
  TextAlignButton,
  ColorStyleButton,
  NestBlockButton,
  UnnestBlockButton,
  CreateLinkButton,
} from '@blocknote/react'

import { CustomBlockTypeSelect } from './CustomBlockTypeSelect'
import { type Editor } from './media-area-schema'

export function CustomFormattingToolbar({ editor }: { editor: Editor }) {
  return (
    <FormattingToolbarController
      formattingToolbar={() => (
        <FormattingToolbar>
          <CustomBlockTypeSelect editor={editor} />

          <FileCaptionButton key="fileCaptionButton" />
          <FileReplaceButton key="replaceFileButton" />

          <BasicTextStyleButton key="boldStyleButton" basicTextStyle="bold" />
          <BasicTextStyleButton key="italicStyleButton" basicTextStyle="italic" />
          <BasicTextStyleButton key="underlineStyleButton" basicTextStyle="underline" />
          <BasicTextStyleButton key="strikeStyleButton" basicTextStyle="strike" />
          {/* Extra button to toggle code styles */}
          <BasicTextStyleButton key="codeStyleButton" basicTextStyle="code" />

          <TextAlignButton key="textAlignLeftButton" textAlignment="left" />
          <TextAlignButton key="textAlignCenterButton" textAlignment="center" />
          <TextAlignButton key="textAlignRightButton" textAlignment="right" />

          <ColorStyleButton key="colorStyleButton" />

          <NestBlockButton key="nestBlockButton" />
          <UnnestBlockButton key="unnestBlockButton" />

          <CreateLinkButton key="createLinkButton" />
        </FormattingToolbar>
      )}
    />
  )
}
