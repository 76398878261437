import { logEvent } from 'firebase/analytics'

import { analytics } from '@/firebase'

export const logOpenPricingModal = (data = {}) => {
  logEvent(analytics, 'open_pricing_modal', data)
}

export const logOpenScoreCardPLGAccordion = (data = {}) => {
  logEvent(analytics, 'open_score_card_plg_accordion', data)
}
