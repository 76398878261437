/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import {
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA,
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA_ITEM,
  type CustomBlocksTypesMediaArea,
} from '@/components/MediaArea/custom-blocks-types-media-area'
import { type BlocksMediaArea } from '@/components/MediaArea/media-area-schema'
import { type MediaAreaWithId } from '@/models/media-area/media-area.model'

export const mediaAreaAdapter = {
  fromDB: (blocks: MediaAreaWithId['blocks'] | undefined) => {
    let blocksToSet: BlocksMediaArea | undefined

    if (blocks) {
      blocksToSet = blocks.length > 0 ? blocks : undefined
    }

    // @ts-ignore
    blocksToSet = blocksToSet?.map(block => {
      const blockAsString = block as unknown as string

      if (
        CUSTOM_BLOCKS_TYPES_MEDIA_AREA_ITEM.includes(block.type) &&
        block.type !== CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT
      ) {
        if (typeof blockAsString === 'string') {
          return JSON.parse(blockAsString) as BlocksMediaArea
        }

        return {
          ...block,
          content: 'none',
        }
      }

      if (typeof blockAsString === 'string') {
        return JSON.parse(blockAsString) as BlocksMediaArea
      }

      return block
    })

    return blocksToSet
  },
  toDB: (blocks: BlocksMediaArea) => {
    const blocksUpdated = blocks.map(block => {
      if (
        CUSTOM_BLOCKS_TYPES_MEDIA_AREA_ITEM.includes(block.type) &&
        block.type !== CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT
      ) {
        return JSON.stringify(block)
      }

      if (block.type === 'table') {
        return JSON.stringify(block)
      }

      return block
    })

    return blocksUpdated
  },
}

const KEYS_MEDIA_AREA_ITEMS = {
  [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_IMAGE]: ['imageUrl'],
  [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_VIDEO]: ['url'],
  [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_AUDIO]: ['audioUrl'],
  [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_DOCUMENT]: ['url'],
  [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_PODCAST]: ['iframe'],
  [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT_TO_SPEECH]: ['text', 'language'],
}

const CUSTOM_MEDIA_AREAS = [
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_IMAGE,
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_VIDEO,
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_AUDIO,
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_DOCUMENT,
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_PODCAST,
  CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT_TO_SPEECH,
]

export const mediaAreaParse = (blocks: BlocksMediaArea, typeBlock?: string) => {
  const blocksParsed: {
    id: string
    type: CustomBlocksTypesMediaArea
    value: any
  }[] = []

  for (const block of blocks) {
    let blockToCheck = block

    if (typeof block === 'string') {
      blockToCheck = JSON.parse(block) as BlocksMediaArea[number]
    }

    const currentTypeBlock = typeBlock ?? blockToCheck.type

    const object = getValueMediaArea(blockToCheck, currentTypeBlock, KEYS_MEDIA_AREA_ITEMS[currentTypeBlock])

    if (object) blocksParsed.push(object)

    if (block?.children?.length > 0) {
      const childrenParsed = mediaAreaParse(block.children, typeBlock)

      blocksParsed.push(...childrenParsed)
    }
  }

  return blocksParsed
}
interface BlockTable {
  id: string
  type: string
  props: Record<string, any>
  content: {
    type: string
    rows: {
      cells: {
        type: string
        text: string
      }[][]
    }[]
  }
  children: BlocksMediaArea
}

const getValueMediaArea = (block: BlocksMediaArea[number], typeBlock: string, keys: string[]) => {
  if (block.type === typeBlock) {
    if (CUSTOM_MEDIA_AREAS.includes(typeBlock)) {
      const value = keys.reduce<Record<string, any>>((acc, key) => {
        acc[key] = block.props[key as keyof typeof block.props]

        return acc
      }, {})

      return {
        id: block.id,
        type: block.type,
        value,
      }
    } else if (typeBlock === 'table') {
      const blockTable = block as BlockTable

      const rows = blockTable.content.rows

      const allText = rows.reduce((acc, curr, index) => {
        const cells = curr.cells.map(cell => cell.map(c => c.text).join(', '))

        if (index === 0) {
          return (
            `
              \n
            ${cells.join(' | ')}` + `\n`
          )
        }

        // return acc + cells.join(', ') + `\n`
        return `
          ${acc} 
          ${cells.join(' | ')}
          `
      }, '')
      // .trim()

      return {
        id: blockTable.id,
        type: blockTable.type,
        value: {
          text: allText,
        },
      }
    } else {
      const content = block.content as { type: string; text: string }[]

      const allText = content.reduce((acc, curr) => {
        if (curr.type === 'text') {
          acc += curr.text + '\n'
        }

        return acc
      }, '')

      return {
        id: block.id,
        type: typeBlock,
        value: {
          text: allText,
        },
      }
    }
  }
}
