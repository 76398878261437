import { useDispatch, useSelector } from 'react-redux'

import { myFunctions } from '../firebase'
import { updateAvialableCredits } from '../Redux/permissionsSlice'

import { ANALYTICS_PLANS, ASSIGNMENT_SETTINGS_PLANS } from '@/constants/plans/variables-plans'

const usePermissions = () => {
  const dispatch = useDispatch()
  const {
    plan = null,
    loaded: permissionsLoaded,
    isStripePlan = null,
    isInstitutionPlan = null,
    credits = null,
    subscriptionId = null,
    contact = null,
    refreshDate = null,
    permissions = [],
  } = useSelector(({ permissions }) => permissions)

  const uid = useSelector(({ user: { auth = null } }) => auth?.uid)
  const consumeLongResponseCardCredits = async creditsConsumed => {
    if ((!isStripePlan && !isInstitutionPlan) || !subscriptionId) {
      return {
        error: true,
        message: 'No subscription found',
      }
    }
    try {
      if (isInstitutionPlan) {
        // consume institution credits
        const { data } = await myFunctions.consumeInstitutionCredit({
          subscriptionId,
          creditsConsumed,
        })

        if (data?.success) {
          // dispatch action to update credits
          dispatch(updateAvialableCredits(data?.remainingCredits))
        }

        return data
      } else if (isStripePlan) {
        // consume stripe credits
        const { data } = await myFunctions.consumeProPlanCredit({
          uid: uid,
          creditsConsumed,
        })

        if (data?.success) {
          // dispatch action to update credits
          dispatch(updateAvialableCredits(data?.credits))
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const has = permission => permissions?.includes(permission)

  return {
    plan,
    permissionsLoaded,
    isStripePlan,
    refreshDate,
    isInstitutionPlan,
    credits,
    subscriptionId,
    contact,
    hasGradebook: has(ANALYTICS_PLANS.ANALYTICS_GRADEBOOK),
    hasGoogleClassroomGradePassback: has(ASSIGNMENT_SETTINGS_PLANS.GOOGLE_CLASSROOM_GRADE_PASSBACK),
    hasAssessments: has(ASSIGNMENT_SETTINGS_PLANS.ASSESSMENTS),
    hasSectionAnalytics: has(ANALYTICS_PLANS.ANALYTICS_CLASSROOM_ANALYTICS),
    hasStudentReports: has(ANALYTICS_PLANS.ANALYTICS_STUDENT_PROGRESS_REPORTS),
    consumeLongResponseCardCredits,
    permissions: permissions || [],
  }
}

export default usePermissions
