export function AlignCenterIcon(props) {
  return (
    <svg
      className="alignCenter"
      style={{
        width: 14,
        height: 14,
        display: 'block',
        fill: '#fff',
        flexShrink: 0,
        marginRight: 0,
      }}
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M14.44 15.325H1.554a.598.598 0 0 1-.602-.602c0-.335.267-.601.602-.601H14.44c.334 0 .608.266.608.601a.604.604 0 0 1-.608.602zm0-13.447H1.554a.598.598 0 0 1-.602-.601c0-.335.267-.602.602-.602H14.44c.334 0 .608.267.608.602a.604.604 0 0 1-.608.601zm-8.736 10.34h4.738c1.12 0 1.776-.65 1.776-1.905V5.687c0-1.254-.656-1.905-1.922-1.905H5.704c-1.266 0-1.922.65-1.922 1.905v4.626c0 1.254.656 1.905 1.922 1.905zm.07-1.149c-.545 0-.843-.28-.843-.85V5.78c0-.568.298-.85.843-.85h4.452c.539 0 .843.282.843.85v4.44c0 .568-.304.85-.697.85H5.774z" />
    </svg>
  )
}

export function AlignRightIcon(props) {
  return (
    <svg
      className="alignRight"
      style={{
        width: 14,
        height: 14,
        display: 'block',
        fill: '#fff',
        flexShrink: 0,
        marginRight: 0,
      }}
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M14.44 15.325H1.554a.598.598 0 0 1-.602-.602c0-.335.267-.601.602-.601H14.44c.334 0 .608.266.608.601a.604.604 0 0 1-.608.602zm0-13.447H1.554a.598.598 0 0 1-.602-.601c0-.335.267-.602.602-.602H14.44c.334 0 .608.267.608.602a.604.604 0 0 1-.608.601zm-6.408 10.34h4.738c1.12 0 1.776-.65 1.776-1.905V5.687c0-1.254-.657-1.905-1.922-1.905H8.032c-1.266 0-1.922.65-1.922 1.905v4.626c0 1.254.656 1.905 1.922 1.905zm.07-1.149c-.545 0-.844-.28-.844-.85V5.78c0-.568.3-.85.844-.85h4.451c.54 0 .844.282.844.85v4.44c0 .568-.305.85-.697.85H8.102z" />
    </svg>
  )
}

export function AlignLeftIcon(props) {
  return (
    <svg
      className="alignLeft"
      style={{
        width: 14,
        height: 14,
        display: 'block',
        fill: '#fff',
        flexShrink: 0,
        marginRight: 0,
      }}
      viewBox="0 0 16 16"
      {...props}
    >
      <path d="M14.44 15.325H1.554a.598.598 0 0 1-.602-.602c0-.335.267-.601.602-.601H14.44c.334 0 .608.266.608.601a.604.604 0 0 1-.608.602zm0-13.447H1.554a.598.598 0 0 1-.602-.601c0-.335.267-.602.602-.602H14.44c.334 0 .608.267.608.602a.604.604 0 0 1-.608.601zM3.372 12.218H8.11c1.12 0 1.776-.65 1.776-1.905V5.687c0-1.254-.657-1.905-1.922-1.905H3.372c-1.266 0-1.922.65-1.922 1.905v4.626c0 1.254.656 1.905 1.922 1.905zm.07-1.149c-.545 0-.844-.28-.844-.85V5.78c0-.568.3-.85.844-.85h4.451c.54 0 .844.282.844.85v4.44c0 .568-.305.85-.697.85H3.442z" />
    </svg>
  )
}
