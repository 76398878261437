import { BlockNoteSchema, defaultBlockSpecs } from '@blocknote/core'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA } from './custom-blocks-types-media-area'
import { VideoCustomBlock } from './CustomBlocks/VideoCustomBlock'
import {
  ImageCustomBlock,
  AudioCustomBlock,
  DocumentCustomBlock,
  PodcastCustomBlock,
  TextToSpeechCustomBlock,
} from './CustomBlocks'

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const { image, audio, video, file, ...restDefaultBlockSpecs } = defaultBlockSpecs

export const PARAGRAPH_BLOCK = {
  type: 'paragraph',
  props: {
    textColor: 'default',
    backgroundColor: 'default',
    textAlignment: 'left',
  },
  content: [],
  children: [],
}

export const SCHEMA_MEDIA_AREA = BlockNoteSchema.create({
  blockSpecs: {
    ...restDefaultBlockSpecs,
    [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_VIDEO]: VideoCustomBlock,
    [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_IMAGE]: ImageCustomBlock,
    [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_AUDIO]: AudioCustomBlock,
    [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_DOCUMENT]: DocumentCustomBlock,
    [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_PODCAST]: PodcastCustomBlock,
    // [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_WEB]: WebCustomBlock,
    [CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT_TO_SPEECH]: TextToSpeechCustomBlock,
  },
})

export type Editor = typeof SCHEMA_MEDIA_AREA.BlockNoteEditor

export type BlocksMediaArea = Editor['document']
