/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react'

import { useUserPlan } from './useUserPlan'

import { PERMISSIONS_MAP } from '@/constants/plans/type-plans'

export function useCheckPermissions({
  requiredPermission,
  ignorePermissions = false,
}: {
  requiredPermission: string | string[]
  ignorePermissions?: boolean
}) {
  const [hasPermission, setHasPermission] = useState(false)

  const { userPlan } = useUserPlan()

  useEffect(() => {
    const checkPermission = () => {
      if (ignorePermissions) {
        return true
      }

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      const permissions = PERMISSIONS_MAP[userPlan] || []

      if (typeof requiredPermission === 'string') {
        // @ts-ignore
        return permissions.includes(requiredPermission)
      }

      if (Array.isArray(requiredPermission)) {
        // @ts-ignore
        return requiredPermission.every(permission => permissions.includes(permission))
      }

      return true
    }

    setHasPermission(checkPermission())
  }, [userPlan, ignorePermissions, requiredPermission])

  return {
    hasPermission,
  }
}
