/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { doc, serverTimestamp, setDoc, getDoc } from 'firebase/firestore'
import { ref, uploadBytes } from 'firebase/storage'

import { db, myFunctions, storage } from '../firebase'
import downloadImageFromURL from '../utils/getImageFileFromURL'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    profile: {
      isEmpty: true,
    },
    auth: {
      isEmpty: true,
    },
    authLoaded: false,
    profileLoaded: false,
    drawerOpen: true,
    mobileOpen: false,
  },
  reducers: {
    setUserProfile: (state, action) => {
      state = {
        ...state,
        profile: {
          ...action.payload,
          loaded: true,
          loading: false,
        },
        profileLoaded: true,
      }
      if (action.payload?.roles?.includes('ADMIN')) {
        state.profile.isTeacher = true
      } else if (action.payload?.roles?.includes('USER')) {
        state.profile.isStudent = true
      }

      return state
    },
    setUserAuth: (state, action) => {
      state = {
        ...state,
        auth: {
          ...action.payload,
          loaded: true,
          loading: false,
        },
        authLoaded: true,
      }

      return state
    },
    setLTIState: (state, action) => {
      state.lti = action.payload
    },
    clearUser: (state, action) => {
      state.loaded = false
      state.loading = false
      state.profile = {
        isEmpty: true,
      }
      state.auth = {
        isEmpty: true,
      }
    },
    setUserType: (state, action) => {
      state.profile.isTeacher = action.payload === 'teacher'
      state.profile.isStudent = action.payload === 'student'
    },
    toggleDrawer: (state, action) => {
      state.drawerOpen = !state.drawerOpen
      state.mobileOpen = !state.mobileOpen
    },
    drawerOpen: (state, action) => {
      state.drawerOpen = true
      state.mobileOpen = true
    },
    drawerClosed: (state, action) => {
      state.drawerOpen = false
      state.mobileOpen = false
    },
  },
})

export const {
  setUserProfile,
  setUserAuth,
  clearUser,
  loadingUser,
  setUserType,
  toggleDrawer,
  drawerClosed,
  drawerOpen,
  setLTIState,
} = userSlice.actions

export default userSlice.reducer

export const createNewUserProfile = (profile, other) => {
  return async (dispatch, getState) => {
    try {
      const {
        user: { auth },
      } = getState()
      const { uid, custom_claims = null } = auth

      if (custom_claims?.picture) {
        const file = await downloadImageFromURL(custom_claims?.picture)

        if (file) {
          const storageRef = ref(storage, `profiles/${uid}`)

          await uploadBytes(storageRef, file)
        }
      }
      // filter through the profile object and switch undefined values to ""
      Object.keys(profile).forEach(key =>
        profile[key] === undefined ? (profile[key] = '') : profile[key],
      )

      const user = {
        ...profile,
        dateMade: serverTimestamp(),
        owners: [uid],
        speakableio: true,
      }

      const userRef = doc(db, 'users', uid)

      await setDoc(userRef, user, { merge: true })
      dispatch(setUserProfile({ ...user, isEmpty: false, id: uid, uid }))

      return { success: true }
    } catch (error) {
      console.log(error)

      return { error: true, message: error.message }
    }
  }
}

export const updateUserProfile = update => {
  return async (dispatch, getState) => {
    try {
      const {
        user: { auth, profile },
      } = getState()
      const { uid } = auth
      const userRef = doc(db, 'users', uid)

      await setDoc(userRef, update, { merge: true })
      const updatedDoc = await getDoc(userRef)

      dispatch(setUserProfile({ ...profile, ...updatedDoc.data() }))

      return { success: true }
    } catch (error) {
      console.log(error)

      return { error: true, message: error.message }
    }
  }
}

export const handleSwitchUserType = type => {
  return (dispatch, getState) => {
    dispatch(setUserType(type))
  }
}

export const handleAddUserToIntercomCompany = async (email, school) => {
  if (!email || !school?.schoolid) return

  const options = {
    email: email,
    companyData: {
      company_id: school?.schoolid,
      name: school?.schoolName,
      custom_attributes: {},
    },
  }

  if (school?.schoolid) {
    options.companyData.custom_attributes.schoolid = school?.schoolid
  }
  if (school?.city) {
    options.companyData.custom_attributes.city = school?.city
  }
  if (school?.state) {
    options.companyData.custom_attributes.state = school?.state
  }
  if (school?.rank) {
    options.companyData.custom_attributes.rank = school?.rank
  }

  try {
    const result = await myFunctions.addUserToIntercomCompany(options)

    console.log('school added', result)

    return result
  } catch (error) {
    throw new Error(error)
  }
}
