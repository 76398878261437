import { type SVGProps } from 'react'

export function FilmIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_4226_162267)">
        <path
          d="M2.10156 9H17.1016M2.10156 5.25H5.85156M13.3516 5.25H17.1016M2.10156 12.75H5.85156M13.3516 12.75H17.1016M5.85156 16.5V1.5M13.3516 16.5V1.5M5.70156 16.5H13.5016C14.7617 16.5 15.3917 16.5 15.873 16.2548C16.2964 16.039 16.6406 15.6948 16.8563 15.2715C17.1016 14.7902 17.1016 14.1601 17.1016 12.9V5.1C17.1016 3.83988 17.1016 3.20982 16.8563 2.72852C16.6406 2.30516 16.2964 1.96095 15.873 1.74524C15.3917 1.5 14.7617 1.5 13.5016 1.5H5.70156C4.44144 1.5 3.81138 1.5 3.33008 1.74524C2.90672 1.96095 2.56251 2.30516 2.3468 2.72852C2.10156 3.20982 2.10156 3.83988 2.10156 5.1V12.9C2.10156 14.1601 2.10156 14.7902 2.3468 15.2715C2.56251 15.6948 2.90672 16.039 3.33008 16.2548C3.81138 16.5 4.44144 16.5 5.70156 16.5Z"
          stroke="#101828"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_4226_162267">
          <rect fill="white" height="18" transform="translate(0.601562)" width="18" />
        </clipPath>
      </defs>
    </svg>
  )
}
