export function ActionButtonCustomBlock({ children, onClick }) {
  return (
    <button
      style={{
        transition: 'background 20ms ease-in 0s',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 6px',
        border: 'none',
        borderRight: '1px solid rgba(255, 255, 255, 0.2)',
        outline: 'none',
        background: 'transparent',
        gap: '2px',
      }}
      type="button"
      onClick={e => {
        e.stopPropagation()
        e.preventDefault()
        onClick?.()
      }}
    >
      {children}
    </button>
  )
}
