import { Button, Divider, IconButton, Popover, Stack, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'

import { PLGChip } from './PLGChip'

import { ReactComponent as ArrowCircleUp } from '@/assets/icons/arrow-circle-up.svg'
import { type PLGInterface } from '@/constants/plans/plg.constants'
import { XIcon } from '@/assets/icons/x-icon'
import { POPOVER_POSITIONS } from '@/constants/popover.constants'
import { setHighlightedPlan, setProductsModalOpen } from '@/Redux/appStateSlice'

export function PLGPopover({
  plgPermission,
  handleClosePopover,
  anchorEl,
  placement = 'bottom',
  openPopover,
}: {
  plgPermission: PLGInterface | null
  handleClosePopover: () => void
  anchorEl: Element | null | HTMLElement
  placement?: 'top' | 'bottom' | 'left' | 'right'
  openPopover: boolean
}) {
  const dispatch = useDispatch()

  if (!plgPermission) return null

  return (
    <Popover
      disableRestoreFocus
      anchorEl={anchorEl}
      {...POPOVER_POSITIONS[placement]}
      open={openPopover}
      sx={{
        '& .MuiPaper-root': {
          width: '400px',
          boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.10) !important',
          paddingBlock: '8px',
          paddingInline: '12px',
          borderRadius: '16px',
          background: '#fff',
        },
      }}
      onClose={handleClosePopover}>
      <Stack gap="4px" sx={{ p: 2 }}>
        <Stack direction="row" gap="4px" justifyContent="space-between">
          <Stack alignItems="center" direction="row" gap="4px">
            <ArrowCircleUp />
            <Typography
              sx={{
                color: '#475467',
                fontSize: '18px',
                fontWeight: 500,
              }}>
              {plgPermission.title}
            </Typography>
          </Stack>
          <IconButton onClick={handleClosePopover}>
            <XIcon height={20} width={20} />
          </IconButton>
        </Stack>
        <Divider
          sx={{
            my: '8px',
            borderColor: '#EAECF0',
          }}
        />
        <Stack direction="row" gap={1}>
          {plgPermission.planType.map(plan => (
            <PLGChip key={plan} type={plan} />
          ))}
        </Stack>

        <Stack spacing={2}>
          <Typography
            sx={{
              color: '#475467',
              fontSize: '14px',
              lineHeight: '20px',
              fontWeight: 400,
            }}>
            {plgPermission.description}
          </Typography>
          {plgPermission.img ? (
            <img
              alt={plgPermission.title}
              src={plgPermission.img}
              style={{
                width: '343px',
                borderRadius: '16px',
              }}
            />
          ) : null}
          <Stack direction="row" gap="4px">
            <Button
              variant="contained"
              onClick={() => {
                dispatch(setProductsModalOpen(true))
                if (plgPermission.planType.length > 0) {
                  dispatch(setHighlightedPlan(plgPermission.planType[0]))
                }
              }}>
              {plgPermission.CTA?.primary ?? 'View Details'}
            </Button>
            {plgPermission.link ? (
              <Button href={plgPermission.link} referrerPolicy="no-referrer" target="_blank" variant="text">
                {plgPermission.CTA?.secondary ?? 'Learn more'}
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
    </Popover>
  )
}
