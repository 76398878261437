/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
import { insertOrUpdateBlock } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA, CUSTOM_BLOCKS_GROUP_MEDIA_AREA } from '../custom-blocks-types-media-area'
import { type Editor } from '../media-area-schema'

import { CardPreviewBlock } from './CardPreviewBlock'
import { ContainerCustomBlock } from './ContainerCustomBlock'
import { CustomBlockIcons } from './IconsCustomBlocks'

import { DocumentSelectorPopover } from '@/components/DocumentSelector'
import { DocumentViewer } from '@/components/DocumentViewer/DocumentViewer'
import { usePopover } from '@/hooks/ui/usePopover'

export const DocumentCustomBlock = createReactBlockSpec(
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_DOCUMENT,
    content: 'none',
    propSchema: {
      url: {
        default: '',
      },
      width: {
        default: 0,
      },
      height: {
        default: 0,
      },
      justifyContent: {
        default: 'center',
        values: ['flex-start', 'center', 'flex-end'],
      },
    },
  },
  {
    render: props => {
      const { justifyContent, url: documentUrl, width, height } = props.block.props

      const initialSize = {
        width,
        height,
      }

      const handleUpdateBlock = (url: string) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            url,
          },
        })
      }

      const removeBlock = () => {
        props.editor.removeBlocks([props.block.id])
      }

      const handleResize = (width: number, height: number) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            width,
            height,
          },
        })
      }

      const handleJustifyContent = (place: 'flex-start' | 'center' | 'flex-end') => {
        props.editor.updateBlock(props.block.id, {
          props: {
            justifyContent: place,
          },
        })
      }

      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <DocumentUI
            documentUrl={documentUrl.length ? documentUrl : null}
            handleJustifyContent={handleJustifyContent}
            handleResize={handleResize}
            handleUpdateBlock={handleUpdateBlock}
            initialSize={initialSize.width ? initialSize : null}
            isEditable={props.editor.isEditable}
            justifyContent={justifyContent}
            removeBlock={removeBlock}
          />
        </div>
      )
    },
  },
)

function DocumentUI({
  documentUrl,
  justifyContent,
  handleJustifyContent,
  handleUpdateBlock,
  removeBlock,
  handleResize,
  initialSize,
  isEditable,
}: {
  documentUrl: string | null
  handleUpdateBlock: (url: string) => void
  justifyContent: 'flex-start' | 'center' | 'flex-end'
  removeBlock: () => void
  handleJustifyContent: (place: 'flex-start' | 'center' | 'flex-end') => void
  handleResize: (width: number, height: number) => void
  initialSize: {
    width: number
    height: number
  } | null
  isEditable: boolean
}) {
  const { anchorEl, handleClosePopover, handleOpenPopover, refPopoverTrigger, preferredPlacement } = usePopover({
    showOnMount: isEditable,
  })

  const handleDocument = (url: string) => {
    handleUpdateBlock(url)

    handleClosePopover()
  }

  return (
    <div>
      {documentUrl == null ? (
        <>
          <CardPreviewBlock
            ref={refPopoverTrigger}
            actions={{
              onDelete: removeBlock,
            }}
            icon={CustomBlockIcons.DocumentBlockIcon}
            showActions={isEditable}
            onClick={handleOpenPopover}
          >
            Upload or embed a file
          </CardPreviewBlock>
          <DocumentSelectorPopover
            anchorEl={anchorEl}
            handleClosePopover={handleClosePopover}
            handleDocumentUploaded={({ url }: { url: string }) => {
              handleDocument(url)
            }}
            handleEmbedLinkUploaded={(link: string) => {
              handleDocument(link)
            }}
            isEditable={isEditable}
            position={preferredPlacement}
          />
        </>
      ) : (
        <ContainerCustomBlock
          handleJustifyContent={handleJustifyContent}
          handleResize={handleResize}
          initialSize={initialSize ?? undefined}
          justifyContent={justifyContent}
          type="pdf"
        >
          <DocumentViewer url={documentUrl} />
        </ContainerCustomBlock>
      )}
    </div>
  )
}

export const insertDocumentCustomBlock = (editor: Editor) => ({
  title: 'Document',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      // @ts-ignore
      type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_DOCUMENT,
    })
  },
  aliases: ['document', 'pdf', 'file', 'doc', 'docx', 'xls', 'xlsx'],
  group: CUSTOM_BLOCKS_GROUP_MEDIA_AREA.MEDIA,
  icon: <CustomBlockIcons.DocumentBlockIcon height={25} width={25} />,
  subtext: 'Upload or embed a file for your students',
})
