import { createContext, useContext, useState } from "react";

const LocationHistoryContext = createContext(null);

export const useLocationHistory = () => {
  const context = useContext(LocationHistoryContext);
  if (!context) {
    throw new Error(
      "useLocationHistory must be used within a LocationHistoryProvider"
    );
  }
  return context;
};

export const LocationHistoryProvider = ({ children }) => {
  const [from, setFrom] = useState({ pathname: "/" });
  const [locationHistory, setLocationHistory] = useState([]);
  const [prevLocation, setPrevLocation] = useState(null);
  const [navigatingBack, setNavigatingBack] = useState(false);

  return (
    <LocationHistoryContext.Provider
      value={{
        locationHistory,
        setLocationHistory,
        from,
        setFrom,
        prevLocation,
        setPrevLocation,
        navigatingBack,
        setNavigatingBack,
      }}
    >
      {children}
    </LocationHistoryContext.Provider>
  );
};
