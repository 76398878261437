import { useState } from 'react'
import { Box, Button, Input, Stack } from '@mui/material'

export function InputEmbedLink({ onEmbedLinkUploaded, disabled, children }) {
  const [embedLink, setEmbedLink] = useState('')

  const handleEmbedLink = async () => {
    const embedLinkTrim = embedLink.trim()

    if (!embedLinkTrim || embedLinkTrim.length === 0) return

    onEmbedLinkUploaded(embedLinkTrim)
  }

  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Input
        disabled={disabled}
        placeholder="Paste a link"
        sx={{
          '& > *': {
            fontSize: '12px',
          },
        }}
        value={embedLink}
        onChange={e => {
          setEmbedLink(e.target.value)
        }}
      />

      <Stack alignItems="center" direction="row" gap="6px" justifyContent="center">
        <Button
          disabled={disabled}
          size="small"
          sx={{
            paddingInline: '30px',
            width: 'fit-content',
          }}
          variant="contained"
          onClick={handleEmbedLink}
        >
          Embed Link
        </Button>
        {children}
      </Stack>
    </Box>
  )
}
