import { type SVGProps } from 'react'

export function UploadFileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="41" viewBox="0 0 41 41" width="41" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.4492 26.901L20.1159 20.2344M20.1159 20.2344L26.7825 26.901M20.1159 20.2344V35.2344M33.4492 28.1391C35.4851 26.4578 36.7825 23.9143 36.7825 21.0677C36.7825 16.0051 32.6785 11.901 27.6159 11.901C27.2517 11.901 26.911 11.711 26.7261 11.3973C24.5526 7.7091 20.5399 5.23438 15.9492 5.23438C9.04566 5.23438 3.44922 10.8308 3.44922 17.7344C3.44922 21.1779 4.84163 24.2962 7.09413 26.5569"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.33333"
      />
    </svg>
  )
}
