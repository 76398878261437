import React from "react";
import { useSyllableBreaking } from "../../hooks/useSyllableBreaking";
import { Box, IconButton, Popover, Skeleton, Typography } from "@mui/material";
import { ReactComponent as ListenIcon } from "../../assets/icons/listen-pink.svg";
const WordAssistantPopover = ({
	word,
	lang,
	anchorEl = null,
	handleClose = () => {},
}) => {
	const { syllables, playSyllable, loading, playWord } = useSyllableBreaking(
		word,
		lang
	);
	const syllablesLength = syllables?.length;

	const handlePlayWord = (e) => {
		e.preventDefault();
		e.stopPropagation();
		playWord();
	};

	return (
		<Popover
			onClick={(e) => e.stopPropagation()}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "center",
			}}
			transformOrigin={{
				vertical: -20,
				horizontal: "center",
			}}
			sx={{
				"& .MuiPopover-paper": {
					minWidth: 200,
					minHeight: 70,
					p: 2,
					display: "flex",
					flexDirection: "column",
					gap: 1,
					justifyContent: "center",
					// alignItems: "center",
				},
			}}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			onClose={handleClose}>
			<Typography
				color={"error.main"}
				sx={{
					width: "fit-content",
					fontSize: 8,
					position: "absolute",
					top: "4px",
					right: "4px",
				}}>
				Beta
			</Typography>
			{!loading && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						gap: 0.5,
						alignItems: "center",
					}}>
					{syllables &&
						syllables?.length > 0 &&
						syllables?.map(({ syllable, ipa }, index) => {
							const handlePlay = (e) => {
								e.preventDefault();
								e.stopPropagation();
								playSyllable(ipa);
							};
							return (
								<React.Fragment key={index}>
									<Typography
										onClick={handlePlay}
										fontSize={21}
										fontWeight={500}
										sx={{
											cursor: "pointer",
											"&:hover": {
												color: "primary.main",
												transform: "scale(1.1)",
												transition: "all 0.2s ease-in-out",
											},
										}}
										key={index}>
										{syllable}
									</Typography>
									<Typography variant="h5">
										{index + 1 < syllablesLength ? "-" : ""}
									</Typography>
								</React.Fragment>
							);
						})}
					<IconButton
						size="small"
						onClick={handlePlayWord}>
						<ListenIcon />
					</IconButton>
				</Box>
			)}
			{(loading || !syllables) && (
				<Skeleton
					variant="text"
					width={170}
				/>
			)}
		</Popover>
	);
};

export default WordAssistantPopover;
