import Intercom from '@intercom/messenger-js-sdk'
import { httpsCallable } from 'firebase/functions'
import { useEffect } from 'react'

import usePermissions from './usePermissions'
import { useAuthUser, useProfileUser } from './useUser'

import { functions } from '@/firebase'
import getProfilePic from '@/utils/getProfilePic'

const retrieveIntercomCompanyId = httpsCallable(functions, 'retrieveIntercomCompanyId')
/**
 *
 * @returns  {Object}  

 */
const useChat = () => {
  const { plan } = usePermissions()
  const { email, uid } = useAuthUser()
  const { isTeacher } = useProfileUser()
  const loaded = Boolean(window._gs)
  const showChat = () => {}

  const hideChat = () => {
    // removeGoSquared();
  }

  const initChat = async (data = {}) => {
    let company_id = null

    initHubSpot()
    try {
      const { data } = await retrieveIntercomCompanyId()

      company_id = data
    } catch (error) {
      console.error(error)
    }

    const {
      customData: {
        districtID = null,
        schoolId = null,
        districtName = '',
        schoolName = '',
        userType = '',
        studentDeviceType = '',
        language = '',
        totalStudentAssignmentsSubmitted = 0,
        assignmentsActivated = 0,
        created_at = null,
      } = {},
      user_id = '',
      name = '',
      email = '',
    } = data

    const updates = {
      app_id: 'xdisq3et',
      user_id: user_id,
      name: name,
      email: email,
      created_at: created_at,
      schoolId: schoolId,
      districtID: districtID,
      districtName: districtName,
      schoolName: schoolName,
      userType: userType,
      studentDeviceType: studentDeviceType,
      language: language,
      assignments: assignmentsActivated,
      submissions: totalStudentAssignmentsSubmitted,
      avatar: {
        type: 'avatar',
        image_url: getProfilePic(user_id),
      },
      company: {
        id: company_id,
      },
    }

    Intercom(updates)
  }

  const trackEvent = (event, data = {}) => {
    if (!loaded) return
  }

  const sendMicPermissionDeniedMessage = () => {
    if (!loaded) return
  }

  useEffect(() => {
    if (plan && isTeacher) {
      Intercom({
        app_id: 'xdisq3et',
        user_id: uid,
        email: email,
        plan,
      })
    }
  }, [plan])

  return {
    showChat,
    hideChat,
    initChat,
    trackEvent,
    sendMicPermissionDeniedMessage,
  }
}

export default useChat

//convert to a js function
export const initCIO = (data = {}) => {
  const [firstName = '', lastName = ''] = data?.name?.split?.(' ')
  const identifyObject = {
    email: data?.email,
    id: data?.user_id,
    created_at: data?.created_at,
    first_name: firstName,
    last_name: data?.customData?.userType === 'TEACHER' ? lastName : '',
    ...data?.customData,
  }

  window._cio.identify(identifyObject)

  if (data?.customData?.districtID) {
    const { districtID } = data.customData || {}
    const relationship = {
      id: data?.user_id,
      email: data?.email,
      cio_relationships: {
        action: 'add_relationships',
        relationships: [
          {
            identifiers: {
              object_type_id: '1',
              object_id: districtID,
            },
          },
        ],
      },
    }

    window._cio.identify(relationship)
  }
}

export const getChatObject = (user, profile) => ({
  user_id: user?.uid,
  id: user?.uid,
  name: user?.displayName || profile?.displayName,
  email: user?.email || profile?.email,
  customData: {
    // covert firebase timestamp to seconds
    created_at: profile?.dateMade ? profile?.dateMade?.seconds : null,
    cleverId: profile?.cleverId || null,
    userType: profile?.roles?.includes('SUPER_ADMIN')
      ? 'ADMINISTRATOR'
      : profile?.roles?.includes('ADMIN')
        ? 'TEACHER'
        : 'STUDENT',
    language: profile?.language,
    adminStatus: profile?.adminStatus ? profile?.adminStatus : null,
    assignmentsActivated: profile?.totalAssignmentsActivated || 0,
    quizlet: profile?.quizletProfile || '',
    schoolName: profile?.schoolName || '',
    districtName: profile?.districtName || '',
    districtID: profile?.districtID || '',
    schoolId: profile?.schoolId || '',
    unsubscribed: profile?.roles?.includes('ADMIN')
      ? profile?.unsubscribed
      : profile?.cleverId
        ? true
        : profile?.unsubscribed,
    studentDeviceType: profile?.studentDeviceType || null,
    totalStudentAssignmentsSubmitted: profile?.totalStudentAssignmentsSubmitted || null,
    id: user?.uid,
  },
})

function initHubSpot() {
  // HubSpot Embed Code
  var script = document.createElement('script')

  script.type = 'text/javascript'
  script.id = 'hs-script-loader'
  script.async = true
  script.defer = true
  script.src = '//js.hs-scripts.com/47285496.js'

  // Append script to the document
  document.head.appendChild(script)
}
