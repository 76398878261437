import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { useMemo, useState } from 'react'

import { InputEmbedLink } from '../EmbedContent'
import { InputFileHidden } from '../shadCN/InputFileHidden'
import { PLGHandler } from '../shadCN/PLGHandler'

import { useAuthUser } from '@/hooks/useUser'
import { pathUploadDocument, uploadFile } from '@/utils/upload-file'
import { withPermissionCheck } from '@/hoc/WithPermissionCheck'
import { MEDIA_AREA_PLANS } from '@/constants/plans/variables-plans'

export function DocumentSelector({ onDocumentUploaded, onEmbedLinkUploaded }) {
  const userAuth = useAuthUser()
  const [selectedTab, setSelectedTab] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const handleUploadDocument = async e => {
    setIsLoading(true)
    const file = e.target.files[0]

    const path = pathUploadDocument(userAuth.uid, file.name)

    const { storagePath, url } = await uploadFile(file, path)

    onDocumentUploaded({ url, path: storagePath })

    setIsLoading(false)
  }

  return (
    <Box
      sx={{
        minWidth: 500,
        maxHeight: '500px',
        overflow: 'auto',
      }}>
      <Tabs
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        value={selectedTab}
        variant="fullWidth"
        onChange={(_, newValue) => {
          setSelectedTab(newValue)
        }}>
        <Tab label="Upload" sx={{ fontSize: '12px' }} />
        <Tab label="Embed link" sx={{ fontSize: '12px' }} />
      </Tabs>
      {selectedTab === 0 ? (
        <UploadButtonWithPermission
          requiredPermission={MEDIA_AREA_PLANS.MEDIA_AREA_DOCUMENT_UPLOAD}
          onChange={handleUploadDocument}
        />
      ) : (
        <InputEmbedLinkHiddenWithPermission
          requiredPermission={MEDIA_AREA_PLANS.MEDIA_AREA_DOCUMENT_UPLOAD}
          onChange={onEmbedLinkUploaded}
        />
      )}
      {isLoading ? (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  )
}

function HOCInputEmbedLink({ onChange, disabled, handleOpenPopover }) {
  return (
    <InputEmbedLink disabled={disabled} onEmbedLinkUploaded={onChange}>
      <PLGHandler visible={disabled} onMouseEnter={handleOpenPopover} />
    </InputEmbedLink>
  )
}

function HOCInputFileHidden({ onChange, disabled, handleOpenPopover }) {
  return (
    <Box sx={{ p: 2, display: 'flex', width: '100%', gap: '4px', alignItems: 'center' }}>
      <InputFileHidden disabled={disabled} onChange={onChange} />
      <PLGHandler visible={disabled} onMouseEnter={handleOpenPopover} />
    </Box>
  )
}

function InputEmbedLinkHiddenWithPermission({ requiredPermission, ...props }) {
  const Component = useMemo(() => withPermissionCheck(HOCInputEmbedLink, requiredPermission), [requiredPermission])

  return <Component {...props} />
}

function UploadButtonWithPermission({ requiredPermission, ...props }) {
  const Component = useMemo(() => withPermissionCheck(HOCInputFileHidden, requiredPermission), [requiredPermission])

  return <Component {...props} />
}
