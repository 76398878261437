import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocationHistory } from "../context/LocationHistoryProvider";

const useFromState = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const { auth } = useSelector(({ user }) => user);
	const {
		from,
		setFrom,
		locationHistory,
		setLocationHistory,
		prevLocation,
		setPrevLocation,
		navigatingBack,
		setNavigatingBack,
	} = useLocationHistory();

	useEffect(() => {
		if (
			!navigatingBack &&
			(locationHistory.length === 0 ||
				locationHistory[locationHistory.length - 1]?.pathname !==
					location.pathname)
		) {
			setLocationHistory([...locationHistory, location]);
		} else {
			setNavigatingBack(false);
		}
	}, [location, locationHistory, setLocationHistory]);

	useEffect(() => {
		if (navigatingBack && locationHistory?.length && prevLocation) {
			const prevLocationIndex = locationHistory.findIndex(
				(item) => item.pathname === prevLocation?.pathname
			);
			prevLocationIndex >= 1 &&
				setPrevLocation(locationHistory[prevLocationIndex - 1]);
		} else if (locationHistory?.length >= 2) {
			setPrevLocation(locationHistory[locationHistory.length - 2]);
		}
	}, [locationHistory, setPrevLocation]);

	const updateFrom = (newFrom) => setFrom(newFrom);

	const goBack = () => {
		setNavigatingBack(true);
		if (!prevLocation) {
			navigate(window.history.length <= 2 ? "/" : -1, { replace: true });
		} else if (prevLocation?.pathname === "/auth/login" && auth.uid) {
			navigate("/", { replace: true });
		} else if (prevLocation.pathname === location.pathname) {
			navigate("/", { replace: true });
		} else {
			navigate(prevLocation, { replace: true });
		}
	};

	return { from, locationHistory, prevLocation, updateFrom, goBack };
};

export default useFromState;
