import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA } from '../custom-blocks-types-media-area'

import { UploadFileIcon } from '@/assets/icons/upload-file-icon'
import { FilmIcon } from '@/assets/icons/film-icon'
import { ReactComponent as TypeSquareIcon } from '@/assets/icons/type-square-icon.svg'
import { ReactComponent as PodcastIcon } from '@/assets/icons/podcast-icon.svg'
import { ReactComponent as CodeIcon } from '@/assets/icons/code-icon.svg'
import { HyperlinkIcon } from '@/assets/icons/hyperlink-icon'
import { ImageIcon } from '@/assets/icons/image-icon'
import { TextSpeechIcon } from '@/assets/icons/text-speech'
import { AudioIcon } from '@/assets/icons/audio'

export const CustomBlockIcons = {
  DocumentBlockIcon: UploadFileIcon,
  ImageBlockIcon: ImageIcon,
  VideoBlockIcon: FilmIcon,
  TextBlockIcon: TypeSquareIcon,
  PodcastBlockIcon: PodcastIcon,
  WebBlockIcon: CodeIcon,
  AnyLinkBlockIcon: HyperlinkIcon,
  AudioBlockIcon: AudioIcon,
}

export const CUSTOM_BLOCK_ICON_OPTIONS = [
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT,
    label: 'Text',
    icon: TypeSquareIcon,
  },
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_IMAGE,
    label: 'Image',
    icon: ImageIcon,
  },
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT_TO_SPEECH,
    label: 'Text to Speech',
    icon: TextSpeechIcon,
  },
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_AUDIO,
    label: 'Audio',
    icon: AudioIcon,
  },
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_VIDEO,
    label: 'Video',
    icon: FilmIcon,
  },
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_DOCUMENT,
    label: 'Document',
    icon: UploadFileIcon,
  },
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_PODCAST,
    label: 'Podcast',
    icon: PodcastIcon,
  },
  // {
  //   type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_WEB,
  //   label: 'Web',
  //   icon: CodeIcon,
  // },
  // {
  //   type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_WEB,
  //   label: 'Any link',
  //   icon: HyperlinkIcon,
  // },
]
