/* eslint-disable react/jsx-sort-props */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Resizable } from 're-resizable'
import { useMemo } from 'react'

import {
  getEnabledResizable,
  getHandleComponentResizable,
  getInitialSizeResizableContent,
  getAspectRatioResizableContent,
} from './resizable-content.constants'

import useUtils from '@/hooks/useUtilities'

interface ResizableContentProps {
  children: React.ReactNode
  type: 'image' | 'audio' | 'video' | 'document' | 'pdf' | 'web' | 'podcast'
  isHovered?: boolean
  handleResizeStop?: (width: number, height: number) => void
  handleMouseEnter?: () => void
  handleMouseLeave?: () => void
  initialSize?: { width: number | string; height: number }
  enabled?: boolean
  maxDimensions?: { maxWidth: number; maxHeight: number }
  isMaxWidth?: boolean
}

export function ResizableContent({
  children,
  type = 'image',
  isHovered,
  handleResizeStop,
  handleMouseEnter,
  handleMouseLeave,
  initialSize,
  enabled,
  maxDimensions,
  isMaxWidth = false,
}: ResizableContentProps) {
  const { isMobile } = useUtils()
  const disablePadding = isMobile || !enabled

  const { width } = initialSize ?? getInitialSizeResizableContent(type, maxDimensions?.maxWidth ?? 0)
  const enabledResizable = getEnabledResizable(type, enabled ?? true)
  const handleComponent = getHandleComponentResizable(type, isHovered ?? false)
  const aspectRatio = getAspectRatioResizableContent(type)

  const almostFullWidth = (maxDimensions?.maxWidth ?? 0) / 1.2

  const extraSizeInMobile = disablePadding ? 160 : 0

  const widthMapped = useMemo(() => {
    let w: number | string = width

    if (width === 0) w = 'auto'
    if (width === '100%') w = almostFullWidth
    if (isMaxWidth) w = '100%'

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    if (disablePadding && typeof width !== 'string') w = (w as number) + extraSizeInMobile

    return w
  }, [width, almostFullWidth, isMaxWidth, disablePadding, extraSizeInMobile])

  let height = 0

  if (type === 'pdf' || type === 'web') {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    height = initialSize?.height || 360
  } else if (type === 'video') {
    height = widthMapped / (aspectRatio as number)
  }

  const dimensions = {
    width: widthMapped,
    height,
  }

  const maxDimensionsWithExtra = maxDimensions ? maxDimensions.maxWidth + (disablePadding ? 160 : 0) : undefined

  return (
    <div
      style={{
        display: 'flex',
        position: 'relative',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Resizable
        size={{
          height: type === 'image' || type === 'podcast' ? 'auto' : height,
          width: dimensions.width === 0 ? 'auto' : dimensions.width,
        }}
        lockAspectRatio={aspectRatio}
        maxWidth={maxDimensionsWithExtra}
        minHeight={50}
        minWidth={50}
        onResizeStop={(e, direction, ref, d) => {
          let currentWidth = 0

          // @ts-ignore
          if (dimensions.width === '100%' || isMaxWidth) {
            currentWidth = maxDimensions?.maxWidth ?? 0
          } else if (typeof dimensions.width === 'string') {
          } else {
            // @ts-ignore
            currentWidth = dimensions.width
          }

          const newDimensions = {
            width: currentWidth + d.width,
            height: dimensions.height + d.height,
          }

          handleResizeStop?.(newDimensions.width, newDimensions.height)
        }}
        enable={enabledResizable}
        // @ts-ignore
        handleComponent={handleComponent}
      >
        {children}
      </Resizable>
    </div>
  )
}
