import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, IconButton } from '@mui/material'
import { Refresh } from '@mui/icons-material'
import { Howl } from 'howler'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { v4 } from 'uuid'

import { ReactComponent as ListenIcon } from '../../assets/icons/listen-mic-white.svg'
import { ReactComponent as StopIcon } from '../../assets/icons/stop-mic-white.svg'
import { ReactComponent as MicIcon } from '../../assets/icons/mic-white.svg'
import { storage } from '../../firebase'
import useRecorder from '../../utils/useRecorder'

import { useAuthUser } from '@/hooks/useUser'

function RecordButtonSimple({
  onSaveRecording = () => {},
  audioUrl = null,
  onCancelRecording = () => {},
  maxDuration = 15,
}) {
  const uid = useAuthUser().uid
  const { startRecording, recorderState, cancelRecording, saveRecording } = useRecorder(maxDuration)

  const { audio, recording } = recorderState

  const [uploading, setUploading] = useState(false)
  const [listening, setListening] = useState(false)
  const [audioInstance, setAudioInstance] = useState(null)

  const handleClick = () => {
    if (audioUrl) {
      listening ? handleStopListening() : handleListen()

      return
    }

    startRecording()
  }

  const handleStop = () => {
    if (recording) {
      saveRecording()
    }
  }

  const handleTryAgain = () => {
    cancelRecording()
    onCancelRecording()
    setAudioInstance(null)
  }

  const handleListen = () => {
    const src = audioUrl
    const howl = new Howl({
      src: [src],
      html5: true,
      format: ['mp3'],
      onplay: () => {
        setListening(true)
      },
      onend: () => {
        setListening(false)
      },
      onstop: () => {
        setListening(false)
      },
    })

    setAudioInstance(howl)
    howl.play()
  }

  const handleStopListening = () => {
    audioInstance.stop()
    setListening(false)
  }

  const uploadAudio = async () => {
    setUploading(true)

    try {
      const id = v4()
      const fileName = `user_data/${uid}/${id}.mp3`
      const audioStorageRef = ref(storage, fileName)
      const uploadTask = uploadBytesResumable(audioStorageRef, audio)

      uploadTask.on(
        'state_changed',
        snapshot => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)

          console.log('Upload is ' + progress + '% done')
        },
        error => {
          console.log('addAudioToFlashcard', error)
        },
        async () => {
          return await getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            const url = downloadURL
            const path = audioStorageRef.fullPath
            const audioObject = { url, path, fileName }

            setUploading(false)
            onSaveRecording(audioObject)
          })
        },
      )
    } catch (err) {
      console.log(err)
      alert('Error uploading audio')
      setUploading(false)

      return false
    }
  }

  useEffect(() => {
    if (audio && !audioUrl) {
      uploadAudio(audio)
    } else {
      setAudioInstance(null)
      cancelRecording()
    }
  }, [audio])

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          bgcolor: recording ? '#E91E63' : 'secondary.main',
          width: '24px',
          height: '24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          '&:hover': {
            bgcolor: recording ? '#E91E63' : 'secondary.dark',
          },
          // pulse opacity
          opacity: recording ? 0.5 : 1,
          transition: 'opacity 0.25s ease-in-out',
          animation: recording ? 'pulse 2s infinite' : 'none',
          '@keyframes pulse': {
            '0%': {
              opacity: 1,
            },
            '50%': {
              opacity: 0.5,
            },
            '100%': {
              opacity: 1,
            },
          },
        }}
        onClick={recording ? handleStop : handleClick}
      >
        {recording ? (
          <StopIcon />
        ) : (
          <>
            {audioUrl || uploading ? (
              uploading ? (
                <CircularProgress
                  size={20}
                  sx={{
                    color: 'white',
                  }}
                />
              ) : (
                <ListenIcon />
              )
            ) : (
              <MicIcon />
            )}
          </>
        )}
      </Box>
      {audioUrl ? (
        <IconButton
          color="secondary"
          size="small"
          sx={{
            fontSize: '12px',
            whiteSpace: 'nowrap',
          }}
          onClick={handleTryAgain}
        >
          <Refresh fontSize="small" />
        </IconButton>
      ) : null}
    </Box>
  )
}

export default RecordButtonSimple
