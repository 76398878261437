const apiKey = import.meta.env.VITE_GOOGLE_IMAGE_SEARCH_API_KEY;
const searchEngineId = import.meta.env.VITE_GOOGLE_SEARCH_ENGINE_ID;

export const getGoogleImageSearchResults = async (query, page = 1) => {
	const resultsPerPage = 10;

	const startIndex = (page - 1) * resultsPerPage + 1;

	const url = `https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(
		query
	)}&cx=${searchEngineId}&searchType=image&key=${apiKey}&start=${startIndex}`;

	try {
		const response = await fetch(url);
		const data = await response.json();
		const imageUrls = data?.items?.map((item) => ({ url: item.link }));
		return imageUrls || [];
	} catch (error) {
		console.error("Error fetching image search results:", error);
	}
};

export const getSingleGoogleImage = async (query) => {
	const url = `https://www.googleapis.com/customsearch/v1?q=${encodeURIComponent(
		query
	)}&cx=${searchEngineId}&searchType=image&key=${apiKey}&num=1`;

	try {
		const response = await fetch(url);
		const data = await response.json();
		const firstImageUrl =
			data?.items?.length > 0 ? { url: data.items[0].link } : null;
		return firstImageUrl;
	} catch (error) {
		console.error("Error fetching image search results:", error);
	}
};
