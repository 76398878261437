import { planTypes } from './type-plans'
import {
  AI_ASSISTANT_PLANS,
  ASSIGNMENT_SETTINGS_PLANS,
  AUTO_GRADING_PLANS,
  COMMENTS_PLANS,
  DISCOVER_PLANS,
  FEEDBACK_PLANS,
  MEDIA_AREA_PLANS,
  SPACES_PLANS,
} from './variables-plans'

export interface PLGInterface {
  title: string
  description: string
  img: string
  planType: (keyof typeof planTypes)[]
  contentType: 'popover' | 'modal' | 'inline'
  link?: string
  CTA?: {
    primary: string
    secondary: string
  }
}

export const PLG_INTERFACES: Record<string, PLGInterface> = {
  [FEEDBACK_PLANS.FEEDBACK_GRAMMAR_INSIGHTS]: {
    title: 'Grammar Insights',
    description: 'Identify grammar errors & successes in student responses and provide detailed explanations.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
    link: 'https://www.speakable.io/features/grammar-insights',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
  [FEEDBACK_PLANS.FEEDBACK_SUGGESTED_RESPONSE]: {
    title: 'Suggested Response',
    description: 'Offer a suggested response with improved grammar for the student.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
  [FEEDBACK_PLANS.FEEDBACK_GRADING_STANDARDS]: {
    title: 'Proficiency Level Estimation',
    description:
      'Estimate a student’s WIDA or ACTFL level based on their response and provide an explanation for the chosen level.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
    link: 'https://www.speakable.io/features/proficiency-level-estimation',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
  [FEEDBACK_PLANS.FEEDBACK_TRANSCRIPT]: {
    title: 'Transcript',
    description: "Provide a transcript of the student's response.",
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  [AI_ASSISTANT_PLANS.AI_ASSISTANT_DOCUMENT_UPLOADS]: {
    title: 'Upload Documents',
    description: 'Build activities based on documents and images you upload.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  [AUTO_GRADING_PLANS.AUTO_GRADING_PASS_FAIL]: {
    title: 'Simplify Free Response Grading',
    description:
      'Automatically grade written and spoken free responses based on a customizable grading prompt. Save time without losing grading control.',
    img: '',
    planType: [planTypes.teacher_pro, planTypes.organization],
    contentType: 'inline',
    link: 'https://www.speakable.io/features/pass-fail',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
  [AUTO_GRADING_PLANS.AUTO_GRADING_RUBRICS]: {
    title: 'Detailed Free Response Evaluation',
    description:
      'Automatically score written and spoken responses with custom rubrics. Adjust grades as needed and get clear justifications for every score.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'inline',
    link: 'https://www.speakable.io/features/rubrics',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
  [ASSIGNMENT_SETTINGS_PLANS.ASSESSMENTS]: {
    title: 'Assessments',
    description:
      'Create summative assessments to evaluate student performance. Review results and AI insights before returning grades to your students.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  [SPACES_PLANS.SPACES_CREATE_SPACE]: {
    title: 'Collaborate with Spaces',
    description:
      'Team up with your colleagues to create and refine a shared activity library. Exchange updates and comments easily.',
    img: '',
    planType: [planTypes.teacher_pro, planTypes.organization],
    contentType: 'popover',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
  [COMMENTS_PLANS.COMMENTS_SUGGESTIONS]: {
    title: 'Comment Suggestions',
    description: 'Get suggestions for comments from your students.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  [DISCOVER_PLANS.DISCOVER_ORGANIZATION_LIBRARY]: {
    title: 'Organization Library',
    description: 'Access to the organizations shared library.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  [MEDIA_AREA_PLANS.MEDIA_AREA_AUDIO_FILES]: {
    title: 'Upload Audio Files',
    description: 'Upload audio files for your students to listen to.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  [MEDIA_AREA_PLANS.MEDIA_AREA_DOCUMENT_UPLOAD]: {
    title: 'Upload Documents',
    description: 'Upload documents for your students to read.',
    img: '',
    planType: [planTypes.organization],
    contentType: 'popover',
  },
  AUTO_GRADING_TOGGLE: {
    title: 'Activate Auto-Grading',
    description:
      'Automate grading to save time, allowing you to spend more time with your students and less on paperwork.',
    img: '',
    planType: [planTypes.teacher_pro, planTypes.organization],
    contentType: 'popover',
    CTA: {
      primary: 'View Plans',
      secondary: 'Learn more',
    },
  },
} as const
