import { type SVGProps } from 'react'

export function ImageIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.4508 15.75H5.49931C5.04495 15.75 4.81777 15.75 4.71257 15.6601C4.62129 15.5822 4.57285 15.4652 4.58227 15.3456C4.59312 15.2077 4.75376 15.047 5.07505 14.7257L11.4523 8.34853C11.7493 8.05152 11.8978 7.90301 12.069 7.84737C12.2196 7.79842 12.3819 7.79842 12.5325 7.84737C12.7038 7.90301 12.8523 8.05151 13.1493 8.34853L16.0508 11.25V12.15M12.4508 15.75C13.7109 15.75 14.341 15.75 14.8223 15.5048C15.2456 15.289 15.5898 14.9448 15.8055 14.5215C16.0508 14.0402 16.0508 13.4101 16.0508 12.15M12.4508 15.75H6.15078C4.89066 15.75 4.2606 15.75 3.7793 15.5048C3.35594 15.289 3.01173 14.9448 2.79602 14.5215C2.55078 14.0402 2.55078 13.4101 2.55078 12.15V5.85C2.55078 4.58988 2.55078 3.95982 2.79602 3.47852C3.01173 3.05516 3.35594 2.71095 3.7793 2.49524C4.2606 2.25 4.89066 2.25 6.15078 2.25H12.4508C13.7109 2.25 14.341 2.25 14.8223 2.49524C15.2456 2.71095 15.5898 3.05516 15.8055 3.47852C16.0508 3.95982 16.0508 4.58988 16.0508 5.85V12.15M8.17578 6.375C8.17578 7.20343 7.50421 7.875 6.67578 7.875C5.84735 7.875 5.17578 7.20343 5.17578 6.375C5.17578 5.54657 5.84735 4.875 6.67578 4.875C7.50421 4.875 8.17578 5.54657 8.17578 6.375Z"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  )
}
