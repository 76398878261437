import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import useUtils from '../../hooks/useUtilities'
import { loadProducts, createStripeSession, createStripePortalLink } from '../../utils/stripe-tools'

import { planTypes } from '@/constants/plans/type-plans'
import { PricingPageComponent } from '@/components/shadCN/pricing-page'
import ContactSalesDialog from '@/components/ContactSalesDialog'

const basicPlan = {
  name: 'Basic',
  role: 'basic',
  price: 'Always Free',
  description: 'When you’re getting started and just want the basics.',
  featTitle: "What's Included:",
  features: [
    'Create activities with text, video, and repeat cards',
    'Assign unlimited activities that give students real-time speaking feedback.',
  ],
}

function SubscriptionPlans({ isModal = false, highlightedPlan }) {
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [openCalendly, setOpenCalendly] = useState(false)
  const { isMobile } = useUtils()

  const { plan, isStripePlan, subscriptionId, type } = useSelector(({ permissions }) => permissions)

  useEffect(() => {
    // search params
    const urlParams = new URLSearchParams(window.location.search)
    const priceId = urlParams.get('priceId')

    if (priceId) {
      setLoading(true)
      handleStartPlan({ priceId, id: planTypes.teacher_pro })
    }

    if (!products?.length) {
      const getProducts = async () => {
        setLoadingProducts(true)
        const result = await loadProducts()

        result.unshift(basicPlan)
        setProducts(result)
        setLoadingProducts(false)
      }

      getProducts()
    }
  }, [])
  const handleStartPlan = async selectedPlan => {
    try {
      let priceId = selectedPlan?.priceId

      if (selectedPlan.id === planTypes.organization) {
        setOpenCalendly(true)
      } else if (selectedPlan.id === planTypes.teacher_pro) {
        setLoading(true)
        await createStripeSession(priceId)

        return
      } else if (selectedPlan === 'downgrade') {
        setLoading(true)
        await createStripePortalLink()
      }
    } catch (e) {
      console.log('handleStartPlan', e)
      setLoading(false)
    }
  }

  return (
    <>
      <ContactSalesDialog open={openCalendly} onClose={() => setOpenCalendly(false)} />
      <PricingPageComponent
        currentPlan={plan}
        handleStartPlan={handleStartPlan}
        highlightedPlan={highlightedPlan}
        isModal={isModal}
        loading={loading}
        products={products}
      />
    </>
  )
}

export default SubscriptionPlans
