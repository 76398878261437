const downloadImageFromURL = async (url) => {
  try {
    const imageData = await fetch(url, {
      referrerPolicy: "no-referrer",
    });
    let imageBlob = await imageData.blob();
    let reader = new FileReader();
    reader.readAsDataURL(imageBlob);
    return imageBlob;
  } catch (error) {
    return null;
  }
};

export default downloadImageFromURL;
