import { createContext, type CSSProperties, useContext } from 'react'
import { BlockNoteView } from '@blocknote/mantine'
import '@blocknote/mantine/style.css'

import { CustomFormattingToolbar } from './CustomFormattingToolbar'
import { CustomSuggestionMenuController } from './CustomSuggestionMenuController'
import { type BlocksMediaArea } from './media-area-schema'

import { useMediaAreaDimensions, useMediaArea, useMediaAreaEditor } from '@/hooks/media-area/useMediaArea'

import './MediaArea.css'

export const MediaAreaContext = createContext<{
  mediaAreaId: string
  onChange: (blocks: BlocksMediaArea) => void
  isEditable?: boolean
  maxDimensions?: {
    maxWidth: number
    maxHeight: number
  }
  isMobileApp?: boolean
} | null>(null)

export function MediaArea({
  mediaAreaId,
  onChange,
  isEditable = true,
  scale = 1,
  stylesContainer,
  isMobileApp = false,
}: {
  mediaAreaId: string
  onChange: (blocks: BlocksMediaArea) => void
  isEditable?: boolean
  scale?: number
  stylesContainer?: CSSProperties
  isMobileApp?: boolean
}) {
  const { mediaAreaQuery } = useMediaArea({ mediaAreaId: mediaAreaId })

  const editor = useMediaAreaEditor({
    blocksData: mediaAreaQuery.data?.blocks,
    dependencies: [mediaAreaId, mediaAreaQuery.isSuccess],
  })

  const { maxDimensions, widthAndHeight, refMediaArea } = useMediaAreaDimensions({ scale, isEditable })

  if (editor === undefined) {
    return 'Loading content...'
  }

  return (
    <MediaAreaContext.Provider value={{ mediaAreaId, onChange, isEditable, maxDimensions, isMobileApp }}>
      <div
        ref={refMediaArea}
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
          ...stylesContainer,
        }}
      >
        <BlockNoteView
          className={!isEditable ? 'assignment-media-area' : ''}
          data-color-scheme="light"
          editable={isEditable}
          editor={editor}
          formattingToolbar={false}
          slashMenu={false}
          style={{
            width: widthAndHeight,
            height: widthAndHeight,
            transform: `scale(${scale.toString()})`,
            transformOrigin: 'top left',
            position: 'absolute',
            overflow: 'auto',
          }}
          theme={{
            fontFamily: 'Poppins, sans-serif',
          }}
          onChange={() => {
            const document = editor.document

            onChange(document)
          }}
        >
          <CustomSuggestionMenuController editor={editor} />
          <CustomFormattingToolbar editor={editor} />
        </BlockNoteView>
      </div>
    </MediaAreaContext.Provider>
  )
}

export function useMediaAreaContext() {
  const context = useContext(MediaAreaContext)

  if (context === null) {
    throw new Error('useMediaArea must be used within a MediaAreaProvider')
  }

  return context
}
