import { Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'

import { EmailInput, FieldWithLabel, OptionSelect } from './components'

import useUtils from '@/hooks/useUtilities'

const portalId = '47285496'
const formId = 'b2172a1f-a33c-451b-b357-0e298e802d6f'

function ContactSalesForm({ handleClose }) {
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { showSnackbar, isTablet } = useUtils()

  const [formState, setFormState] = useState({
    which_are_you_interested_in_purchasing_: '',
    please_select_your_role: '',
    firstname: '',
    lastname: '',
    email: '',
    name: '',
  })

  const handleInputChange = (key, value) => {
    setFormState({
      ...formState,
      [key]: value,
    })
  }

  useEffect(() => {
    const isFormComplete = requiredFields.every(field => formState[field] !== '') && isEmailValid

    setIsFormValid(isFormComplete)
  }, [formState, isEmailValid])

  const handleSubmit = async e => {
    e.preventDefault()

    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`

    const formDataStructure = {
      submittedAt: Date.now().toString(),
      fields: [
        {
          objectTypeId: '0-1',
          name: 'which_are_you_interested_in_purchasing_',
          value: formState.which_are_you_interested_in_purchasing_,
        },
        {
          objectTypeId: '0-1',
          name: 'please_select_your_role',
          value: formState.please_select_your_role,
        },
        {
          objectTypeId: '0-1',
          name: 'firstname',
          value: formState.firstname,
        },
        {
          objectTypeId: '0-1',
          name: 'lastname',
          value: formState.lastname,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: formState.email,
        },
        {
          objectTypeId: '0-2',
          name: 'name',
          value: formState.name,
        },
      ],
      context: {
        pageUri: window.location.href,
        pageName: document.title,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to allow Speakable to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: 'I agree to receive marketing communications from Speakable.',
            },
          ],
        },
      },
    }

    try {
      setIsSubmitting(true)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataStructure),
      })

      if (response.ok) {
        setIsSubmitting(false)
        const responseData = await response.json()

        console.log('Form submitted successfully', responseData)
        showSnackbar('Form submitted successfully', 'success')
        handleClose()
      } else {
        const errorData = await response.text()

        console.error('Error:', response.status, response.statusText, errorData)
        showSnackbar(`There was an error submitting the form. Status: ${response.status}`, 'error')
      }
    } catch (error) {
      setIsSubmitting(false)
      showSnackbar('There was an error submitting the form.', 'error')
    }
  }

  return (
    <Stack
      alignItems="center"
      flexDirection="column"
      gap="24px"
      justifyContent="center"
      sx={{
        height: '100%',
        // maxWidth: isTablet ? '100%' : 463,
        maxWidth: '100%',
        borderRadius: '16px',
        background: '#FFF',
        p: isTablet ? '20px' : '20px 0px',
      }}>
      <Stack
        alignItems="flex-start"
        flexDirection="column"
        gap="20px"
        sx={{
          width: 394,
        }}>
        <Typography
          sx={{
            fontSize: 20,
            fontWeight: 600,
            lineeight: '30px',
          }}>
          Enter your details below
        </Typography>
        <FieldWithLabel required label="What are you interested in purchasing">
          <OptionSelect
            fullWidth
            inputStyles={{ '& .MuiInputBase-root': { height: 44 } }}
            options={purchaseOptions}
            placeholder="What are you interested in purchasing"
            setValue={value => handleInputChange('which_are_you_interested_in_purchasing_', value)}
            value={formState?.which_are_you_interested_in_purchasing_}
          />
        </FieldWithLabel>
        <FieldWithLabel required label="Please select your role">
          <OptionSelect
            fullWidth
            inputStyles={{ '& .MuiInputBase-root': { height: 44 } }}
            options={roleOptions}
            placeholder="Please select your role"
            setValue={value => handleInputChange('please_select_your_role', value)}
            value={formState?.please_select_your_role}
          />
        </FieldWithLabel>
        <Stack direction="row" gap="8px" width="100%">
          <FieldWithLabel label="First Name">
            <TextField
              fullWidth
              placeholder="Enter your first name"
              sx={{
                '& .MuiInputBase-root': { height: 44 },
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '16px !important' },
              }}
              value={formState.firstname}
              onChange={e => handleInputChange('firstname', e.target.value)}
            />
          </FieldWithLabel>
          <FieldWithLabel label="Last Name">
            <TextField
              fullWidth
              placeholder="Enter your last name"
              sx={{
                '& .MuiInputBase-root': { height: 44 },
                '& .MuiOutlinedInput-notchedOutline': { borderRadius: '16px !important' },
              }}
              value={formState.lastname}
              onChange={e => handleInputChange('lastname', e.target.value)}
            />
          </FieldWithLabel>
        </Stack>
        <FieldWithLabel required label="Enter your work email">
          <EmailInput
            email={formState.email}
            handleInputChange={value => handleInputChange('email', value)}
            onEmailValid={value => setIsEmailValid(value)}
          />
        </FieldWithLabel>
        <FieldWithLabel required label="Name of your School / Organization">
          <TextField
            fullWidth
            placeholder="Enter your school name"
            sx={{
              '& .MuiInputBase-root': { height: 44 },
              '& .MuiOutlinedInput-notchedOutline': { borderRadius: '16px !important' },
            }}
            value={formState.name}
            onChange={e => handleInputChange('name', e.target.value)}
          />
        </FieldWithLabel>
        <Button
          fullWidth
          disabled={!isFormValid || isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          variant="contained"
          onClick={handleSubmit}>
          Submit
        </Button>
      </Stack>
    </Stack>
  )
}

export default ContactSalesForm

const purchaseOptions = [
  { label: 'Individual School Plan', value: 'Individual School Plan' },
  { label: 'District/Multi-School Plan', value: 'District/Multi-School Plan' },
  { label: 'Individual Teacher Plan', value: 'Individual Teacher Plan' },
]

const roleOptions = [
  { label: 'Student', value: 'Student' },
  { label: 'Teacher/Faculty', value: 'Teacher/Faculty' },
  { label: 'School Administrator', value: 'School Administrator' },
  { label: 'Department Lead', value: 'Department Lead' },
  { label: 'Technical Director/IT Director', value: 'Technical Director/IT Director' },
]

const requiredFields = ['which_are_you_interested_in_purchasing_', 'please_select_your_role', 'email', 'name']
