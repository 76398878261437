import { Box, Button, Stack, Typography } from '@mui/material'

import { PLGChip } from './PLGChip'

import { ChattySuccessIcon } from '@/assets/chatty-icons'
import { ReactComponent as ArrowCircleUp } from '@/assets/icons/arrow-circle-up.svg'
import { PLG_INTERFACES } from '@/constants/plans/plg.constants'
import { COMMENTS_PLANS } from '@/constants/plans/variables-plans'

const plg = {
  freePlan: {
    title: 'Comment Suggestions!',
    chips: PLG_INTERFACES[COMMENTS_PLANS.COMMENTS_SUGGESTIONS].planType,
    subtitle: "Hi there! I'm Chatty, your friendly assistant! ",
    description:
      'Did you know you can unlock personalized comment suggestions for your students? Upgrade now to get tailored feedback and make your teaching even more impactful!',
  },
  proPlan: {
    title: 'Comment Suggestions!',
    chips: PLG_INTERFACES[COMMENTS_PLANS.COMMENTS_SUGGESTIONS].planType,
    subtitle: "You're rocking the Pro Plan!",
    description:
      'To access special features like Comment Suggestions, let your school admin know they can request a quote for an Organization Plan. It&apos;s free for you, and it benefits the whole school!',
  },
}

export function PLGCommentSuggestions({ isFreePlan }: { isFreePlan: boolean }) {
  const plgData = isFreePlan ? plg.freePlan : plg.proPlan

  return (
    <Stack alignItems="center" direction="row" gap="24px" justifyContent="center" width="100%">
      <Box position="relative">
        <Box
          sx={{
            display: 'flex',
            width: 31,
            height: 31,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100px',
            background: 'linear-gradient(45deg, #FF6C6C 0%, #DD7BFF 100%)',
            overflow: 'hidden',
            flexShrink: 0,
          }}
        >
          <img
            alt={ChattySuccessIcon.alt}
            height="90%"
            src={ChattySuccessIcon.src}
            style={{ position: 'relative', objectFit: 'cover', objectPosition: 'top', top: '2px' }}
            width="90%"
          />
        </Box>
        <ArrowCircleUp style={{ position: 'absolute', top: '-6px', right: '-4px', width: 14 }} />
      </Box>
      <Stack bgcolor="#FDF2FA" borderRadius="12px" gap="8px" paddingBlock="9px" paddingInline="12px" width="100%">
        <Stack alignItems="center" direction="row" gap="4px" width="100%">
          <ArrowCircleUp width={18} />
          <Typography
            sx={{
              color: '#000',
              fontSize: 16,
              fontWeight: 600,
            }}
          >
            {plgData.title}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap="4px"
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {plgData.chips.map(chip => (
            <PLGChip key={chip} type={chip} />
          ))}
        </Stack>
        <Typography
          sx={{
            color: '#101828',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {plgData.subtitle}
        </Typography>
        <Typography
          sx={{
            color: '#101828',
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {plgData.description}
        </Typography>
        <Stack direction="row" gap="12px" width="100%">
          <Button
            sx={{
              color: '#C11574',
              ':hover': {
                backgroundColor: '#C1157410',
              },
            }}
          >
            Request Quote
          </Button>
          <Button>Learn more</Button>
        </Stack>
      </Stack>
    </Stack>
  )
}
