export function VolumeMaxIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="21" viewBox="0 0 20 21" width="20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.4579 4.20502C17.639 5.84688 18.3346 7.86138 18.3346 10.0384C18.3346 12.2153 17.639 14.2298 16.4579 15.8717M13.1224 6.70502C13.7815 7.64988 14.168 8.79898 14.168 10.0384C14.168 11.2777 13.7815 12.4268 13.1224 13.3717M8.0299 4.50975L5.39183 7.14783C5.2477 7.29195 5.17564 7.36402 5.09154 7.41555C5.01698 7.46124 4.93569 7.49491 4.85066 7.51533C4.75476 7.53835 4.65284 7.53835 4.44902 7.53835H3.0013C2.53459 7.53835 2.30124 7.53835 2.12298 7.62918C1.96617 7.70907 1.83869 7.83656 1.7588 7.99336C1.66797 8.17162 1.66797 8.40497 1.66797 8.87168V11.205C1.66797 11.6717 1.66797 11.9051 1.7588 12.0833C1.83869 12.2401 1.96617 12.3676 2.12298 12.4475C2.30124 12.5384 2.53459 12.5384 3.0013 12.5384H4.44902C4.65284 12.5384 4.75476 12.5384 4.85066 12.5614C4.93569 12.5818 5.01698 12.6155 5.09154 12.6612C5.17564 12.7127 5.2477 12.7847 5.39183 12.9289L8.0299 15.5669C8.38688 15.9239 8.56537 16.1024 8.71861 16.1145C8.85158 16.1249 8.98152 16.0711 9.06814 15.9697C9.16797 15.8528 9.16797 15.6004 9.16797 15.0955V4.98116C9.16797 4.47631 9.16797 4.22389 9.06814 4.107C8.98152 4.00558 8.85158 3.95176 8.71861 3.96222C8.56537 3.97428 8.38688 4.15277 8.0299 4.50975Z"
        stroke={props.color ?? 'white'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.66667"
      />
    </svg>
  )
}
