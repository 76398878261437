import { createTheme } from '@mui/material/styles'

import { palette } from './palette'
// const mui_theme = createTheme({
// 	palette: palette,
// 	typography: {
// 		fontFamily: "Poppins, sans-serif, Pacifico",
// 		fontWeightLight: 300,
// 		fontWeightRegular: 400,
// 		fontWeightMedium: 500,
// 		fontWeightBold: 700,
// 		body1: {
// 			fontSize: "14px",
// 			// lineHeight: "30px",
// 			fontFamily: "Poppins",
// 		},
// 		body2: {
// 			fontSize: "12px",
// 			lineHeight: "26px",
// 			fontFamily: "Poppins",
// 		},
// 		button: {
// 			fontSize: 14,
// 			fontFamily: "Poppins, sans-serif",
// 			textTransform: "capitalize",
// 		},
// 		caption: {
// 			fontFamily: "Poppins",
// 			fontSize: "12px",
// 			color: "#5C7CA0",
// 		},
// 		h1: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontWeight: 500,
// 			fontSize: "25px",
// 			// lineHeight: "38.5px",
// 		},
// 		h2: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontWeight: 500,
// 			fontSize: "23px",
// 			// lineHeight: "35px",
// 		},
// 		h3: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontWeight: 500,
// 			fontSize: "21px",
// 			// lineHeight: "33px",
// 		},
// 		h4: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontWeight: 500,
// 			fontSize: "19px",
// 			// lineHeight: "31px",
// 		},
// 		h5: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontWeight: 500,
// 			fontSize: "17px",
// 			// lineHeight: "29px",
// 		},
// 		h6: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontWeight: 500,
// 			fontSize: "15px",
// 			// lineHeight: "27px",
// 		},
// 		subtitle1: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontSize: "16px",
// 			// lineHeight: "28px",
// 			fontWeight: 300,
// 		},
// 		subtitle2: {
// 			fontFamily: "Poppins, sans-serif",
// 			fontSize: "14px",
// 			// lineHeight: "26px",
// 			fontWeight: 500,
// 		},
// 	},
// 	shape: {
// 		// borderRadius: 25,
// 	},
// 	components: {
// 		// tooltip
// 		MuiTooltip: {
// 			styleOverrides: {
// 				tooltip: {
// 					backgroundColor: palette.secondary.main,
// 					color: "#FFFFFF",
// 					fontSize: "12px",
// 					borderRadius: 8,
// 				},
// 				arrow: {
// 					color: palette.secondary.main,
// 				},
// 			},
// 		},

// 		MuiButton: {
// 			styleOverrides: {
// 				root: {
// 					borderRadius: 25,
// 					textTransform: "capitalize",
// 				},
// 			},
// 		},
// 		MuiListItem: {
// 			styleOverrides: {
// 				root: {
// 					"&.Mui-selected": {
// 						backgroundColor: "#F96EA9",
// 						color: "#FFFFFF",
// 						"&:hover": {
// 							backgroundColor: "#F96EA9",
// 							color: "#FFFFFF",
// 						},
// 					},
// 				},
// 			},
// 		},
// 		MuiCard: {
// 			styleOverrides: {
// 				root: {
// 					boxShadow: "0px 4px 10px rgba(174, 198, 221, 0.4)",
// 					borderRadius: "8px",
// 				},
// 			},
// 		},
// 		MuiPaper: {
// 			styleOverrides: {
// 				root: {
// 					boxShadow: "0px 4px 10px rgba(174, 198, 221, 0.4)",
// 				},
// 			},
// 		},
// 		// MuiRadio: {
// 		// 	styleOverrides: {
// 		// 		root: {
// 		// 			"&.Mui-checked": {
// 		// 				color: "#0b77ea",
// 		// 			},
// 		// 		},
// 		// 	},
// 		// },

// 		MuiOutlinedInput: {
// 			styleOverrides: {
// 				root: {
// 					borderRadius: "10px !important",
// 					boxShadow: "0px 10px 21px rgba(174, 198, 221, 0.2)",
// 					backgroundColor: "white",
// 					"&:hover": {
// 						borderColor: "#F96EA9",
// 					},
// 					"&.Mui-focused": {
// 						borderColor: "#F96EA9",
// 					},
// 				},
// 				notchedOutline: {
// 					border: "1px solid #DEEEFF !important",
// 				},
// 			},
// 		},
// 		// style mui select
// 		MuiSelect: {
// 			styleOverrides: {
// 				root: {
// 					borderRadius: "30px !important",
// 				},
// 				notchedOutline: {
// 					borderRadius: "30px !important",
// 					border: "1px solid #DEEEFF !important",
// 				},
// 			},
// 		},
// 	},
// });

// export default mui_theme;

//NEW THEME
const mui_theme = createTheme({
  palette: palette,
  typography: {
    fontFamily: 'Poppins, sans-serif, Pacifico',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    body1: {
      fontSize: '14px',
      // lineHeight: "30px",
      fontFamily: 'Poppins',
    },
    body2: {
      fontSize: '12px',
      lineHeight: '26px',
      fontFamily: 'Poppins',
    },
    button: {
      fontSize: 14,
      fontFamily: 'Poppins, sans-serif',
      textTransform: 'capitalize',
    },
    caption: {
      fontFamily: 'Poppins',
      fontSize: '12px',
      color: '#5C7CA0',
    },
    h1: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '25px',
      // lineHeight: "38.5px",
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '23px',
      // lineHeight: "35px",
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '21px',
      // lineHeight: "33px",
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '19px',
      // lineHeight: "31px",
    },
    h5: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '17px',
      // lineHeight: "29px",
    },
    h6: {
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 500,
      fontSize: '15px',
      // lineHeight: "27px",
    },
    subtitle1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '16px',
      // lineHeight: "28px",
      fontWeight: 300,
    },
    subtitle2: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '14px',
      // lineHeight: "26px",
      fontWeight: 500,
    },
  },
  shape: {
    // borderRadius: 25,
  },

  components: {
    // tooltip
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: palette.secondary.main,
          color: '#FFFFFF',
          fontSize: '12px',
          borderRadius: 8,
        },
        arrow: {
          color: palette.secondary.main,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: palette.primary.dark,
          },
          // "&.Mui-disabled": {
          // 	color: "rgba(0, 0, 0, 0.26)",
          // },
        },
      },
    },
    // Switch style
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 42,
          height: 26,
          padding: 0,
          marginLeft: 0,
          '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
              transform: 'translateX(16px)',
              color: '#fff',
              '& + .MuiSwitch-track': {
                backgroundColor: 'rgba(221, 37, 144, 1)',
                opacity: 1,
                border: 0,
              },
              '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
              },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
              color: '#33cf4d',
              border: '6px solid #fff',
              boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.06)',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
              color: 'rgba(208, 213, 221, 1)',
              boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.06)',
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.3,
            },
          },
          '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
          },
          '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: 'rgba(242, 244, 247, 1)',
            opacity: 1,
            transition: 'background-color 300ms',
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          textTransform: 'capitalize',
        },
        outlined: {
          borderColor: '#FAA7E0',
          color: 'rgba(193, 21, 116, 1)',
          boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.10)',

          // secondary
        },
        contained: {
          borderColor: 'rgba(238, 70, 188, 1)',
          boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.10)',
        },
        // custom outline variant
        outlinedPrimary: {
          borderColor: '#FAA7E0',
          color: 'rgba(193, 21, 116, 1)',
        },
        outlinedSecondary: {
          borderColor: 'rgba(208, 213, 221, 1)',
          color: 'rgba(62, 71, 132, 1)',
        },
        // normal variant
        textPrimary: {
          color: '#475467',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#F96EA9',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#F96EA9',
              color: '#FFFFFF',
            },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important',
          borderRadius: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important',
        },
      },
    },
    // MuiRadio: {
    // 	styleOverrides: {
    // 		root: {
    // 			"&.Mui-checked": {
    // 				color: "#0b77ea",
    // 			},
    // 		},
    // 	},
    // },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '16px !important',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important',
          backgroundColor: 'white',
          '&:hover': {
            borderColor: '#F96EA9',
          },
          '&.Mui-focused': {
            borderColor: '#F96EA9',
          },
        },
        notchedOutline: {
          border: '1px solid rgba(208, 213, 221, 1) !important',
          borderRadius: '8px !important',
        },
      },
    },
    // style mui select
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '16px !important',
        },
        notchedOutline: {
          borderRadius: '16px !important',
          border: '1px solid rgba(208, 213, 221, 1) !important',
        },
      },
    },

    // tabs
    // MuiTab: {
    // 	styleOverrides: {
    // 		// selected tab text color
    // 		selected: {
    // 			color: `${palette.primary.dark} !important`,
    // 		},
    // 	},
    // },
  },
})

export default mui_theme
