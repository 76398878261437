/* eslint-disable react/display-name */
import { CircularProgress } from '@mui/material'

import logo from '../../assets/img/new_logo1.png'

import styles from './styles.module.css'

export default function () {
  return (
    <div className={styles.container}>
      <img alt="..." className={styles.logo} src={logo} />
      <CircularProgress color="primary" size={40} />
    </div>
  )
}
