import { type SVGProps } from 'react'

export function HyperlinkIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="21" viewBox="0 0 21 21" width="21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.02083 14.9193H6.35417C4.05298 14.9193 2.1875 13.0538 2.1875 10.7526C2.1875 8.45142 4.05298 6.58594 6.35417 6.58594H8.02083M13.0208 14.9193H14.6875C16.9887 14.9193 18.8542 13.0538 18.8542 10.7526C18.8542 8.45142 16.9887 6.58594 14.6875 6.58594H13.0208M6.35417 10.7526L14.6875 10.7526"
        stroke="#101828"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.66667"
      />
    </svg>
  )
}
