import { ReactComponent as ArrowCircleUp } from '@/assets/icons/arrow-circle-up.svg'

export function PLGHandler({
  onMouseEnter,
  visible,
  style = {},
}: {
  onMouseEnter: (() => void) | undefined
  visible: boolean
  style?: React.CSSProperties
}) {
  if (!visible) return null

  return <ArrowCircleUp style={style} onMouseEnter={onMouseEnter} />
}
