import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

const useKeyPress = (keys, callback, node = null) => {
  // Implement the callback ref pattern
  const callbackRef = useRef(callback)

  useLayoutEffect(() => {
    callbackRef.current = callback
  })

  // Handle what happens on key press
  const handleKeyPress = useCallback(
    event => {
      // List of input types where we want to ignore the key press
      const inputTypes = ['input', 'textarea']
      // List of arrow keys
      const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']

      // Get the active element and check if it's an input field
      const activeElement = document.activeElement
      const isInputField =
        activeElement && (inputTypes.includes(activeElement.tagName.toLowerCase()) || activeElement.isContentEditable)

      // Only proceed with the callback if the focused element is not an input field
      // or if the key is not an arrow key
      if (!isInputField || !arrowKeys.includes(event.key)) {
        if (keys.some(key => event.key === key)) {
          callbackRef.current(event)
        }
      }
    },
    [keys],
  )

  useEffect(() => {
    // Target is either the provided node or the document
    const targetNode = node ?? document

    // Attach the event listener
    targetNode && targetNode.addEventListener('keydown', handleKeyPress)

    // Remove the event listener
    return () => targetNode && targetNode.removeEventListener('keydown', handleKeyPress)
  }, [handleKeyPress, node])
}

export default useKeyPress
