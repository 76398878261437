import { useState, useEffect } from "react";

import { createApi } from "unsplash-js";

export const unsplash = createApi({
	accessKey: import.meta.env.VITE_UNSPLASH_ACCESS_KEY,
});

const useUnsplash = () => {
	const [pics, setPics] = useState([]);

	const searchImages = async (searchTerm, page = 1) => {
		const searchData = await unsplash.search.getPhotos({
			query: searchTerm,
			page: page,
			perPage: 10,
			contentFilter: "high",
		});
		const imagesArray = searchData.response.results;
		setPics(imagesArray);
		return imagesArray;
	};
	const getSingleImage = async (term) => {
		const image = await unsplash.search.getPhotos({
			query: term,
			page: 1,
			perPage: 1,
			contentFilter: "high",
		});
		if (!image?.response?.results?.[0]) return "";

		return image?.response?.results?.[0] || "";
	};

	return { pics, searchImages, getSingleImage };
};

export default useUnsplash;
