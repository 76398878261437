import { createSvgIcon } from "@mui/material/utils";

export const CircleArrowsIcon = createSvgIcon(
  <svg
    width={91}
    height={93}
    viewBox="0 0 91 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#a)">
      <path
        d="M23.294 34.88c2.86-10.67 13.826-17.002 24.495-14.143l7.728 2.07c10.669 2.86 17 13.826 14.142 24.495l-2.07 7.728c-2.86 10.67-13.826 17-24.496 14.142l-7.727-2.07c-10.67-2.86-17.001-13.826-14.142-24.496z"
        fill="#7F56D9"
      />
    </g>
    <g filter="url(#b)">
      <path
        d="M13.441 44.954c0-5.6 0-8.4 1.09-10.54a10 10 0 0 1 4.37-4.37c2.14-1.09 4.94-1.09 10.54-1.09h16c5.6 0 8.401 0 10.54 1.09a10 10 0 0 1 4.37 4.37c1.09 2.14 1.09 4.94 1.09 10.54v16c0 5.6 0 8.4-1.09 10.54a10 10 0 0 1-4.37 4.37c-2.139 1.09-4.94 1.09-10.54 1.09h-16c-5.6 0-8.4 0-10.54-1.09a10 10 0 0 1-4.37-4.37c-1.09-2.14-1.09-4.94-1.09-10.54z"
        fill="#fff"
        fillOpacity={0.2}
      />
      <path
        d="M13.941 44.954c0-2.808 0-4.885.135-6.527.134-1.637.398-2.798.9-3.786a9.5 9.5 0 0 1 4.152-4.151c.988-.503 2.15-.768 3.786-.901 1.643-.134 3.719-.135 6.527-.135h16c2.809 0 4.885 0 6.528.135 1.636.133 2.798.398 3.785.9a9.5 9.5 0 0 1 4.152 4.152c.503.988.767 2.15.9 3.786.135 1.642.135 3.719.135 6.527v16c0 2.809 0 4.885-.134 6.527-.134 1.637-.398 2.799-.901 3.786a9.5 9.5 0 0 1-4.152 4.152c-.987.503-2.149.767-3.785.9-1.643.135-3.72.135-6.528.135h-16c-2.808 0-4.884 0-6.527-.134-1.637-.134-2.798-.398-3.786-.901a9.5 9.5 0 0 1-4.151-4.152c-.503-.987-.767-2.149-.901-3.786-.134-1.642-.135-3.718-.135-6.527z"
        stroke="#fff"
        strokeOpacity={0.6}
      />
      <g clipPath="url(#c)">
        <path
          d="M42.441 59.828a8.5 8.5 0 0 0-5-15.375h-.5m.5 17a8.5 8.5 0 0 1-5-15.374m4 17.275 2-2-2-2m2-12.8-2-2 2-2"
          stroke="#fff"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
    <path
      d="M1.313 74.247C.596 75.659.307 77.234.5 78.682c.222 1.668 1.073 3.155 2.334 4.08.737.539 1.647.912 2.705 1.108q.716.13 1.416.13c2.076 0 3.99-.847 5.115-2.329 1.356-1.784 1.412-4.04 1.359-6.014-.077-2.877-1.57-4.934-3.99-5.503-3.028-.708-6.597 1.085-8.126 4.093"
      fill="#EE46BC"
    />
    <g filter="url(#d)">
      <path
        d="M58.389 87.715c.944.77 2.187 1.213 3.59 1.277q.18.008.36.008c2.16 0 4.213-.909 5.55-2.475 1.578-1.851 2-4.527 1.05-6.656-1.506-3.375-5.997-3.293-8.559-2.18-1.865.812-3.27 2.418-3.755 4.293-.548 2.117.16 4.42 1.764 5.733"
        fill="#FEC84B"
      />
    </g>
    <path
      d="M73.545 40.728c.034-.387-.002-.8-.105-1.228-.495-2.058-2.378-3.592-4.479-3.65-1.823.014-3.294 1.067-3.848 2.916-.483 1.615.13 3.474 1.492 4.523a4.47 4.47 0 0 0 2.73.911c.648 0 1.3-.135 1.906-.41 1.312-.599 2.195-1.77 2.304-3.062"
      fill="#36BFFA"
    />
    <path
      d="M16.455 24.488c.997.342 1.967.512 2.895.512q.591 0 1.158-.091c1.688-.28 3.083-1.209 3.641-2.432.607-1.33.13-2.673-.186-3.56-.683-1.92-2.478-3.034-4.687-2.907-2.77.159-5.359 2.166-5.774 4.474-.148.831-.026 1.575.367 2.214.48.776 1.326 1.363 2.586 1.79"
      fill="#47CD89"
    />
    <defs>
      <filter
        id="a"
        x={-3.953}
        y={-4.439}
        width={98.789}
        height={98.788}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={10}
          result="effect1_foregroundBlur_5444_41980"
        />
      </filter>
      <filter
        id="b"
        x={-2.559}
        y={12.954}
        width={80}
        height={80}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feGaussianBlur
          in="BackgroundImageFix"
          stdDeviation={8}
        />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_5444_41980"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_backgroundBlur_5444_41980"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        x={52.441}
        y={73}
        width={21}
        height={20}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood
          floodOpacity={0}
          result="BackgroundImageFix"
        />
        <feBlend
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation={2}
          result="effect1_foregroundBlur_5444_41980"
        />
      </filter>
      <clipPath id="c">
        <path
          fill="#fff"
          d="M25.441 40.954h24v24h-24z"
        />
      </clipPath>
    </defs>
  </svg>,
  "CircleArrows"
);
