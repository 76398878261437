/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
import { insertOrUpdateBlock } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import { Popover } from '@mui/material'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA, CUSTOM_BLOCKS_GROUP_MEDIA_AREA } from '../custom-blocks-types-media-area'
import { type Editor } from '../media-area-schema'

import { CardPreviewBlock } from './CardPreviewBlock'
import { ContainerCustomBlock } from './ContainerCustomBlock'
import { CustomBlockIcons } from './IconsCustomBlocks'

import { InputEmbedIframe } from '@/components/EmbedContent'
import { usePopover } from '@/hooks/ui/usePopover'
import { POPOVER_POSITIONS } from '@/constants/popover.constants'

export const PodcastCustomBlock = createReactBlockSpec(
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_PODCAST,
    content: 'none',
    propSchema: {
      iframe: {
        default: '',
      },
      width: {
        default: 0,
      },
      height: {
        default: 0,
      },
      justifyContent: {
        default: 'center',
        values: ['flex-start', 'center', 'flex-end'],
      },
    },
  },
  {
    render: props => {
      const { justifyContent, iframe, width, height } = props.block.props

      const initialSize = {
        width,
        height,
      }

      const handleUpdateBlock = (iframeUser: string) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            iframe: iframeUser,
          },
        })
      }

      const removeBlock = () => {
        props.editor.removeBlocks([props.block.id])
      }

      const handleResize = (width: number, height: number) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            width,
            height,
          },
        })
      }

      const handleJustifyContent = (place: 'flex-start' | 'center' | 'flex-end') => {
        props.editor.updateBlock(props.block.id, {
          props: {
            justifyContent: place,
          },
        })
      }

      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <PodcastUI
            handleJustifyContent={handleJustifyContent}
            handleResize={handleResize}
            handleUpdateBlock={handleUpdateBlock}
            iframe={iframe.length > 0 ? iframe : null}
            initialSize={initialSize.width ? initialSize : null}
            isEditable={props.editor.isEditable}
            justifyContent={justifyContent}
            removeBlock={removeBlock}
          />
        </div>
      )
    },
  },
)

function PodcastUI({
  iframe = null,
  handleUpdateBlock,
  removeBlock,
  handleResize,
  justifyContent,
  handleJustifyContent,
  initialSize,
  isEditable,
}: {
  iframe: string | null
  handleUpdateBlock: (iframeUser: string) => void
  justifyContent: 'flex-start' | 'center' | 'flex-end'
  removeBlock: () => void
  handleResize: (width: number, height: number) => void
  handleJustifyContent: (place: 'flex-start' | 'center' | 'flex-end') => void
  initialSize: {
    width: number
    height: number
  } | null
  isEditable: boolean
}) {
  const { anchorEl, handleClosePopover, handleOpenPopover, refPopoverTrigger, preferredPlacement } = usePopover({
    showOnMount: isEditable,
  })

  const open = Boolean(anchorEl)

  const isOpen = open && isEditable

  return (
    <>
      {iframe == null ? (
        <>
          <CardPreviewBlock
            ref={refPopoverTrigger}
            actions={{
              onDelete: removeBlock,
            }}
            icon={CustomBlockIcons.PodcastBlockIcon}
            showActions={isEditable}
            onClick={handleOpenPopover}
          >
            Embed Podcast
          </CardPreviewBlock>
          <Popover
            anchorEl={anchorEl}
            sx={{
              '& .MuiPaper-root': {
                background: '#FFF',
                boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.10) !important',
                border: '1px solid rgba(0,0,0,0.08)',
                borderRadius: '8px',
              },
            }}
            {...POPOVER_POSITIONS[preferredPlacement]}
            open={isOpen}
            onClose={handleClosePopover}
          >
            <InputEmbedIframe onEmbedIframeUploaded={handleUpdateBlock} />
          </Popover>
        </>
      ) : (
        <ContainerCustomBlock
          handleJustifyContent={handleJustifyContent}
          handleResize={handleResize}
          initialSize={initialSize ?? undefined}
          justifyContent={justifyContent}
          type="podcast"
        >
          <div dangerouslySetInnerHTML={{ __html: iframe }} />
        </ContainerCustomBlock>
      )}
    </>
  )
}

export const insertPodcastCustomBlock = (editor: Editor) => ({
  title: 'Podcast',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      // @ts-ignore
      type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_PODCAST,
    })
  },
  aliases: ['pocast', 'audio', 'music', 'sound'],
  group: CUSTOM_BLOCKS_GROUP_MEDIA_AREA.MEDIA,
  icon: <CustomBlockIcons.PodcastBlockIcon height={25} width={25} />,
  subtext: 'Add a podcast for your students',
})
