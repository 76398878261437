import { useState, useEffect, useRef } from 'react'

import checkMicPermissions from './checkMicPermissions'

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  recording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
  url: null,
  elapsedTime: '00:00',
}

function formatTime(minutes, seconds) {
  const mm = String(minutes).padStart(2, '0')
  const ss = String(seconds).padStart(2, '0')

  return `${mm}:${ss}`
}

function createMediaRecorder(stream) {
  const options = ['audio/webm', 'audio/ogg', 'audio/wav', 'audio/mp3; codecs=opus']

  for (const mimeType of options) {
    if (MediaRecorder.isTypeSupported(mimeType)) {
      console.log(`Using MIME type: ${mimeType}`)

      return new MediaRecorder(stream, { mimeType })
    }
  }

  console.warn('No supported media type found. Using default.')

  return new MediaRecorder(stream)
}

async function startRecording(setRecorderState) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: {
        echoCancellation: true,
        noiseSuppression: true,
        autoGainControl: true,
        sampleRate: 44100,
        channelCount: 1,
      },
    })

    // console.log('Got media stream:', stream)
    setRecorderState(prevState => ({
      ...prevState,
      initRecording: true,
      mediaStream: stream,
      recording: true,
    }))
  } catch (err) {
    console.error('Error accessing media devices:', err)
    checkMicPermissions()
  }
}

function saveRecording(recorderRef, setRecorderState, timeoutId) {
  console.log('Saving recording...')
  const recorder = recorderRef.current

  if (recorder && recorder.state !== 'inactive') {
    recorder.stop()
    setRecorderState(prevState => ({
      ...prevState,
      recording: false,
    }))
    if (timeoutId) clearTimeout(timeoutId)
  }
}

export default function useRecorder(maxDuration) {
  const [recorderState, setRecorderState] = useState(initialState)
  const recorderRef = useRef(null)

  useEffect(() => {
    if (!maxDuration) return

    let timeoutId

    if (recorderState.recording) {
      timeoutId = setTimeout(() => {
        // console.log(`Max duration (${maxDuration}s) reached. Stopping recording.`)
        saveRecording(recorderRef, setRecorderState, timeoutId)
      }, maxDuration * 1000)
    }

    return () => clearTimeout(timeoutId)
  }, [recorderState.recording, maxDuration])

  useEffect(() => {
    if (recorderState.mediaRecorder) {
      recorderRef.current = recorderState.mediaRecorder
    }
  }, [recorderState.mediaRecorder])

  useEffect(() => {
    let recordingInterval = null

    if (recorderState.initRecording) {
      recordingInterval = setInterval(() => {
        setRecorderState(prevState => {
          if (prevState.recordingMinutes * 60 + prevState.recordingSeconds >= maxDuration) {
            clearInterval(recordingInterval)
            saveRecording(recorderRef, setRecorderState)

            return prevState
          }

          const newRecordingSeconds = (prevState.recordingSeconds + 1) % 60
          const newRecordingMinutes =
            prevState.recordingSeconds + 1 >= 60 ? prevState.recordingMinutes + 1 : prevState.recordingMinutes

          return {
            ...prevState,
            recordingMinutes: newRecordingMinutes,
            recordingSeconds: newRecordingSeconds,
            elapsedTime: formatTime(newRecordingMinutes, newRecordingSeconds),
          }
        })
      }, 1000)
    } else {
      clearInterval(recordingInterval)
    }

    return () => clearInterval(recordingInterval)
  }, [recorderState.initRecording, maxDuration])

  useEffect(() => {
    if (recorderState.mediaStream) {
      const mediaRecorder = createMediaRecorder(recorderState.mediaStream)

      // console.log('MediaRecorder created:', mediaRecorder)
      setRecorderState(prevState => ({
        ...prevState,
        mediaRecorder: mediaRecorder,
      }))
    }
  }, [recorderState.mediaStream])

  useEffect(() => {
    const recorder = recorderState.mediaRecorder
    let chunks = []

    if (recorder && recorder.state === 'inactive') {
      recorder.start()
      // console.log('MediaRecorder started:', recorder)

      recorder.ondataavailable = e => {
        chunks.push(e.data)
        // console.log('Data available:', e.data)
      }

      recorder.onstop = () => {
        // console.log('MediaRecorder stopped. Creating blob...')
        const blob = new Blob(chunks, { type: recorder.mimeType })

        // console.log('Blob created:', blob)
        chunks = []

        try {
          const url = window.URL.createObjectURL(blob)

          // console.log('URL created:', url)
          setRecorderState(prevState => {
            if (prevState.mediaRecorder)
              return {
                ...initialState,
                audio: blob,
                url: url,
              }
            else return initialState
          })
        } catch (error) {
          console.error('Error creating object URL:', error)
        }
      }
    }

    return () => {
      if (recorder) {
        // console.log('Cleaning up MediaRecorder...')
        recorder.stream.getAudioTracks().forEach(track => track.stop())
      }
    }
  }, [recorderState.mediaRecorder])

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => {
      // console.log('Cancelling recording...')
      setRecorderState(initialState)
    },
    saveRecording: () => saveRecording(recorderRef, setRecorderState),
  }
}

// import { useState, useEffect, useRef } from 'react'

// async function startRecording(setRecorderState) {
//   try {
//     const stream = await navigator.mediaDevices.getUserMedia({
//       // audio: true,
//       audio: {
//         echoCancellation: true,
//         noiseSuppression: true,
//         autoGainControl: true,
//       },
//     })

//     setRecorderState(prevState => ({
//       ...prevState,
//       initRecording: true,
//       mediaStream: stream,
//       recording: true,
//     }))
//   } catch (err) {
//     console.log(err)
//   }
// }

// function saveRecording(recorderRef, setRecorderState, timeoutId) {
//   const recorder = recorderRef.current

//   if (recorder && recorder.state !== 'inactive') {
//     recorder.stop()
//     setRecorderState(prevState => ({
//       ...prevState,
//       recording: false,
//     }))
//     if (timeoutId) clearTimeout(timeoutId)
//   }
// }

// const initialState = {
//   recordingMinutes: 0,
//   recordingSeconds: 0,
//   initRecording: false,
//   recording: false,
//   mediaStream: null,
//   mediaRecorder: null,
//   audio: null,
//   url: null,
//   elapsedTime: '00:00',
// }

// function formatTime(minutes, seconds) {
//   const mm = String(minutes).padStart(2, '0')
//   const ss = String(seconds).padStart(2, '0')

//   return `${mm}:${ss}`
// }

// export default function useRecorder(maxDuration) {
//   const [recorderState, setRecorderState] = useState(initialState)
//   const recorderRef = useRef(null)

//   useEffect(() => {
//     if (!maxDuration) return

//     let timeoutId

//     if (recorderState.recording) {
//       timeoutId = setTimeout(() => {
//         saveRecording(recorderRef, setRecorderState, timeoutId)
//       }, maxDuration * 1000)
//     }

//     return () => clearTimeout(timeoutId)
//   }, [recorderState.recording, maxDuration])

//   useEffect(() => {
//     if (recorderState.mediaRecorder) {
//       recorderRef.current = recorderState.mediaRecorder
//     }
//   }, [recorderState.mediaRecorder])

//   useEffect(() => {
//     let recordingInterval = null

//     if (recorderState.initRecording) {
//       recordingInterval = setInterval(() => {
//         setRecorderState(prevState => {
//           if (prevState.recordingMinutes * 60 + prevState.recordingSeconds >= maxDuration) {
//             clearInterval(recordingInterval)
//             saveRecording(recorderRef, setRecorderState)

//             return prevState
//           }

//           const newRecordingSeconds = (prevState.recordingSeconds + 1) % 60
//           const newRecordingMinutes =
//             prevState.recordingSeconds + 1 >= 60 ? prevState.recordingMinutes + 1 : prevState.recordingMinutes

//           return {
//             ...prevState,
//             recordingMinutes: newRecordingMinutes,
//             recordingSeconds: newRecordingSeconds,
//             elapsedTime: formatTime(newRecordingMinutes, newRecordingSeconds),
//           }
//         })
//       }, 1000)
//     } else {
//       clearInterval(recordingInterval)
//     }

//     return () => clearInterval(recordingInterval)
//   }, [recorderState.initRecording, maxDuration])

//   useEffect(() => {
//     if (recorderState.mediaStream) {
//       setRecorderState(prevState => ({
//         ...prevState,
//         mediaRecorder: new MediaRecorder(prevState.mediaStream),
//       }))
//     }
//   }, [recorderState.mediaStream])

//   useEffect(() => {
//     const recorder = recorderState.mediaRecorder
//     let chunks = []

//     if (recorder && recorder.state === 'inactive') {
//       recorder.start()

//       recorder.ondataavailable = e => {
//         chunks.push(e.data)
//       }

//       recorder.onstop = () => {
//         // const blob = new Blob(chunks, { type: "audio/mp3; codecs=opus" });
//         // const blob = new Blob(chunks, { type: 'audio/wav' })
//         const blob = new Blob(chunks, { type: 'audio/webm' })

//         chunks = []

//         setRecorderState(prevState => {
//           if (prevState.mediaRecorder)
//             return {
//               ...initialState,
//               audio: blob,
//               url: window.URL.createObjectURL(blob),
//             }
//           else return initialState
//         })
//       }
//     }

//     return () => {
//       if (recorder) recorder.stream.getAudioTracks().forEach(track => track.stop())
//     }
//   }, [recorderState.mediaRecorder])

//   return {
//     recorderState,
//     startRecording: () => startRecording(setRecorderState),
//     cancelRecording: () => setRecorderState(initialState),
//     saveRecording: () => saveRecording(recorderRef, setRecorderState),
//   }
// }
