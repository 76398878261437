import {
  ANALYTICS_PLANS,
  AUTO_GRADING_PLANS,
  FEEDBACK_PLANS,
  SPACES_PLANS,
  AI_ASSISTANT_PLANS,
  ASSIGNMENT_SETTINGS_PLANS,
  DISCOVER_PLANS,
  MEDIA_AREA_PLANS,
} from './variables-plans'

export const FREE_PLAN = []

export const TEACHER_PRO_PLAN = [
  FEEDBACK_PLANS.FEEDBACK_TRANSCRIPT,
  FEEDBACK_PLANS.FEEDBACK_SUMMARY,
  FEEDBACK_PLANS.FEEDBACK_TARGET_LANGUAGE,
  AUTO_GRADING_PLANS.AUTO_GRADING_PASS_FAIL,
  ANALYTICS_PLANS.ANALYTICS_GRADEBOOK,
  SPACES_PLANS.SPACES_CREATE_SPACE,
]

export const ORGANIZATION_PLAN = [
  FEEDBACK_PLANS.FEEDBACK_TRANSCRIPT,
  FEEDBACK_PLANS.FEEDBACK_SUMMARY,
  FEEDBACK_PLANS.FEEDBACK_GRAMMAR_INSIGHTS,
  FEEDBACK_PLANS.FEEDBACK_SUGGESTED_RESPONSE,
  FEEDBACK_PLANS.FEEDBACK_RUBRIC,
  FEEDBACK_PLANS.FEEDBACK_GRADING_STANDARDS,
  FEEDBACK_PLANS.FEEDBACK_DISABLE_ALLOW_RETRIES,
  FEEDBACK_PLANS.FEEDBACK_TARGET_LANGUAGE,
  AUTO_GRADING_PLANS.AUTO_GRADING_PASS_FAIL,
  AUTO_GRADING_PLANS.AUTO_GRADING_RUBRICS,
  AI_ASSISTANT_PLANS.AI_ASSISTANT_DOCUMENT_UPLOADS,
  AI_ASSISTANT_PLANS.AI_ASSISTANT_UNLIMITED_USE,
  ASSIGNMENT_SETTINGS_PLANS.ASSESSMENTS,
  ASSIGNMENT_SETTINGS_PLANS.GOOGLE_CLASSROOM_GRADE_PASSBACK,
  ANALYTICS_PLANS.ANALYTICS_GRADEBOOK,
  ANALYTICS_PLANS.ANALYTICS_STUDENT_PROGRESS_REPORTS,
  ANALYTICS_PLANS.ANALYTICS_CLASSROOM_ANALYTICS,
  ANALYTICS_PLANS.ANALYTICS_ORGANIZATION,
  SPACES_PLANS.SPACES_CREATE_SPACE,
  SPACES_PLANS.SPACES_CHECK_POINTS,
  DISCOVER_PLANS.DISCOVER_ORGANIZATION_LIBRARY,
  MEDIA_AREA_PLANS.MEDIA_AREA_DOCUMENT_UPLOAD,
  MEDIA_AREA_PLANS.MEDIA_AREA_AUDIO_FILES,
]

export const planTypes = {
  basic: 'basic',
  teacher_pro: 'teacher_pro',
  organization: 'organization',
  // OLD PLANS
  starter: 'starter',
  growth: 'growth',
  professional: 'professional',
} as const

export const PERMISSIONS_MAP = {
  [planTypes.starter]: TEACHER_PRO_PLAN,
  [planTypes.teacher_pro]: TEACHER_PRO_PLAN,
  [planTypes.growth]: ORGANIZATION_PLAN,
  [planTypes.professional]: ORGANIZATION_PLAN,
  [planTypes.organization]: ORGANIZATION_PLAN,
  [planTypes.basic]: FREE_PLAN,
} as const
