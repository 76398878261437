import { Howl } from "howler";

const micOn = new Howl({
  src: [
    "https://firebasestorage.googleapis.com/v0/b/gobeyond-app.appspot.com/o/audio%2Fmic_on.m4a?alt=media&token=724320a9-a462-4fbe-b100-680299882ea3",
  ],
  format: ["m4a"],
});
micOn.volume(0.5);

const micOff = new Howl({
  src: [
    "https://firebasestorage.googleapis.com/v0/b/gobeyond-app.appspot.com/o/audio%2FMic%20off.m4a?alt=media&token=6734bed0-d3c2-4f66-a3fe-ef321be0a821",
  ],
  format: ["m4a"],
});
micOff.volume(0.5);
const blip = new Howl({
  src: [
    `https://firebasestorage.googleapis.com/v0/b/gobeyond-app.appspot.com/o/audio%2Fblip.mp3?alt=media&token=6f389f63-57b4-4196-a859-ae1cedc0a220`,
  ],
  format: ["mp3"],
});
blip.volume(0.2);
const scoresSummary = new Howl({
  src: [
    "https://firebasestorage.googleapis.com/v0/b/gobeyond-app.appspot.com/o/audio%2Faudio-scores-summary%20(1).mp3?alt=media&token=09bf0875-2133-472c-a52b-70ef65cca3a5",
  ],
  format: ["mp3"],
});
scoresSummary.volume(0.6);

const audioClips = {
  blip,
  scoresSummary,
  micOn,
  micOff,
};
export default audioClips;
