import { createSlice } from '@reduxjs/toolkit'

import { myFunctions } from '../firebase'

export const organizationsSlice = createSlice({
  name: 'organization',
  initialState: {
    loaded: false,
    loading: false,
  },
  reducers: {
    setOrganization: (state, action) => {
      state = { ...state, ...action.payload, loaded: true, loading: false }

      return state
    },
    clearOrganization: state => {
      state = {
        loaded: false,
        loading: false,
      }

      return state
    },
  },
})

export const { setOrganization } = organizationsSlice.actions

export default organizationsSlice.reducer

const excludedDomains = [
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'msn.com',
  'live.com',
  'ymail.com',
  'mail.com',
  'inbox.com',
  'protonmail.com',
  'zoho.com',
  'test.com',
]

export const loadOrganization = email => {
  return async (dispatch, getState) => {
    const emailDomain = email?.split?.('@')?.[1]
    const {
      user: { profile },
    } = getState()

    if (!profile || !profile.roles || !profile.roles.includes('ADMIN')) {
      return
    }
    try {
      if (excludedDomains.includes(emailDomain)) {
        return
      }
      const { data } = await myFunctions.loadOrganizationV2()

      if (!data.success) {
        console.log('No organization found')

        return
      }
      if (data.domainOrg) {
        return dispatch(
          setOrganization({
            ...data.domainOrg,
            adminOrgs: data.adminOrgs || [],
            userOrgs: data.userOrgs || [],
            managerOrgs: data.managerOrgs || [],
          }),
        )
      } else {
        return dispatch(
          setOrganization({
            ...data,
          }),
        )
      }
    } catch (error) {
      console.log(error)
    }
  }
}

// export const loadOrganization = (email) => {
// 	return async (dispatch, getState) => {
// 		const emailDomain = email.split("@")[1];

// 		try {
// 			if (excludedDomains.includes(emailDomain)) {
// 				return;
// 			}
// const {
// 	user: { auth, profile },
// } = getState();
// 			const {
// 				data: { success, org },
// 			} = await myFunctions.loadOrganization({
// 				isTeacher: profile?.roles?.includes("ADMIN"),
// 			});

// 			if (!success) {
// 				console.log("No organization found");
// 				return;
// 			}
// 			if (org) {
// 				const payload = {
// 					name: org.name || org?.districtName || emailDomain,
// 					id: org.id,
// 				};
// 				if (org.isAdmin) {
// 					payload.isAdmin = true;
// 					payload.teachers = org.teachers;
// 				}
// 				// if (profile.roles.includes("ADMIN")) {
// 				//   //Mark admin login
// 				//   // await dispatch(recordOrganizationLogin("members", org.id));
// 				// } else if (profile.roles.includes("USER")) {
// 				//   //Mark user login
// 				//   // await dispatch(recordOrganizationLogin("students", org.id));
// 				// }

// 				dispatch(setOrganization(payload));
// 			} else if (profile?.roles && profile?.roles?.includes("ADMIN")) {
// 				const response = await myFunctions.createOrganization();
// 				const newOrg = response.data;
// 				if (newOrg.error) {
// 					return;
// 				}
// 				const payload = {
// 					...newOrg,
// 				};
// 				dispatch(setOrganization(payload));
// 				//Mark admin login
// 				// await dispatch(recordOrganizationLogin("members", emailDomain));
// 			}
// 		} catch (error) {
// 			console.log(error);
// 		}
// 	};
// };
