/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useSelector } from 'react-redux'

export function useUser() {
  const user = useSelector<any>(state => state.user)

  return user as {
    auth: UserAuth
    profile: UserProfile
  }
}

export function useAuthUser() {
  const auth = useSelector<any>(state => state.user.auth)

  return auth as UserAuth
}

export function useProfileUser() {
  const profile = useSelector<any>(state => state.user.profile)

  return profile as UserProfile
}

interface UserAuth {
  uid: string
  email: string
  displayName: string
  custom_claims: Record<string, any>
  createdAt: string
  lastLogin: string
  loaded: boolean
  loading: boolean
}

interface UserProfile {
  emailOptOut: boolean
  nativeLanguage: string
  speakableio: boolean
  onboardingState: string
  dateMade: {
    seconds: number
    nanoseconds: number
  }
  connectedGoogleClassroom: boolean
  emailVerified: boolean
  onboardingComplete: boolean
  savedSets: string[]
  additionalNativeLanguages: string[]
  recentRubrics: string[]
  isGoogleUser: boolean
  language: string
  studentDeviceType: string
  totalAssignmentsActivated: number
  displayName: string
  onboardingSteps: string[]
  totalStudentAssignmentsSubmitted: number
  owners: string[]
  additionalLanguages: string[]
  email: string
  adminStatus: string
  schoolId: string
  schoolName: string
  districtName: string
  image: {
    url: string | null
    path: string | null
  }
  roles: string[]
  lastAccess: {
    seconds: number
    nanoseconds: number
  }
  recentAssignments: string[]
  districtID: string
  loaded: boolean
  loading: boolean
  isTeacher: boolean
  defaultGradingSettings: any
  defaultAssignmentRepeat: number
  googleClassroomUserId?: string
  savedRubrics: string[]
}
