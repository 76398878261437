import { filterSuggestionItems } from '@blocknote/core'
import {
  type DefaultReactSuggestionItem,
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
  type SuggestionMenuProps,
} from '@blocknote/react'
import { Box, Stack, Typography } from '@mui/material'
import { useEffect, useMemo, useRef, useState } from 'react'

import {
  insertImageCustomBlock,
  insertVideoCustomBlock,
  insertAudioCustomBlock,
  insertDocumentCustomBlock,
  insertPodcastCustomBlock,
  insertTextToSpeechCustomBlock,
} from './CustomBlocks'
import { type Editor } from './media-area-schema'

import { scrollBarStyles } from '@/assets/css/scrollbar'
import useKeyPress from '@/utils/useKeyPress'

const checkGroup = (item: DefaultReactSuggestionItem) => {
  let group = item.group ?? 'default'

  if (group === 'Headings') group = 'Text style'
  if (group === 'Basic blocks') group = 'Text style'

  return group
}

const groupByGroup = (items: DefaultReactSuggestionItem[]) => {
  const groupedItems = items.reduce<Record<string, DefaultReactSuggestionItem[]>>((acc, item) => {
    const group = checkGroup(item)

    const groupItems = acc[group] ?? []

    return {
      ...acc,
      [group]: [...groupItems, item],
    }
  }, {})

  return Object.entries(groupedItems).map(([group, newItems]) => ({
    title: group,
    items: newItems,
  }))
}

const mapText = {
  'Heading 1': {
    text: 'Title',
    styles: {
      fontWeight: 500,
      fontSize: '22px',
    },
    aliases: ['Title', 'h1', 'Heading 1', 't', 'h'],
  },
  'Heading 2': {
    text: 'Headline',
    styles: {
      fontWeight: 500,
      fontSize: '18px',
    },
    aliases: ['Headline', 'h2', 'Heading 2', 'h'],
  },
  'Heading 3': {
    text: 'Sub headline',
    styles: {
      fontWeight: 500,
      fontSize: '14px',
    },
    aliases: ['Sub headline', 'h3', 'Heading 3', 'h'],
  },
  'Numbered List': {
    text: '1. Numbered list',
    styles: {
      fontWeight: 400,
      fontSize: '14px',
    },
    aliases: ['Numbered list', '1. Numbered list', 'n', 'l'],
  },
  'Bullet List': {
    text: '• Bullet List',
    styles: {
      fontWeight: 400,
      fontSize: '14px',
    },
    aliases: ['Bullet list', '• Bullet List', 'b', 'l'],
  },
}

export function CustomSuggestionMenuController({ editor }: { editor: Editor }) {
  const defaultItems = useMemo(
    () =>
      getDefaultReactSlashMenuItems(editor).map(item => {
        const itemMapped = mapText[item.title as keyof typeof mapText]

        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!itemMapped) return item

        return {
          ...item,
          aliases: itemMapped.aliases,
        }
      }),
    [editor],
  )

  return (
    <SuggestionMenuController
      // eslint-disable-next-line @typescript-eslint/require-await
      getItems={async query =>
        filterSuggestionItems(
          [
            insertImageCustomBlock(editor),
            insertVideoCustomBlock(editor),
            insertAudioCustomBlock(editor),
            insertDocumentCustomBlock(editor),
            insertPodcastCustomBlock(editor),
            // insertWebCustomBlock(editor),
            insertTextToSpeechCustomBlock(editor),
            ...defaultItems,
          ],
          query,
        )
      }
      suggestionMenuComponent={CustomSlashMenu}
      triggerCharacter="/"
    />
  )
}

function CustomSlashMenu(props: SuggestionMenuProps<DefaultReactSuggestionItem>) {
  const groupItems = groupByGroup(props.items)
  const [itemSelected, setItemSelected] = useState<DefaultReactSuggestionItem | null>(null)

  const isFocused = (item: DefaultReactSuggestionItem) => {
    return item.title === itemSelected?.title
  }

  useKeyPress(['ArrowUp'], () => {
    if (itemSelected == null) return

    const prev = props.items.findIndex(item => item.title === itemSelected.title) - 1

    if (prev >= 0) {
      setItemSelected(props.items[prev])
    } else {
      setItemSelected(null)
    }
  })

  useKeyPress(['ArrowDown'], () => {
    if (itemSelected == null) {
      setItemSelected(props.items[0])
    } else {
      const next = props.items.findIndex(item => item.title === itemSelected.title) + 1

      if (next < props.items.length) {
        setItemSelected(props.items[next])
      } else {
        setItemSelected(props.items[0])
      }
    }
  })

  useKeyPress(['Enter'], (e: React.KeyboardEvent<HTMLElement>) => {
    if (itemSelected == null) return
    e.preventDefault()
    e.stopPropagation()

    props.onItemClick?.(itemSelected)
  })

  return (
    <Stack
      className="slash-menu"
      sx={{
        paddingBlock: '16px',
        paddingInline: '8px',
        backgroundColor: '#fff',
        borderRadius: '16px',
        boxShadow: '0px 1px 16px 0px rgba(16, 24, 40, 0.10)',
        gap: '2px',
        maxHeight: '600px',
        ...scrollBarStyles,
        overflowY: 'auto',
      }}
    >
      {groupItems.map(groupItem => (
        <Stack key={groupItem.title}>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: '#475467',
              paddingLeft: '8px',
            }}
          >
            {groupItem.title}
          </Typography>
          {groupItem.items.map(block => (
            <Item
              key={block.title}
              focused={isFocused(block)}
              item={block}
              showIcon={groupItem.title !== 'Text style'}
              onItemClick={props.onItemClick}
            />
          ))}
        </Stack>
      ))}
    </Stack>
  )
}

function Item({
  item,
  onItemClick,
  showIcon = true,
  focused = false,
}: {
  item: DefaultReactSuggestionItem
  onItemClick?: (item: DefaultReactSuggestionItem) => void
  showIcon?: boolean
  focused?: boolean
}) {
  const ref = useRef<HTMLElement | null>(null)

  let title = {
    text: item.title,
    styles: {},
  }

  const titleMapped = mapText[title.text as keyof typeof mapText]

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  title = titleMapped || title

  useEffect(() => {
    if (focused) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [focused])

  return (
    <Stack
      key={item.title}
      ref={ref}
      alignContent="center"
      direction="row"
      justifyContent="space-between"
      sx={{
        gap: '8px',
        cursor: 'pointer',
        padding: '8px 16px',
        borderRadius: '8px',
        transition: 'background-color 0.2s',
        backgroundColor: focused ? '#f5f5f5' : 'transparent',
        '&:hover': {
          backgroundColor: '#f5f5f5',
        },
      }}
      onClick={() => {
        onItemClick?.(item)
      }}
    >
      <Stack alignItems="center" direction="row" gap="8px">
        {showIcon ? (
          <Stack
            sx={{
              backgroundColor: '#F9FAFB',
              padding: '8px',
              borderRadius: '11px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {item.icon}
          </Stack>
        ) : null}
        <Box>
          <Typography
            color="#101828"
            sx={{
              ...title.styles,
            }}
          >
            {title.text}
          </Typography>
          <Typography color="#101828">{item.subtext}</Typography>
        </Box>
      </Stack>
      <Box>{item.badge}</Box>
    </Stack>
  )
}
