import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateUserProfile } from "./userSlice";
import {
  logOnboardingCompletion,
  logOnboardingStep,
  logOnboardingTransition,
} from "../utils/onboardingLogEvent";

export const WELCOME = "WELCOME";
export const CREATE_CLASSROOM = "CREATE_CLASSROOM";
export const ADD_ACTIVITY = "ADD_ACTIVITY";
export const CREATE_ASSIGNMENT = "CREATE_ASSIGNMENT";
export const CREATE_SPACE = "CREATE_SPACE";
export const CREATE_FROM_SCRATCH = "CREATE_FROM_SCRATCH";
export const CREATE_WITH_RESPOND_CARDS = "CREATE_WITH_RESPOND_CARDS";

export const onboardingSteps = [
  WELCOME,
  CREATE_CLASSROOM,
  ADD_ACTIVITY,
  CREATE_ASSIGNMENT,
  CREATE_SPACE,
  CREATE_FROM_SCRATCH,
  // CREATE_WITH_RESPOND_CARDS,
];

export const updateOnboardingSteps = createAsyncThunk(
  "onboarding/updateOnboardingSteps",
  async (newStep, { getState, dispatch }) => {
    const state = getState();
    const currentSteps = state.onboarding.onboardingSteps;
    const onboardingState = state.onboarding.onboardingState;
    if (!currentSteps.includes(newStep)) {
      const newOnboardingArray = [...currentSteps, newStep];
      dispatch(addOnboardingStep(newStep));
      logOnboardingStep("completed", newStep, onboardingState);
      if (
        onboardingState === "main" &&
        (newStep === CREATE_CLASSROOM || newStep === ADD_ACTIVITY)
      ) {
        dispatch(setActiveStepCompleteStatus(true));
      }
      await dispatch(
        updateUserProfile({ onboardingSteps: newOnboardingArray })
      );
    }
  }
);

export const updateOnboardingState = createAsyncThunk(
  "onboarding/updateOnboardingState",
  async (newState, { getState, dispatch }) => {
    const state = getState();
    const completedSteps = state.onboarding.onboardingSteps;
    dispatch(setOnboardingState(newState));
    logOnboardingTransition("main", "summary", completedSteps);
    await dispatch(updateUserProfile({ onboardingState: newState }));
  }
);

export const completeOnboardingState = createAsyncThunk(
  "onboarding/completeOnboardingState",
  async (_, { getState, dispatch }) => {
    const state = getState();
    const completedSteps = state.onboarding.onboardingSteps;
    dispatch(completeOnboarding());
    logOnboardingCompletion(completedSteps);
    await dispatch(updateUserProfile({ onboardingComplete: true }));
  }
);

const initialState = {
  onboardingSteps: [],
  onboardingState: "main",
  onboardingComplete: false,
  activeStep: 0,
  activeStepComplete: false,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    addOnboardingStep: (state, action) => {
      const step = action.payload;
      if (!state.onboardingSteps.includes(step)) {
        state.onboardingSteps.push(step);
      }
    },
    setOnboardingState: (state, action) => {
      state.onboardingState = action.payload;
    },
    completeOnboarding: (state) => {
      state.onboardingComplete = true;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setActiveStepCompleteStatus: (state, action) => {
      state.activeStepComplete = action.payload;
    },
    initializeOnboardingState: (state, action) => {
      const { onboardingSteps, onboardingState, onboardingComplete } =
        action.payload;
      state.onboardingSteps = onboardingSteps || initialState.onboardingSteps;
      state.onboardingState = onboardingState || initialState.onboardingState;
      state.onboardingComplete =
        onboardingComplete ?? initialState.onboardingComplete;
    },
  },
});

export const {
  addOnboardingStep,
  setOnboardingState,
  completeOnboarding,
  initializeOnboardingState,
  setActiveStep,
  setActiveStepCompleteStatus,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
