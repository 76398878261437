/* eslint-disable react/function-component-definition */
import styles from './ResizableContent.module.css'

export const DEFAULT_SIZE_RESIZABLE_CONTENT = {
  video: 440,
  image: 240,
  audio: 440,
  document: 440,
  podcast: 240,
  pdf: 720,
  web: 720,
}

export const ASPECT_RATIOS_RESIZABLE_CONTENT = {
  video: 16 / 9,
  audio: 1,
  document: 1,
  podcast: false,
  image: false,
  pdf: false,
  web: false,
}

const DEFAULT_ENABLED_RESIZABLE = {
  right: false,
  left: false,
  top: false,
  bottom: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
}

export const getEnabledResizable = (type: string, isEnabled: boolean) => {
  if (!isEnabled) return DEFAULT_ENABLED_RESIZABLE

  if (type === 'pdf') {
    return { ...DEFAULT_ENABLED_RESIZABLE, bottomLeft: true, bottomRight: true, bottom: true }
  }

  if (type === 'web') {
    return {
      ...DEFAULT_ENABLED_RESIZABLE,
      bottomLeft: true,
      bottomRight: true,
      bottom: true,
      right: true,
      left: true,
    }
  }

  return { ...DEFAULT_ENABLED_RESIZABLE, right: true, left: true }
}

export const getAspectRatioResizableContent = (type: keyof typeof ASPECT_RATIOS_RESIZABLE_CONTENT) => {
  return ASPECT_RATIOS_RESIZABLE_CONTENT[type]
}

export const getInitialSizeResizableContent = (type: keyof typeof DEFAULT_SIZE_RESIZABLE_CONTENT, maxWidth: number) => {
  let width = DEFAULT_SIZE_RESIZABLE_CONTENT[type]

  if (type === 'pdf' || type === 'web') {
    if (maxWidth > 0) width = maxWidth
  }

  return {
    width,
  }
}

const LeftHandle = () => <div className={styles.customHandleLeft} />
const RightHandle = () => <div className={styles.customHandleRight} />
const BottomHandle = () => <div className={styles.customHandleBottom} />
const BottomRightHandle = () => (
  <div className={styles.customHandleBottomRight}>
    <div />
    <div />
  </div>
)
const BottomLeftHandle = () => (
  <div className={styles.customHandleBottomLeft}>
    <div />
    <div />
  </div>
)

export const getHandleComponentResizable = (type: keyof typeof DEFAULT_SIZE_RESIZABLE_CONTENT, isHovered: boolean) => {
  if (type === 'pdf') {
    return {
      // bottom: isHovered && <BottomHandle />,
      bottomRight: isHovered && <BottomRightHandle />,
      bottomLeft: isHovered && <BottomLeftHandle />,
    }
  }

  if (type === 'web') {
    return {
      bottom: isHovered && <BottomHandle />,
      bottomRight: isHovered && <BottomRightHandle />,
      bottomLeft: isHovered && <BottomLeftHandle />,
      right: isHovered && <RightHandle />,
      left: isHovered && <LeftHandle />,
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (type === 'image' || type === 'video' || type === 'audio' || type === 'document' || type === 'podcast') {
    return {
      right: isHovered && <RightHandle />,
      left: isHovered && <LeftHandle />,
    }
  }

  return undefined
}
