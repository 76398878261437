import { useRef, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { doc, collection, setDoc, runTransaction, getDocs, getDoc, Timestamp } from 'firebase/firestore'
import { useLocation } from 'react-router-dom'

import { db } from '@/firebase'

function useUserActivity({ userId, assignmentId, page }) {
  const startTimeRef = useRef(null)
  const sessionDocRef = useRef(null)
  const intervalRef = useRef(null)

  // Create a new session document
  const createSession = async () => {
    const sessionRef = doc(collection(db, 'users', userId, 'sessions'))

    sessionDocRef.current = sessionRef

    try {
      await setDoc(sessionRef, {
        startTime: Timestamp.now(),
        endTime: null,
        totalTime: 0,
        ...(assignmentId && { assignmentId }),
        page,
      })
      // console.log('New session created with ID:', sessionRef.id)
    } catch (error) {
      console.error('Error creating session:', error)
    }
  }

  // Restore sessionDocRef from localStorage if available
  const restoreSession = async () => {
    const sessionId = localStorage.getItem('sessionDocRef')

    if (sessionId) {
      sessionDocRef.current = doc(db, 'users', userId, 'sessions', sessionId)
      // console.log('Restored session with ID:', sessionId)
    }
  }

  // Update session document with cumulative total time
  const updateSession = async (timeSpent, type) => {
    if (!sessionDocRef.current) return

    const sessionRef = sessionDocRef.current

    try {
      await runTransaction(db, async transaction => {
        const sessionDoc = await transaction.get(sessionRef)

        if (!sessionDoc.exists()) {
          console.warn('Session document does not exist.')

          return
        }

        const sessionData = sessionDoc.data()
        const existingTotalTime = sessionData.totalTime || 0

        await transaction.update(sessionRef, {
          ...(type === 'end' && { endTime: Timestamp.now() }),
          totalTime: existingTotalTime + timeSpent,
        })
        // console.log('Session updated with time:', timeSpent)
      })

      await updateUserTotalActiveTime()

      // If session ends, clear the session ID from localStorage
      if (type === 'end') {
        localStorage.removeItem('sessionDocRef')
      }
    } catch (error) {
      // console.error('Error updating session:', error)
    }
  }

  // Update user's total active time by aggregating from sessions
  const updateUserTotalActiveTime = async () => {
    if (!userId) return

    const userDocRef = doc(db, 'users', userId)
    const sessionsRef = collection(db, 'users', userId, 'sessions')

    try {
      await runTransaction(db, async transaction => {
        const userDoc = await transaction.get(userDocRef)

        if (!userDoc.exists()) {
          // console.warn('User document does not exist.')

          return
        }

        const sessionSnapshot = await getDocs(sessionsRef)
        const totalActiveTime = sessionSnapshot.docs.reduce((sum, doc) => {
          const data = doc.data()
          const sessionTotalTime = data.totalTime || 0

          return sum + sessionTotalTime
        }, 0)

        transaction.update(userDocRef, { totalActiveTime })
        // console.log('User total active time updated to:', totalActiveTime)
      })
    } catch (error) {
      // console.error('Error updating user total active time:', error)
    }
  }

  // Handle heartbeat - check and update session
  const handleHeartbeat = async () => {
    if (!userId || !startTimeRef.current || !sessionDocRef.current) return

    const sessionRef = sessionDocRef.current
    const sessionDoc = await getDoc(sessionRef)

    if (!sessionDoc.exists()) return

    const sessionData = sessionDoc.data()
    const timeSpent = (Date.now() - startTimeRef.current) / 1000 // Convert ms to seconds

    if (!sessionData.assignmentId && assignmentId) {
      // console.log(`Ending current session and starting new session for assignment: ${assignmentId}`)
      await updateSession(Math.floor(timeSpent), 'end')
      startTimeRef.current = Date.now() // Reset start time for the new session
      await createSession()
    } else if (sessionData.assignmentId && !assignmentId) {
      // console.log(`Ending assignment session and starting new session without assignment`)
      await updateSession(Math.floor(timeSpent), 'end')
      startTimeRef.current = Date.now() // Reset start time for the new session
      await createSession()
    } else if (sessionData.assignmentId && assignmentId && sessionData.assignmentId === assignmentId) {
      // console.log(`Updating existing session for assignment: ${assignmentId} with time: ${timeSpent} seconds.`)
      await updateSession(Math.floor(timeSpent), 'update')
    } else {
      // console.log(`Updating session with time: ${timeSpent} seconds.`)
      await updateSession(Math.floor(timeSpent), 'update')
    }
  }

  const handleOnActive = async () => {
    if (!userId) return

    if (!startTimeRef.current) {
      await createSession()
      startTimeRef.current = Date.now()
      // console.log('User became active. Start time set to:', startTimeRef.current)
    }
  }

  const handleOnIdle = async () => {
    if (!userId) return

    if (startTimeRef.current) {
      const timeSpent = (Date.now() - startTimeRef.current) / 1000 // Convert ms to seconds

      // console.log(`User became idle. Time spent since last active: ${timeSpent} seconds.`)
      await updateSession(Math.floor(timeSpent), 'end')
      startTimeRef.current = null
    }
  }

  const handleBeforeUnload = () => {
    if (!userId || !sessionDocRef.current) return

    if (startTimeRef.current) {
      const timeSpent = (Date.now() - startTimeRef.current) / 1000 // Convert ms to seconds

      localStorage.setItem('lastActiveTime', Math.floor(timeSpent))
      // console.log(`Before unload: Time spent since last active: ${timeSpent} seconds.`)
    }

    // Store the current session ID in localStorage before the page unloads
    const sessionId = sessionDocRef.current.id

    localStorage.setItem('sessionDocRef', sessionId)
    // console.log(`Before unload: Session ID ${sessionId} saved to localStorage.`)
  }

  const syncLastActiveTime = async () => {
    if (!userId || !sessionDocRef.current) return

    const lastActiveTime = localStorage.getItem('lastActiveTime')

    if (lastActiveTime) {
      const timeSpent = parseInt(lastActiveTime, 10) // Convert stored time to an integer

      // console.log(`Syncing last active time: ${timeSpent} seconds.`)

      try {
        const sessionRef = sessionDocRef.current
        const sessionDoc = await getDoc(sessionRef)

        if (sessionDoc.exists()) {
          const sessionData = sessionDoc.data()
          const existingTotalTime = sessionData.totalTime || 0

          await runTransaction(db, async transaction => {
            await transaction.update(sessionRef, {
              totalTime: existingTotalTime + timeSpent,
              endTime: Timestamp.now(), // Optionally update the end time
            })
          })

          // console.log(
          //   `Last active time successfully synced. Updated total time: ${existingTotalTime + timeSpent} seconds.`,
          // )
        } else {
          // console.warn('Session document does not exist. Unable to sync last active time.')
        }

        // Clean up after syncing
        localStorage.removeItem('lastActiveTime')
        localStorage.removeItem('sessionDocRef')
      } catch (error) {
        // console.error('Error syncing last active time:', error)
      }
    }
  }

  useIdleTimer({
    timeout: 1000 * 60 * 1, // 1 minute
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  })

  useEffect(() => {
    if (!userId) return

    // Restore the session on component mount
    restoreSession()

    intervalRef.current = setInterval(async () => {
      await handleHeartbeat() // Call the heartbeat function to check and update session
    }, 1000 * 60) // Heartbeat every minute

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      clearInterval(intervalRef.current)
      window.removeEventListener('beforeunload', handleBeforeUnload)
      // console.log('Component unmounting. Syncing last active time.')
      syncLastActiveTime() // Sync last active time when the component unmounts
    }
  }, [userId, assignmentId]) // Add assignmentId to dependencies

  return null // No UI elements needed
}

function SessionTimer({ userId }) {
  const location = useLocation() // Get current location
  // Check if the current path matches the assignment page format
  const isAssignmentPage = location.pathname.startsWith('/assignment/')

  // Pass assignmentId only if it's an assignment page
  return useUserActivity({
    userId,
    assignmentId: isAssignmentPage ? location.pathname.split('/')[2] : null,
    page: location.pathname,
  })
}

export default SessionTimer
