/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { ExpandMore } from '@mui/icons-material'

import { PLGChip } from '.'

import { ReactComponent as ArrowCircleUp } from '@/assets/icons/arrow-circle-up.svg'
import PLGAssignmentGrammarInsights from '@/assets/PLG-Banner/PLG-assignment-grammar-insights.gif'
import PLGAssignmentGrammarStandard from '@/assets/PLG-Banner/PLG-assignment-grammar-standard.png'
import PLGAssignmentTranscript from '@/assets/PLG-Banner/PLG-assignment-transcript.gif'
import PLGAssignmentCommentsSuggestions from '@/assets/PLG-Banner/PLG-assignment-comments-suggestion.svg'
import { PLG_INTERFACES } from '@/constants/plans/plg.constants'
import { COMMENTS_PLANS, FEEDBACK_PLANS } from '@/constants/plans/variables-plans'
import { XIcon } from '@/assets/icons/x-icon'
import { setHighlightedPlan, setProductsModalOpen } from '@/Redux/appStateSlice'
import { logOpenScoreCardPLGAccordion } from '@/lib/firebase_analyics/plg'
import { useUserPlan } from '@/hooks/useUserPlan'
import { planTypes } from '@/constants/plans/type-plans'

const PLGViewsFreePlan = [
  {
    title: 'Transcript',
    description:
      'Access detailed transcripts of student responses, making it easier to provide feedback and grade assignments.',
    img: PLGAssignmentTranscript,
    chips: PLG_INTERFACES[FEEDBACK_PLANS.FEEDBACK_TRANSCRIPT].planType,
  },
  {
    title: 'Grammar Insights',
    description:
      'Provide targeted feedback on grammar usage and errors, helping students improve their language skills with precise corrections.',
    img: PLGAssignmentGrammarInsights,
    chips: PLG_INTERFACES[FEEDBACK_PLANS.FEEDBACK_GRAMMAR_INSIGHTS].planType,
  },
  {
    title: 'Grading Standard Estimate (WIDA, ACTFL)',
    description:
      'Receive grading standard estimates aligned with frameworks like WIDA and ACTFL, ensuring your feedback meets recognized educational standards.',
    img: PLGAssignmentGrammarStandard,
    chips: PLG_INTERFACES[FEEDBACK_PLANS.FEEDBACK_GRADING_STANDARDS].planType,
  },
  {
    title: 'Chatty Suggests',
    description:
      'Provide tailored feedback to help you quickly deliver personalized, constructive comments on student work.',
    img: PLGAssignmentCommentsSuggestions,
    chips: PLG_INTERFACES[COMMENTS_PLANS.COMMENTS_SUGGESTIONS].planType,
  },
]

const variants = {
  hidden: { opacity: 0, x: 20 },
  visible: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -20 },
}

export function PLGAssignmentResultsFreePlan() {
  const dispatch = useDispatch()
  const [view, setView] = useState(0)
  const { isFreePlan } = useUserPlan()
  const plg = PLGViewsFreePlan[view]

  return (
    <Stack alignItems="center" gap="12px" maxWidth={600} mx="auto" paddingBlock="16px" width="100%">
      <Box
        sx={{
          position: 'relative',
          borderRadius: '16px',
          padding: '1.5px',
          background: 'linear-gradient(to right, #FF6C6C, #DD7BFF)',
        }}>
        <Accordion
          slotProps={{
            transition: {
              timeout: 600,
            },
          }}
          sx={{
            boxShadow: 'none !important',
            backgroundColor: '#fff',
            borderRadius: '16px !important',
          }}>
          <AccordionSummary
            expandIcon={
              <ExpandMore
                style={{
                  paddingTop: '20px !important',
                }}
              />
            }
            sx={{
              minHeight: '0px !important',
              '& .MuiAccordionSummary-root': {
                minHeight: '0px !important',
              },
              '& .MuiButtonBase-root': {
                minHeight: '0px !important',
              },
              '& .MuiAccordionSummary-content': {
                marginBlock: '10px !important',
              },
            }}
            onClick={() => {
              logOpenScoreCardPLGAccordion()
            }}>
            <Stack direction="row" gap="4px">
              <ArrowCircleUp />
              <Typography>
                {isFreePlan ? 'Activate Auto-Grading' : 'Unlock Grammar Insights, Rubrics, and More!'}
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingTop: '10px !important',
            }}>
            <Stack
              borderRadius="24px"
              direction="row"
              gap="30px"
              justifyContent="center"
              paddingBottom="12px"
              paddingInline="12px"
              width="100%">
              <Stack flexGrow={1} gap="12px" justifyContent="space-evenly" width={230}>
                <Stack gap="12px" mb={5}>
                  <Typography
                    sx={{
                      color: '#000000',
                      fontSize: '18px',
                      lineHeight: '28px',
                      fontWeight: 600,
                    }}>
                    Get Detailed Insights & Feedback Assistance
                  </Typography>
                  <Typography
                    sx={{
                      color: '#101828',
                      fontSize: '14px',
                      lineHeight: '20px',
                    }}>
                    Automate grading to save time, allowing you to spend more time with your students and less on
                    paperwork.
                  </Typography>
                </Stack>
                <Stack gap="12px" justifyContent="center">
                  <Button
                    variant="contained"
                    onClick={() => {
                      dispatch(setProductsModalOpen(true))
                      dispatch(setHighlightedPlan(planTypes.organization))
                    }}>
                    View Plans
                  </Button>
                  <Button
                    href="https://www.speakable.io/instant-feedback"
                    referrerPolicy="no-referrer"
                    target="_blank"
                    variant="text">
                    Learn more
                  </Button>
                </Stack>
              </Stack>
              <Stack gap="12px" justifyContent="center">
                <motion.div
                  key={view}
                  animate="visible"
                  exit="exit"
                  initial="hidden"
                  transition={{ duration: 0.5 }}
                  variants={variants}>
                  <Stack alignItems="center" gap="12px" height={320} width={300}>
                    <img
                      alt={plg.title}
                      src={plg.img}
                      style={{
                        width: '100%',
                        objectFit: 'cover',
                        objectPosition: 'center',
                        borderTopLeftRadius: '12px',
                        borderTopRightRadius: '12px',
                      }}
                    />
                    <Stack alignItems="center" gap="6px" justifyContent="center" paddingInline="24px" paddingTop="18px">
                      <Typography
                        sx={{
                          color: '#101828',
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '28px',
                          textAlign: 'center',
                          maxWidth: '25ch',
                        }}>
                        {plg.title}
                      </Typography>
                      <Stack
                        alignItems="center"
                        direction="row"
                        gap="12px"
                        justifyContent="center"
                        sx={{ width: '330px', padding: '0px 24px' }}>
                        {plg.chips.map(chip => (
                          // @ts-ignore
                          <PLGChip key={chip} type={chip} />
                        ))}
                      </Stack>
                      <Typography
                        sx={{
                          color: '#475467',
                          fontSize: '12px',
                          maxWidth: '320px',
                          textAlign: 'center',
                          textWrap: 'balance',
                        }}>
                        {plg.description}
                      </Typography>
                    </Stack>
                  </Stack>
                </motion.div>
                <Stack alignItems="center" direction="row" gap="12px" justifyContent="center">
                  {PLGViewsFreePlan.map((button, index) => (
                    <Box
                      key={button.title}
                      sx={{
                        borderRadius: '50%',
                        width: '12px',
                        height: '12px',
                        backgroundColor: index === view ? '#EE46BC' : '#dfdfdf',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setView(index)
                      }}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Stack>
  )
}

export function PLGAssignmentResultsProPlan() {
  const [visible, setVisible] = useState(true)

  return (
    <Stack alignItems="center" gap="12px" maxWidth={600} mx="auto" paddingBlock="16px" width="100%">
      {visible ? (
        <Stack border="1px solid #EAECF0" borderRadius="16px" gap="4px" sx={{ p: 2 }}>
          <Stack direction="row" gap="4px" justifyContent="space-between">
            <Stack alignItems="center" direction="row" gap="4px">
              <ArrowCircleUp />
              <Typography
                sx={{
                  color: '#475467',
                  fontSize: '18px',
                  fontWeight: 500,
                }}>
                Unlock Even More with an Organization Plan
              </Typography>
            </Stack>
            <IconButton
              onClick={() => {
                setVisible(false)
              }}>
              <XIcon height={20} width={20} />
            </IconButton>
          </Stack>

          <Stack spacing={2}>
            <Typography
              sx={{
                color: '#475467',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 400,
              }}>
              Take your school&apos;s experience to the next level with enhanced features and seamless management.
            </Typography>

            <Stack direction="row" gap="4px">
              <Button variant="contained">Contact sales</Button>
              <Button
                href="https://www.speakable.io/instant-feedback"
                referrerPolicy="no-referrer"
                target="_blank"
                variant="text">
                Learn more
              </Button>
            </Stack>
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  )
}

export function PLGAssignmentResults({
  isFreePlan,
  isTeacherProPlan,
  isOrganizationPlan,
}: {
  isFreePlan: boolean
  isTeacherProPlan: boolean
  isOrganizationPlan: boolean
}) {
  if (isOrganizationPlan) return null

  if (isFreePlan || isTeacherProPlan) return <PLGAssignmentResultsFreePlan />

  // if (isTeacherProPlan) return <PLGAssignmentResultsProPlan />

  return null
}
