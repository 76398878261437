import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage'

import compressImage from './compress-image'

import { storage } from '@/firebase'

export async function uploadFile(file, path) {
  const compressedFile = await compressImage(file)
  const storageRef = ref(storage, path)

  await uploadBytes(storageRef, compressedFile)
  const downloadUrl = await getDownloadURL(storageRef)
  const storagePath = storageRef.fullPath

  return { url: downloadUrl, storagePath }
}

export const pathUploadImage = (uid, fileName) => `user_data/${uid}/${appendDateToFileName(fileName)}`

export const pathUploadDocument = (uid, fileName) => `user_data/${uid}/${appendDateToFileName(fileName)}`

export const pathUploadAudio = (uid, fileName) => `user_data/${uid}/${appendDateToFileName(fileName)}`

const appendDateToFileName = fileName => {
  const date = new Date().toISOString()

  return `${fileName}_${date}`
}

export async function uploadFileWithProgress({
  file,
  fileName,
  path,
  onUpdateProgress = () => {},
  onFinalize = () => {},
}) {
  const storageRef = ref(storage, `${path}/${Date.now()}_${fileName}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

      onUpdateProgress(progress)
      console.log('Upload is ' + progress + '% done')
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused')
          break
        case 'running':
          console.log('Upload is running')
          break
      }
    },
    error => {
      console.error('Upload failed', error)
    },
    async () => {
      const downloadURL = await getDownloadURL(uploadTask.snapshot.ref)
      const storagePath = storageRef.fullPath

      onFinalize({ url: downloadURL, storagePath })
    },
  )
}
