/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'

import { InputEmbedLink } from '../EmbedContent'

import { YoutubeSearcher } from './YoutubeSearcher'

export function VideoSelector({ handleVideo, handleVideoUrl }) {
  const [selectedTab, setSelectedTab] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <Box
      sx={{
        minWidth: 500,
        maxHeight: '500px',
        overflow: 'auto',
      }}
    >
      <Tabs
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        value={selectedTab}
        variant="fullWidth"
        onChange={handleChangeTab}
      >
        <Tab label="Embed Link" sx={{ fontSize: '12px' }} />
        <Tab label="YouTube" sx={{ fontSize: '12px' }} />
      </Tabs>
      {selectedTab === 0 && (
        <Box sx={{ p: 1, display: 'flex', width: '100%' }}>
          <InputEmbedLink onEmbedLinkUploaded={handleVideoUrl} />
        </Box>
      )}
      {selectedTab === 1 && (
        <Box>
          <YoutubeSearcher onSelectVideo={handleVideo} />
        </Box>
      )}
    </Box>
  )
}
