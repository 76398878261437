import { Box, ClickAwayListener, Tooltip } from '@mui/material'
import { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import { ActionButtonCustomBlock } from './ActionButtonCustomBlock'

import { AlignCenterIcon, AlignLeftIcon, AlignRightIcon } from '@/assets/icons/align-icons'

export function AlignContentCustomBlock({ handleJustifyContent }) {
  const [open, setOpen] = useState(false)

  const onJustifyContent = justifyContent => {
    handleJustifyContent(justifyContent)
    setOpen(false)
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false)
      }}
    >
      <Box>
        <Tooltip
          placement="top"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -10],
                  },
                },
              ],
            },
          }}
          title="Align"
        >
          <div>
            <ActionButtonCustomBlock
              onClick={() => {
                setOpen(true)
              }}
            >
              <AlignCenterIcon />
              <ExpandMoreIcon
                sx={{
                  color: '#fff',
                  fill: '#fff',
                  width: '12px',
                  height: '16px',
                  fontSize: '10px',
                }}
              />
            </ActionButtonCustomBlock>
          </div>
        </Tooltip>
        {open ? (
          <Box
            sx={{
              position: 'absolute',
              top: '28px',
              left: '50%',
              transform: 'translateX(-50%)',
              display: 'flex',
              borderRadius: '4px',
              color: '#fff',
              fill: '#fff',
              fontSize: '11.5px',
              background: 'rgba(0, 0, 0, 0.6)',
              whiteSpace: 'nowrap',
              zIndex: 10,
            }}
          >
            <ActionButtonCustomBlock onClick={() => onJustifyContent('flex-start')}>
              <AlignLeftIcon />
            </ActionButtonCustomBlock>
            <ActionButtonCustomBlock onClick={() => onJustifyContent('center')}>
              <AlignCenterIcon />
            </ActionButtonCustomBlock>
            <ActionButtonCustomBlock>
              <AlignRightIcon onClick={() => onJustifyContent('flex-end')} />
            </ActionButtonCustomBlock>
          </Box>
        ) : null}
      </Box>
    </ClickAwayListener>
  )
}
