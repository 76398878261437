const createCustomError = function (name: string, defaultMessage?: string) {
  return class CustomError extends Error {
    constructor(message?: string) {
      super(defaultMessage ?? message)
      this.name = name
    }
  }
}

export const ItemExistsError = createCustomError('ItemExistsError')

export const ItemNotFoundError = createCustomError('ItemNotFoundError')

export const InternalServerError = createCustomError(
  'InternalServerError',
  'Internal server error. Please try again later.',
)

export const HttpsFirebaseError = createCustomError('HttpsFirebaseError')

export const MissingDataError = createCustomError('MissingDataError')
