export const FEEDBACK_PLANS = {
  FEEDBACK_TRANSCRIPT: 'FEEDBACK_TRANSCRIPT', // Transcript from the audio
  FEEDBACK_SUMMARY: 'FEEDBACK_SUMMARY', // Chatty summary (Free plan)
  FEEDBACK_GRAMMAR_INSIGHTS: 'FEEDBACK_GRAMMAR_INSIGHTS', // Grammar insights
  FEEDBACK_SUGGESTED_RESPONSE: 'FEEDBACK_SUGGESTED_RESPONSE', // Suggested Response
  FEEDBACK_RUBRIC: 'FEEDBACK_RUBRIC', // Suggested Response
  FEEDBACK_GRADING_STANDARDS: 'FEEDBACK_GRADING_STANDARDS', // ACTFL / WIDA Estimate
  FEEDBACK_TARGET_LANGUAGE: 'FEEDBACK_TARGET_LANGUAGE', // Ability to set the feedback language to the target language of the student
  FEEDBACK_DISABLE_ALLOW_RETRIES: 'FEEDBACK_DISABLE_ALLOW_RETRIES', // Turn of allow retries
} as const

export const AUTO_GRADING_PLANS = {
  AUTO_GRADING_PASS_FAIL: 'AUTO_GRADING_PASS_FAIL', // Pass / fail grading
  AUTO_GRADING_RUBRICS: 'AUTO_GRADING_RUBRICS', // Autograded rubrics
} as const

export const COMMENTS_PLANS = {
  COMMENTS_SUGGESTIONS: 'COMMENTS_SUGGESTIONS', // Comment suggestions
} as const

export const AI_ASSISTANT_PLANS = {
  AI_ASSISTANT_DOCUMENT_UPLOADS: 'AI_ASSISTANT_DOCUMENT_UPLOADS', // Allow document uploading
  AI_ASSISTANT_UNLIMITED_USE: 'AI_ASSISTANT_UNLIMITED_USE', // Allow unlimited use of AI assistant. Otherwise, limits are used.
} as const

export const ASSIGNMENT_SETTINGS_PLANS = {
  ASSESSMENTS: 'ASSESSMENTS', // Ability to create assessment assignment types
  GOOGLE_CLASSROOM_GRADE_PASSBACK: 'GOOGLE_CLASSROOM_GRADE_PASSBACK', // Assignment scores can sync with classroom
} as const

export const ANALYTICS_PLANS = {
  ANALYTICS_GRADEBOOK: 'ANALYTICS_GRADEBOOK', // Access to the gradebook page
  ANALYTICS_CLASSROOM_ANALYTICS: 'ANALYTICS_CLASSROOM_ANALYTICS', // Access to the classroom analytics page
  ANALYTICS_STUDENT_PROGRESS_REPORTS: 'ANALYTICS_STUDENT_PROGRESS_REPORTS', // Access to the panel that shows an individual student's progress and assignments
  ANALYTICS_ASSIGNMENT_RESULTS: 'ANALYTICS_ASSIGNMENT_RESULTS', // Access to the assigment RESULTS page
  ANALYTICS_ORGANIZATION: 'ANALYTICS_ORGANIZATION', // Access to the organization analytics panel (for permitted admins)
} as const

export const SPACES_PLANS = {
  SPACES_CREATE_SPACE: 'SPACES_CREATE_SPACE', // Ability to create spaces
  SPACES_CHECK_POINTS: 'SPACES_CHECK_POINTS', // Feature not available yet. Ability to create checkpoints for spaces for data aggregation
} as const

export const DISCOVER_PLANS = {
  DISCOVER_ORGANIZATION_LIBRARY: 'DISCOVER_ORGANIZATION_LIBRARY', // Access to the organizations shared library
} as const

export const INTEGRATIONS_PLANS = {
  INTEGRATIONS_LTI: 'INTEGRATIONS_LTI', //Access to all LTI integrations (Canvas, Blackboard, Schoology, etc)
} as const

export const MEDIA_AREA_PLANS = {
  MEDIA_AREA_DOCUMENT_UPLOAD: 'MEDIA_AREA_DOCUMENT_UPLOAD',
  MEDIA_AREA_AUDIO_FILES: 'MEDIA_AREA_AUDIO_FILES',
} as const
