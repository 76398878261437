import { getDoc } from 'firebase/firestore'

import { handleError } from '@/error/handle-error'
import { mediaAreaRefDoc } from '@/lib/firestore/refs/media-area-firestore-refs'
import { type MediaAreaWithId } from '@/models/media-area/media-area.model'

export async function getMediaArea({ mediaAreaId }: { mediaAreaId: string }) {
  try {
    const ref = mediaAreaRefDoc(mediaAreaId)

    const docSnap = await getDoc(ref)

    if (!docSnap.exists()) return null

    return {
      id: docSnap.id,
      ...docSnap.data(),
    } as MediaAreaWithId
  } catch (error) {
    handleError(error)
  }
}
