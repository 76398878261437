export function AudioIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg fill="none" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M3.14844 7.5L3.14844 10.5M6.52344 8.25V9.75M9.89844 4.5V13.5M13.2734 2.25V15.75M16.6484 7.5V10.5"
        stroke={props.color ?? '#101828'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  )
}
