export const scrollBarStyles = {
	"&::-webkit-scrollbar": {
		width: "4px",
		height: "4px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "rgba(92, 124, 160, 0.10)",
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: "rgba(92, 124, 160, 0.10)",
		borderRadius: "4px",
	},
};
