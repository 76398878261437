/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useMemo, useState } from 'react'
import { Box, Tab, Tabs, CircularProgress } from '@mui/material'

import { InputEmbedLink } from '../EmbedContent'
import RecordButtonSimple from '../RecordButtonSimple'
import { InputFileHidden } from '../shadCN/InputFileHidden'
import { PLGHandler } from '../shadCN/PLGHandler'

import { pathUploadAudio, uploadFile } from '@/utils/upload-file'
import { useAuthUser } from '@/hooks/useUser'
import { MEDIA_AREA_PLANS } from '@/constants/plans/variables-plans'
import { withPermissionCheck } from '@/hoc/WithPermissionCheck'

export function AudioSelector({ handleAudioUrl }) {
  const userAuth = useAuthUser()
  const [selectedTab, setSelectedTab] = useState(0)
  const [audio, setAudio] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleUploadAudio = async e => {
    setIsLoading(true)
    const file = e.target.files[0]

    const path = pathUploadAudio(userAuth.uid, file.name)

    const { url } = await uploadFile(file, path)

    handleAudioUrl(url)

    setIsLoading(false)
  }

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue)
  }

  const onCancelRecording = () => {
    setAudio(null)
  }

  const onSaveRecording = audio => {
    setAudio(audio)
    handleAudioUrl(audio.url)
  }

  return (
    <Box
      sx={{
        minWidth: 500,
        maxHeight: '500px',
        overflow: 'auto',
      }}>
      <Tabs
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        value={selectedTab}
        variant="fullWidth"
        onChange={handleChangeTab}>
        <Tab label="Record" sx={{ fontSize: '12px' }} />
        <Tab label="Embed Link" sx={{ fontSize: '12px' }} />
        <Tab label="Upload" sx={{ fontSize: '12px' }} />
      </Tabs>
      {selectedTab === 0 && (
        <Box sx={{ p: 2, display: 'flex', width: '100%' }}>
          <RecordButtonSimple
            audioUrl={audio?.url}
            maxDuration={60}
            onCancelRecording={onCancelRecording}
            onSaveRecording={onSaveRecording}
          />
        </Box>
      )}
      {selectedTab === 1 && (
        <Box sx={{ p: 2, display: 'flex', width: '100%' }}>
          <InputEmbedLink onEmbedLinkUploaded={handleAudioUrl} />
        </Box>
      )}
      {selectedTab === 2 && (
        <UploadButtonWithPermission
          requiredPermission={MEDIA_AREA_PLANS.MEDIA_AREA_AUDIO_FILES}
          onChange={handleUploadAudio}
        />
      )}
      {isLoading ? (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </Box>
      ) : null}
    </Box>
  )
}

function HOCInputFileHidden({ onChange, disabled, handleOpenPopover }) {
  return (
    <Box sx={{ p: 2, display: 'flex', width: '100%', gap: '4px', alignItems: 'center' }}>
      <InputFileHidden disabled={disabled} onChange={onChange} />
      <PLGHandler visible={disabled} onMouseEnter={handleOpenPopover} />
    </Box>
  )
}

function UploadButtonWithPermission({ requiredPermission, ...props }) {
  const Component = useMemo(() => withPermissionCheck(HOCInputFileHidden, requiredPermission), [requiredPermission])

  return <Component {...props} />
}
