/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
import { insertOrUpdateBlock } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'
import { Box, Popover } from '@mui/material'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA, CUSTOM_BLOCKS_GROUP_MEDIA_AREA } from '../custom-blocks-types-media-area'
import { type Editor } from '../media-area-schema'

import { CardPreviewBlock } from './CardPreviewBlock'
import { ContainerCustomBlock } from './ContainerCustomBlock'
import { CustomBlockIcons } from './IconsCustomBlocks'

import { usePopover } from '@/hooks/ui/usePopover'
import { POPOVER_POSITIONS } from '@/constants/popover.constants'
import { InputEmbedLink } from '@/components/EmbedContent'

export const WebCustomBlock = createReactBlockSpec(
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_WEB,
    content: 'none',
    propSchema: {
      url: {
        default: '',
      },
      width: {
        default: 0,
      },
      height: {
        default: 0,
      },
      justifyContent: {
        default: 'center',
        values: ['flex-start', 'center', 'flex-end'],
      },
    },
  },
  {
    render: props => {
      const { justifyContent, url: webUrl, width, height } = props.block.props

      const initialSize = {
        width,
        height,
      }

      const handleUpdateBlock = (url: string) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            url,
          },
        })
      }

      const removeBlock = () => {
        props.editor.removeBlocks([props.block.id])
      }

      const handleResize = (width: number, height: number) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            width,
          },
        })
      }

      const handleJustifyContent = (place: 'flex-start' | 'center' | 'flex-end') => {
        props.editor.updateBlock(props.block.id, {
          props: {
            justifyContent: place,
          },
        })
      }

      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <WebUI
            handleJustifyContent={handleJustifyContent}
            handleResize={handleResize}
            handleUpdateBlock={handleUpdateBlock}
            initialSize={initialSize.width ? initialSize : null}
            isEditable={props.editor.isEditable}
            justifyContent={justifyContent}
            removeBlock={removeBlock}
            webUrl={webUrl.length > 0 ? webUrl : null}
          />
        </div>
      )
    },
  },
)

function WebUI({
  webUrl = null,
  handleUpdateBlock,
  removeBlock,
  handleResize,
  justifyContent,
  handleJustifyContent,
  initialSize,
  isEditable,
}: {
  webUrl: string | null
  handleUpdateBlock: (url: string) => void
  justifyContent: 'flex-start' | 'center' | 'flex-end'
  removeBlock: () => void
  handleResize: (width: number, height: number) => void
  handleJustifyContent: (place: 'flex-start' | 'center' | 'flex-end') => void
  initialSize: {
    width: number
    height: number
  } | null
  isEditable: boolean
}) {
  const { anchorEl, handleClosePopover, handleOpenPopover, refPopoverTrigger } = usePopover({
    showOnMount: isEditable,
  })

  const open = Boolean(anchorEl)

  return (
    <>
      {webUrl == null ? (
        <>
          <CardPreviewBlock
            ref={refPopoverTrigger}
            actions={{
              onDelete: removeBlock,
            }}
            icon={CustomBlockIcons.WebBlockIcon}
            showActions={isEditable}
            onClick={handleOpenPopover}
          >
            URL from the site
          </CardPreviewBlock>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={POPOVER_POSITIONS.bottom.anchorOrigin}
            // eslint-disable-next-line react/jsx-no-leaked-render
            open={open && isEditable}
            transformOrigin={POPOVER_POSITIONS.bottom.transformOrigin}
            onClose={handleClosePopover}
          >
            <Box
              sx={{
                borderRadius: 1,
                border: '1px solid rgba(0,0,0,0.08)',
                boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
                padding: 1,
                transform: 'scale(.9)',
              }}
            >
              <InputEmbedLink onEmbedLinkUploaded={handleUpdateBlock} />
            </Box>
          </Popover>
        </>
      ) : (
        <ContainerCustomBlock
          handleJustifyContent={handleJustifyContent}
          handleResize={handleResize}
          initialSize={initialSize ?? undefined}
          justifyContent={justifyContent}
          type="web"
        >
          <iframe height="100%" src={webUrl} title="Website" width="100%" />
        </ContainerCustomBlock>
      )}
    </>
  )
}

export const insertWebCustomBlock = (editor: Editor) => ({
  title: 'Web',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      // @ts-ignore
      type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_WEB,
    })
  },
  aliases: ['web', 'website', 'url'],
  group: CUSTOM_BLOCKS_GROUP_MEDIA_AREA.ENTERTAINMENT,
  icon: <CustomBlockIcons.WebBlockIcon height={25} width={25} />,
})
