/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-shadow */
import { createReactBlockSpec } from '@blocknote/react'
import { Box, IconButton, Stack, TextField, alpha } from '@mui/material'
import { insertOrUpdateBlock } from '@blocknote/core'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA, CUSTOM_BLOCKS_GROUP_MEDIA_AREA } from '../custom-blocks-types-media-area'
import { type Editor } from '../media-area-schema'
import { useMediaAreaContext } from '../MediaArea'

import { CustomBlockIcons } from './IconsCustomBlocks'

import tts from '@/utils/textToSpeech'
import { VolumeMaxIcon } from '@/assets/icons/volume-max'
import { ReactComponent as PauseIcon } from '@/assets/icons/pause.svg'
import usePollyTextToSpeech from '@/utils/amazonPollyTextToSpeech'
import HighlightCardText from '@/components/HighlightCardText'

export const TextToSpeechCustomBlock = createReactBlockSpec(
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT_TO_SPEECH,
    content: 'none',
    propSchema: {
      language: {
        default: '',
      },
      text: {
        default: '',
      },
    },
  },
  {
    render: props => {
      const { language, text } = props.block.props

      const handleUpdateBlock = (args: { text?: string; language?: string }) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            ...args,
          },
        })
      }

      return (
        <TextToSpeechUI
          handleUpdateBlock={handleUpdateBlock}
          isEditable={props.editor.isEditable}
          language={language}
          text={text}
        />
      )
    },
  },
)

function TextToSpeechUI({
  language,
  text,
  handleUpdateBlock,
  isEditable,
}: {
  text: string
  language: string
  handleUpdateBlock: (args: { text?: string; language?: string }) => void
  isEditable: boolean
}) {
  const { pollyTextToSpeech, playing, currentWord, pause, resume, paused } = usePollyTextToSpeech(text)
  const { isMobileApp } = useMediaAreaContext()

  const handleStartTTS = () => {
    if (playing && !paused) {
      pause()

      return
    }
    if (playing && paused) {
      resume()

      return
    }

    if (isMobileApp) {
      tts({
        text: text ?? '',
        language: language,
        slow: false,
        speed: 1,
      })
    } else {
      pollyTextToSpeech({
        // @ts-ignore
        lang: language,
        // @ts-ignore
        speechText: text ?? '',
      })
    }
  }

  return (
    <Stack alignItems="flex-start" direction="row" gap={1} paddingInline={1.4} width="100%">
      <IconButton
        disabled={text.length === 0}
        size="small"
        sx={{
          border: '1px solid #EE46BC',
          background: '#F670C7',
          '&:hover': {
            background: alpha('#F670C7', 0.8),
          },
          padding: '6px',
          '&:disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
            background: '#F670C7',
          },
        }}
        onClick={handleStartTTS}
      >
        {playing && !paused ? <PauseIcon height={20} width={20} /> : null}
        {paused ? <VolumeMaxIcon height={20} width={20} /> : null}
        {!playing && !paused ? <VolumeMaxIcon height={20} width={20} /> : null}
      </IconButton>
      {isEditable ? (
        <TextField
          autoFocus
          multiline
          placeholder="Type or paste text here"
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-root': {
              border: 'none !important',
              boxShadow: 'none !important',
              paddingLeft: '0px !important',
              paddingTop: '5px !important',
              '& fieldset': {
                boxShadow: 'none !important',
                border: 'none !important',
              },
            },
          }}
          value={text}
          onChange={e => {
            handleUpdateBlock({
              text: e.target.value,
            })
          }}
        />
      ) : (
        <Box>
          <HighlightCardText
            currentWord={currentWord}
            isMicOn={undefined}
            language={language}
            textStyles={{
              fontSize: '14px',
              lineHeight: '18px',
              color: '#344054',
            }}
          >
            {text}
          </HighlightCardText>
        </Box>
      )}
    </Stack>
  )
}

export const insertTextToSpeechCustomBlock = (editor: Editor) => ({
  title: 'Text to Speech',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      // @ts-ignore
      type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_TEXT_TO_SPEECH,
    })
  },
  aliases: ['text-to-speech', 'tts', 'speech'],
  group: CUSTOM_BLOCKS_GROUP_MEDIA_AREA.MEDIA,
  icon: <CustomBlockIcons.TextBlockIcon height={25} width={25} />,
  subtext: 'Add TTS experience',
})
