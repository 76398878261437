/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  ImageList,
  ImageListItem,
  InputLabel,
  OutlinedInput,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { Search } from '@mui/icons-material'

import { useAuthUser } from '@/hooks/useUser'
import useUnsplash from '@/hooks/useUnsplash'
import { pathUploadImage, uploadFile } from '@/utils/upload-file'
import useKeyPress from '@/utils/useKeyPress'
import { getGoogleImageSearchResults } from '@/utils/GoogleImageSearch'
import { HyperlinkIcon } from '@/assets/icons/hyperlink-icon'
import { ReactComponent as GoogleIcon } from '@/assets/icons/google-black.svg'
import { ReactComponent as UnsplashIcon } from '@/assets/icons/unsplash-black.svg'

export function ImageSelector({
  onClickImage,
}: {
  onClickImage: (images: { url: string; path: string | null }[]) => void
}) {
  const { uid } = useAuthUser()
  const [loading, setLoading] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const { searchImages } = useUnsplash()
  const [selectedTab, setSelectedTab] = useState(0)
  const [imageUrls, setImageUrls] = useState<{ url: string; path: string | null }[]>([])
  const [page, setPage] = useState(0)
  const [prevQuery, setPrevQuery] = useState('')

  const loadMoreImages = () => {
    if (selectedTab === 0) handleSearchGoogle()
    if (selectedTab === 1) handleSearchUnsplash()
  }

  const handleUploadImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    setLoadingUpload(true)

    const file = e.target.files[0]
    const path = pathUploadImage(uid, file.name)

    const { storagePath, url } = await uploadFile(file, path)

    setLoadingUpload(false)
    onClickImage([{ url, path: storagePath }])
  }

  const handleSearchUnsplash = async () => {
    let nextPage = page

    setLoading(true)
    const $input = document.getElementById('image-selector_search_input') as HTMLInputElement

    const searchTerm = $input.value

    if (prevQuery !== searchTerm) {
      setPage(1)
      setImageUrls([])
      nextPage = 1
    }

    const images = await searchImages(searchTerm, nextPage)

    const urls = images.map(image => ({
      url: image.urls.regular,
      path: null,
      authorName: `${image.user.first_name} ${image.user.last_name ?? ''}`,
      authorUrl: image.user.links.html,
    }))

    setImageUrls(prev => [...prev, ...urls])

    setTimeout(() => {
      setLoading(false)

      setPage(prev => prev + 1)
      setPrevQuery(searchTerm)
    }, 1000)
  }

  const handleSearchGoogle = async () => {
    let nextPage = page

    setLoading(true)
    const $input = document.getElementById('image-selector_search_input') as HTMLInputElement

    const searchTerm = $input.value

    if (!searchTerm) {
      setLoading(false)

      return
    }
    if (prevQuery !== searchTerm) {
      setPage(1)
      setImageUrls([])
      nextPage = 1
    }
    const urls = await getGoogleImageSearchResults(searchTerm, nextPage)

    setImageUrls(prev => [...prev, ...(urls || [])])
    setTimeout(() => {
      setLoading(false)
      setPage(prev => prev + 1)
      setPrevQuery(searchTerm)
    }, 1000)
  }

  const handleChangeTab = (newValue: number) => {
    setSelectedTab(newValue)
    setPage(1)
    setImageUrls([])
  }
  const handleImageClick = (image: { url: string; path: string | null }) => {
    onClickImage([image])
    setSelectedTab(0)
    setImageUrls([])
  }

  useKeyPress(['Enter'], (e: React.KeyboardEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (selectedTab === 0) handleSearchGoogle()
    if (selectedTab === 1) handleSearchUnsplash()
  })

  return (
    <Stack
      direction="row"
      gap={1}
      height={400}
      pt={1}
      sx={{
        flex: '1 1 auto',
      }}
      width="100%"
    >
      <Stack justifyContent="space-between">
        <Tabs
          orientation="vertical"
          sx={{ height: '200px' }}
          value={selectedTab}
          onChange={(_, newValue: number) => {
            handleChangeTab(newValue)
          }}
        >
          <Tab
            icon={<GoogleIcon style={{ margin: '0' }} width={16} />}
            label="Google"
            sx={{
              fontSize: '12px',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              textTransform: 'none',
              minHeight: '50px',
            }}
          />
          <Tab
            icon={<UnsplashIcon style={{ margin: '0' }} width={16} />}
            label="Unsplash"
            sx={{
              fontSize: '12px',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              textTransform: 'none',
              minHeight: '50px',
            }}
          />
          <Tab
            icon={<HyperlinkIcon style={{ margin: '0' }} width={19} />}
            label="Paste an image url"
            sx={{
              fontSize: '12px',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              textTransform: 'none',
              minHeight: '50px',
              textWrap: 'nowrap',
            }}
          />
        </Tabs>
        <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
          <Button
            component="label"
            disabled={loadingUpload}
            size="small"
            startIcon={loadingUpload ? <CircularProgress color="inherit" size={20} /> : null}
            sx={{
              paddingInline: '20px',
              textWrap: 'nowrap',
            }}
            variant="contained"
          >
            Upload Image
            <input hidden accept="image/*" disabled={loadingUpload} type="file" onChange={handleUploadImage} />
          </Button>
        </Box>
      </Stack>
      <Stack sx={{ width: '100%', flex: '1 1 auto' }}>
        {selectedTab === 0 || selectedTab === 1 ? (
          <Box sx={{ p: 2, display: 'flex' }}>
            <FormControl fullWidth variant="outlined">
              <InputLabel
                htmlFor="image-selector_search_input"
                sx={{
                  fontSize: '12px',
                  padding: '0px',
                }}
              >
                Search for images
              </InputLabel>
              <OutlinedInput
                autoFocus
                endAdornment={
                  <IconButton color="secondary" onClick={handleSearchGoogle}>
                    <Search sx={{ fontSize: '20px', color: '#344054' }} />
                  </IconButton>
                }
                id="image-selector_search_input"
                label="Search Images on Google"
                sx={{
                  fontSize: '12px',
                  paddingRight: '5px',
                }}
              />
            </FormControl>
          </Box>
        ) : null}
        {selectedTab === 2 && (
          <Box sx={{ p: 2 }}>
            <TextField
              autoFocus
              fullWidth
              id="image-url-input"
              label="Image URL"
              sx={{
                '& > *': {
                  fontSize: '12px',
                },
              }}
              variant="outlined"
              onChange={e => {
                setImageUrls([{ url: e.target.value, path: null }])
              }}
            />
          </Box>
        )}
        {imageUrls.length > 0 && (
          <Box sx={{ p: 2, overflowY: 'auto', height: '100%' }}>
            <ImageList cols={3} gap={8} variant="masonry">
              {imageUrls.map(image => (
                <ImageListItem key={image.url}>
                  {loading ? (
                    <Skeleton animation="wave" height="200px" sx={{ borderRadius: 2 }} variant="rectangular" />
                  ) : (
                    <img
                      alt="selected"
                      loading="eager"
                      src={`${image.url}?w=248&fit=crop&auto=format`}
                      srcSet={`${image.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      style={{
                        cursor: 'pointer',
                        borderRadius: 8,
                      }}
                      onClick={() => {
                        handleImageClick(image)
                      }}
                      onError={e => {
                        e.currentTarget.style.display = 'none'
                      }}
                    />
                  )}
                </ImageListItem>
              ))}
            </ImageList>
            {selectedTab !== 2 && (
              <Button color="secondary" onClick={loadMoreImages}>
                Load more
              </Button>
            )}
          </Box>
        )}
      </Stack>
    </Stack>
  )
}
