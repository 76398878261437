export default function textToSpeech({ text, language, slow, speed }) {
  let speech = window.speechSynthesis;

  if (speech.speaking) {
    speech.cancel();
    return;
  }
  const storedVoice = localStorage.getItem(language);
  const voices = speech.getVoices().filter((v) => v.lang.includes(language));
  const voice = voices.find((v) => v.name === storedVoice);
  let msg = new SpeechSynthesisUtterance();

  msg.lang = language;
  msg.voice = storedVoice && voice ? voice : voices[0] || null;
  if (speed) {
    msg.rate = speed;
  } else if (slow) {
    msg.rate = 0.5;
  } else {
    msg.rate = 0.7;
  }
  msg.volume = 1.0;
  msg.pitch = 1;
  const wordsArray = text && text.split("+");
  if (text && wordsArray.length === 1) {
    msg.text = removeParentheses(text);
    speech.cancel();
    speech.speak(msg);
  } else if (text && wordsArray.length > 1) {
    msg.text = wordsArray.join(", ");
    speech.cancel();
    speech.speak(msg);
  }

  msg.onend = function () {
    speech.cancel();
  };

  msg.onerror = function () {
    speech.cancel();
  };
  return msg;
}

const removeParentheses = (text) => {
  if (!text) return "";
  const regex = /\([^()]*\)/g;
  const cleaned = text.replace(regex, "");
  if (cleaned) {
    return cleaned;
  } else {
    return text;
  }
};
