import { PLGPopover } from '@/components/PLG'
import { PLG_INTERFACES, type PLGInterface } from '@/constants/plans/plg.constants'
import { usePopover } from '@/hooks/ui/usePopover'
import { useCheckPermissions } from '@/hooks/useCheckPermissions'

interface WrappedComponentProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  disabled: boolean
  handleOpenPopover: (event: React.MouseEvent<HTMLElement>) => void
  handleClosePopover: () => void
}

export const withPermissionCheck = (
  WrappedComponent: React.FC<WrappedComponentProps>,
  requiredPermission: string | string[],
  customPLG?: PLGInterface,
) => {
  return function (props: { ignorePermissions?: boolean; onClick?: (event: React.MouseEvent<HTMLElement>) => void }) {
    const { anchorEl, handleClosePopover, handleOpenPopover, preferredPlacement } = usePopover({ showOnMount: false })
    const plgPermission = typeof requiredPermission === 'string' ? PLG_INTERFACES[requiredPermission] : null

    const { ignorePermissions = false, onClick } = props

    const { hasPermission } = useCheckPermissions({ requiredPermission, ignorePermissions })

    const openPopover = Boolean(anchorEl)

    const plgToShow = plgPermission ?? customPLG ?? null

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      if (!hasPermission) {
        handleOpenPopover(event)
      } else {
        onClick?.(event)
      }
    }

    return (
      <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
        <WrappedComponent
          {...props}
          disabled={!hasPermission}
          handleClosePopover={handleClosePopover}
          handleOpenPopover={handleOpenPopover}
          onClick={handleClick}
        />
        {plgToShow?.contentType === 'popover' && (
          <PLGPopover
            anchorEl={anchorEl}
            handleClosePopover={handleClosePopover}
            openPopover={openPopover}
            placement={preferredPlacement}
            plgPermission={plgToShow}
          />
        )}
      </div>
    )
  }
}
