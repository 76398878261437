const googleTranslateApiKey = import.meta.env.VITE_GOOGLE_TRANSLATE_API;

function decodeHTMLEntities(text) {
  const textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

/**
 * Translates the given text using Google Translate API
 * @param {string} text - The text to be translated
 * @param {string} targetLanguage - The target language for translation
 * @param {string} [sourceLanguage='auto'] - The source language (optional, defaults to 'auto')
 * @param {function} callback - The callback function to handle the result
 */
async function translate(
  text,
  targetLanguage,
  sourceLanguage = "auto",
  callback
) {
  // Return the text as it is if targetLanguage and sourceLanguage are equal
  if (targetLanguage === sourceLanguage) {
    callback(null, { translatedText: text });
    return;
  }
  const url = `https://translation.googleapis.com/language/translate/v2?key=${googleTranslateApiKey}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        q: text,
        target: sourceLanguage,
        source: targetLanguage,
      }),
    });

    const data = await response.json();

    if (data.error) {
      callback(
        new Error(`Error translating text: ${data.error.message}`),
        null
      );
    } else {
      const decodedTranslation = decodeHTMLEntities(
        data.data.translations[0].translatedText
      );
      callback(null, {
        ...data.data.translations[0],
        translatedText: decodedTranslation,
      });
    }
  } catch (error) {
    callback(error, null);
  }
}

async function detectLanguage(text, returnFullName = false) {
  const url = `https://translation.googleapis.com/language/translate/v2/detect?key=${googleTranslateApiKey}`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      q: text,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (returnFullName) {
        return data.data.detections[0][0].languageName;
      } else {
        return data.data.detections[0][0].language;
      }
    })
    .catch((error) => {
      console.error("Error detecting language:", error);
    });
}

const googleTranslate = { translate, detectLanguage };

export default googleTranslate;
