import { forwardRef, useState } from 'react'
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import { usePopover } from '@/hooks/ui/usePopover'
import { POPOVER_POSITIONS } from '@/constants/popover.constants'
import { scrollBarStyles } from '@/assets/css/scrollbar'

const listDotActions = [
  {
    label: 'Delete',
    icon: <DeleteOutlineIcon />,
  },
]

export const CardPreviewBlock = forwardRef(
  (
    {
      onClick,
      icon: Icon,
      children,
      actions,
      showActions = true,
    }: {
      onClick: (e: React.MouseEvent<HTMLElement>) => void
      icon?: any
      children: string
      actions?: {
        onDelete?: () => void
      }
      showActions?: boolean
    },
    ref,
  ) => {
    const [isHovered, setIsHovered] = useState(false)
    const { anchorEl, handleOpenPopover, handleClosePopover } = usePopover({ showOnMount: true })

    const handleAction = (label: string) => {
      if (label === 'Delete') {
        actions?.onDelete?.()
      }

      handleClosePopover()
    }

    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '0.875rem',
          color: '#666',
          cursor: 'pointer',
          alignItems: 'center',
          padding: 2,
          backgroundColor: '#f5f5f5',
          transition: 'background-color 100ms',
          borderRadius: 1.5,
          '&:hover': {
            backgroundColor: '#e0e0e0',
          },
        }}
        onClick={e => {
          if (showActions) {
            onClick(e)
          }
        }}
        onMouseEnter={() => {
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
        }}
      >
        <Box alignItems="center" display="flex" gap={1}>
          {Icon ? <Icon height="25px" width="25px" /> : null}
          <Typography
            sx={{
              fontSize: '12px',
              textWrap: 'nowrap',
            }}
          >
            {children}
          </Typography>
        </Box>
        {showActions ? (
          <Button
            sx={{
              width: 'fit-content !important',
              alignItems: 'center',
              padding: '2px 2px',
              cursor: 'pointer',
              background: 'transparent',
              color: 'rgba(55, 53, 47, 0.45)',
              border: 'none',
              borderRadius: '4px',
              fontSize: '11.5px',
              whiteSpace: 'nowrap',
              height: '26px',
              overflow: 'hidden',
              transition: 'background 100ms ease-in',
              zIndex: 10,
              opacity: isHovered ? 1 : 0,
              ':hover': {
                background: 'rgba(55, 53, 47, 0.10)',
              },
            }}
            type="button"
            onClick={e => {
              e.stopPropagation()
              handleOpenPopover(e)
            }}
          >
            <MoreHorizIcon />
          </Button>
        ) : null}
        <Popover
          onClose={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation()
            handleClosePopover()
          }}
          {...POPOVER_POSITIONS.top}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          sx={{
            '& .MuiPaper-root': {
              width: '190px',
              padding: '6px 2px',
              borderRadius: '5px',
              background: '#FFF',
              border: '1px solid rgba(0,0,0,0.08)',
              boxShadow:
                'rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px',
              ...scrollBarStyles,
              maxHeight: '400px',
            },
          }}
        >
          <List disablePadding>
            {listDotActions.map(action => (
              <ListItem
                key={action.label}
                disablePadding
                sx={{
                  '&:hover': {
                    color: '#ef4444',
                  },
                  '&:hover .MuiListItemIcon-root': {
                    color: '#ef4444',
                  },
                }}
              >
                <ListItemButton
                  sx={{
                    padding: '2px 6px',
                  }}
                  onClick={e => {
                    e.stopPropagation()
                    handleAction(action.label)
                  }}
                >
                  <ListItemIcon
                    sx={{
                      width: '20px',
                      minWidth: '0px',
                      marginRight: '12px',
                    }}
                  >
                    {action.icon}
                  </ListItemIcon>
                  <ListItemText>{action.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Popover>
      </Box>
    )
  },
)
