import { Stack, Typography } from '@mui/material'
import { planTypes } from '@/constants/plans/type-plans'
import { ReactComponent as OrgIcon } from '@/assets/icons/org-blue.svg'
import { ReactComponent as TeacherProIcon } from '@/assets/icons/teacher-pro-stars.svg'
import { ReactComponent as BasicPlanIcon } from '@/assets/icons/basic-plan-heart.svg'

import { useUserPlan } from '@/hooks/useUserPlan'

const variants = {
  [planTypes.basic]: {
    gradientBorder: 'linear-gradient(90deg, rgba(252, 203, 144, 1), rgba(213, 126, 235, 1))', // Basic gradient
    bgcolor: 'rgba(253, 242, 250, 1)',
    color: '#C11574',
    icon: BasicPlanIcon,
    label: 'Free',
  },
  [planTypes.teacher_pro]: {
    gradientBorder: 'linear-gradient(90deg, #7579FF 0%, #B224EF 100%)', // Teacher Pro gradient
    bgcolor: 'rgba(244, 243, 255, 1)',
    color: 'rgba(89, 37, 220, 1)',
    icon: TeacherProIcon,
    label: 'Teacher Pro',
  },
  [planTypes.organization]: {
    gradientBorder: 'linear-gradient(90deg, #89F7FE 0%, #66A6FF 100%)', // Organization gradient
    bgcolor: 'rgba(239, 248, 255, 1)',
    color: '#026AA2',
    icon: OrgIcon,
    label: 'Organization',
  },
}

export function PLGChip({
  type,
  isButton = false,
  onClick = () => null,
}: {
  type: keyof typeof planTypes
  isButton?: boolean
  onClick?: () => void
}) {
  // const { checkIsTeacherProPlan } = useUserPlan()

  // const isTeacher = checkIsTeacherProPlan(type)
  let variant = null
  if (type === planTypes.starter) {
    variant = variants[planTypes.teacher_pro]
  } else if (type === planTypes.growth || type === planTypes.professional) {
    variant = variants[planTypes.organization]
  } else {
    variant = variants?.[type] ?? variants[planTypes.basic]
  }
  return (
    <Stack
      onClick={() => {
        if (!isButton) return
        onClick()
      }}
      sx={{
        background: variant.gradientBorder, // Apply gradient to the outer wrapper
        borderRadius: isButton ? '18px' : '9999px', // Circular border
        padding: '2px', // Padding to create space for the gradient border
        display: 'inline-block',
        cursor: isButton ? 'pointer' : 'default',
      }}>
      <Stack
        alignItems="center"
        borderRadius={isButton ? '16px' : '9999px'}
        direction="row"
        gap="4px"
        px={isButton ? '14px' : '8px'}
        py={isButton ? '10px' : '2px'}
        sx={{
          backgroundColor: isButton ? 'white' : variant.bgcolor, // Inner background color
        }}
        width="fit-content">
        <variant.icon
          style={{
            height: isButton ? '20px' : '14px',
            width: isButton ? '20px' : '14px',
          }}
        />
        <Typography color={variant.color} fontSize={isButton ? '14px' : '12px'} fontWeight={500} textAlign="center">
          {variant.label}
        </Typography>
      </Stack>
    </Stack>
  )
}
