import { isSafari } from './browser-type'

const checkMicPermissions = (noMicPermissions = () => {}) => {
  const navigator = window.navigator

  if (!navigator || !navigator.permissions) {
    return
  }

  return navigator.permissions.query({ name: 'microphone' }).then(status => {
    if (status.state === 'granted') {
      return true
    } else {
      // eslint-disable-next-line no-unused-expressions
      navigator &&
        navigator.mediaDevices &&
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => {
            if (!isSafari) {
              window.location.reload()
            }

            return true
          })
          .catch(() => {
            noMicPermissions()
            alert(
              'Your microphone is currently off! Please turn it on by clicking on the warning (or lock) icon on the left side of the URL bar and toggling the "Microphone" to the on position.',
            )

            return false
          })
    }
  })
}

export default checkMicPermissions
