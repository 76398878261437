const cleanString = (words) => {
	const splitWords = words && words.split("+");

	if (splitWords && splitWords.length === 1) {
		const newWord = purify(words);
		return newWord;
	} else if (splitWords && splitWords.length > 1) {
		const split = splitWords.map((w) => purify(w));
		return split;
	} else {
		return "";
	}
};

const cleanNumberString = (words, lang) => {
	if (!words) return;
	if (lang === "es") {
		return words.replace(/y/g, "");
	} else if (lang === "fr") {
		return words.replace(/et/g, "");
	} else if (lang === "en") {
		return words.replace(/\b(?!thous)and\b/g, "");
	} else {
		return words;
	}
};

const purify = (word) => {
	return word
		.normalize("NFD")
		.replace(/\/([^" "]*)/g, "") // removes word after slash to space  el/la --> el
		.replace(/\([^()]*\)/g, "") // removes word parenthesis
		.replace(/（[^()]*）/g, "") //removes chinese parenthesis
		.replace(/[\u0300-\u036f]/g, "") // removes diacritics
		.replace(/[-]/g, " ") // removes hyphens
		.replace(/[.,/#!¡¿？؟。，.?$%^&*;:{}=\-_`~()’'…\s]/g, "") // removes punctuation
		.replace(/\s\s+/g, " ") //Removes all whitespace
		.toLowerCase()
		.trim();
};
export { purify, cleanNumberString };
export default cleanString;
