/* eslint-disable @typescript-eslint/no-shadow */
import { insertOrUpdateBlock } from '@blocknote/core'
import { createReactBlockSpec } from '@blocknote/react'

import { CUSTOM_BLOCKS_TYPES_MEDIA_AREA, CUSTOM_BLOCKS_GROUP_MEDIA_AREA } from '../custom-blocks-types-media-area'
import { type Editor } from '../media-area-schema'

import { CardPreviewBlock } from './CardPreviewBlock'
import { CustomBlockIcons } from './IconsCustomBlocks'

import AudioPlayer from '@/components/TimelineWaveForm'
import { usePopover } from '@/hooks/ui/usePopover'
import { AudioSelectorPopover } from '@/components/AudioSelector'

export const AudioCustomBlock = createReactBlockSpec(
  {
    type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_AUDIO,
    content: 'none',
    propSchema: {
      audioUrl: {
        default: '',
      },
    },
  },
  {
    render: props => {
      const audioUrl = props.block.props.audioUrl

      const handleUpdateBlock = (audioUrl: string) => {
        props.editor.updateBlock(props.block.id, {
          props: {
            audioUrl,
          },
        })
      }

      const removeBlock = () => {
        props.editor.removeBlocks([props.block.id])
      }

      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}>
          <AudioUI
            audioUrl={audioUrl.length > 0 ? audioUrl : null}
            handleUpdateBlock={handleUpdateBlock}
            isEditable={props.editor.isEditable}
            removeBlock={removeBlock}
          />
        </div>
      )
    },
  },
)

function AudioUI({
  handleUpdateBlock,
  audioUrl,
  removeBlock,
  isEditable,
}: {
  audioUrl: string | null
  handleUpdateBlock: (audioUrl: string) => void
  removeBlock: () => void
  isEditable: boolean
}) {
  const { anchorEl, handleClosePopover, handleOpenPopover, refPopoverTrigger, preferredPlacement } = usePopover({
    showOnMount: isEditable,
  })

  const onSaveRecording = (audioUrl: string) => {
    handleUpdateBlock(audioUrl)
  }

  return (
    <>
      {audioUrl == null ? (
        <>
          <CardPreviewBlock
            ref={refPopoverTrigger}
            actions={{
              onDelete: removeBlock,
            }}
            icon={CustomBlockIcons.AudioBlockIcon}
            showActions={isEditable}
            onClick={handleOpenPopover}>
            Embed or record audio
          </CardPreviewBlock>
          <AudioSelectorPopover
            anchorEl={anchorEl}
            enabled={isEditable}
            handleAudioUrl={onSaveRecording}
            handleClosePopover={handleClosePopover}
            position={preferredPlacement}
          />
        </>
      ) : (
        <AudioPlayer src={audioUrl} />
      )}
    </>
  )
}

export const insertAudioCustomBlock = (editor: Editor) => ({
  title: 'Audio',
  onItemClick: () => {
    insertOrUpdateBlock(editor, {
      type: CUSTOM_BLOCKS_TYPES_MEDIA_AREA.MEDIA_AREA_ITEM_AUDIO,
    })
  },
  aliases: [
    'audio',
    'record',
    'microphone',
    'sound',
    'voice',
    'audio-recording',
    'audio-recorder',
    'audio-player',
    'audio-recording-player',
  ],
  group: CUSTOM_BLOCKS_GROUP_MEDIA_AREA.MEDIA,
  icon: <CustomBlockIcons.AudioBlockIcon height={25} width={25} />,
  subtext: 'Record audio for your students to listen to',
})
