import { Dialog } from '@mui/material'

import { ImageSelector } from './ImageSelector'

import { XIcon } from '@/assets/icons/x-icon'

export function ImageDialogSelector({
  open,
  handleClose,
  enabled = true,
  onClickImage,
}: {
  open: boolean
  handleClose: () => void
  enabled?: boolean
  onClickImage: (images: { url: string; path: null | string }[]) => void
}) {
  const isOpen = open && enabled

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '16px',
          paddingInline: '16px',
          paddingBlock: '16px',
          position: 'relative',
        },
      }}
      onClose={handleClose}
    >
      <ImageSelector
        onClickImage={images => {
          onClickImage(images)
        }}
      />
      <XIcon
        color="#98A2B3"
        style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
        onClick={handleClose}
      />
    </Dialog>
  )
}
