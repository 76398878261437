import LogRocket from 'logrocket'
const random = Math.random()

export const startLogRocket = (isAdmin, callback) => {
  if (isAdmin) {
    console.log('starting logrocket')
    LogRocket.init('s9who2/speakableio', {
      shouldCaptureIP: false,
    })
    callback()

    return
  } else if (random <= 0.05) {
    console.log('starting logrocket for student')
    LogRocket.init('s9who2/speakableio', {
      shouldCaptureIP: false,
    })
    callback()

    return
  }
  console.log('not starting logrocket')
}
