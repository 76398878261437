import { signInWithCustomToken } from "firebase/auth";
import { auth, myFunctions } from "../firebase";
import { useSelector } from "react-redux";

const productionMode = window.location.origin.includes("app.speakable.io");
// const redirect_uri = `${window.location.origin}/onboarding?connect=clever`;
const redirect_uri = `${window.location.origin}/clever`;

const client_id = productionMode
	? import.meta.env.VITE_CLEVER_CLIENT_ID_PROD
	: import.meta.env.VITE_CLEVER_CLIENT_ID;

const test_district_id = productionMode ? false : "5b2ad81a709e300001e2cd7a";
export const cleverAuthURL =
	`https://clever.com/oauth/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}` +
	(test_district_id ? `&district_id=${test_district_id}` : "");

export const authenticateCleverWithCode = async (code) => {
	try {
		const { data } = await myFunctions.handleCleverAuthentication({
			code,
			redirect_uri: redirect_uri,
			devMode: !productionMode,
		});
		if (!data?.auth_token) {
			console.log("Error authenticating with Clever", data);
			return {
				error: true,
				data,
			};
		}
		const userCredential = await signInWithCustomToken(auth, data.auth_token);
		return {
			success: true,
			user: userCredential.user,
			...data,
		};
	} catch (error) {
		console.log(error);
		return {
			error: true,
			data: error,
		};
	}
};

const useClever = () => {
	const { profile, auth } = useSelector(({ user }) => user);

	const createCleverSection = async (section) => {
		const { data } = await myFunctions.createSingleCleverSection({
			section,
			language: profile?.language || "en",
			displayName: profile?.displayName || "",
		});
		return data;
	};
	const syncCleverSection = async (sectionId) => {
		const { data } = await myFunctions.syncCleverSection({
			sectionId,
			userId: auth?.uid,
		});
		return data;
	};

	const listCleverSections = async () => {
		try {
			const { data } = await myFunctions.handleGetCleverSections();
			return data;
		} catch (error) {
			throw new Error(error);
		}
	};

	return {
		listCleverSections,
		createCleverSection,
		syncCleverSection,
		cleverAuthURL,
	};
};

export default useClever;
